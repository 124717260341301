<h1 color="primary" class="edit-pm-dialog-headline" mat-dialog-title>Update your payment method</h1>
<div mat-dialog-content  *ngIf="data">
    <div>
        <div class="edit-pm-container"  fxLayout="row wrap">
            <div class="selector-icon">
                <div class="caption">
                    <img *ngIf="data.object == 'card'" src="../../../assets/billing/cards/{{data.brand}}.png">
                    <div *ngIf="data.object !== 'card'" class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--32 Box-root Flex-flex">
                        <svg height="70" width="100" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                            <g fill="none" fill-rule="evenodd">
                                <path d="M0 0h32v32H0z" fill="rgb(247, 250, 255)"></path>
                                <path d="M7.274 13.5a1.25 1.25 0 0 1-.649-2.333C7.024 10.937 10.15 9.215 16 6c5.851 3.215 8.976 4.937 9.375 5.167a1.25 1.25 0 0 1-.65 2.333zm12.476 10v-8.125h3.75V23.5H25a1 1 0 0 1 1 1V26H6v-1.5a1 1 0 0 1 1-1h1.5v-8.125h3.75V23.5h1.875v-8.125h3.75V23.5z" fill="rgb(41, 134, 218)"></path>
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            <div class="option-content" fxFlex="auto">
                <div class="item">
                    <span class="label">{{(data.object == 'card')? data.brand : data.bank_name  | titlecase}}•••• {{data.last4}}</span>
                </div>
            </div>
        </div>
    </div>
    <form [formGroup]="bankVerificationForm"  *ngIf="data && data.object == 'bank_account' && data.status !== 'verified'">
        <div fxlayout="column">
            <span class="edit-form-deposits-info">Your bank account must first be verified before you can use it as a payment method. Please enter the two small deposits sent to your bank account.  These deposits take 1-2 business days to appear on your online statement. </span>
            <div fxLayout="row wrap" fxLayoutGap="2%" class="edit-form-deposits-fields">
                <mat-form-field appearance="outline" fxFlex.gt-xs="48%">
                    <mat-label>First deposit</mat-label>
                    <input matInput maxlength="2" formControlName="first" required>
                    <span matPrefix class="small-deposits">$0.&nbsp;</span>
                </mat-form-field>
                <mat-form-field appearance="outline" fxFlex.gt-xs="50%">
                    <mat-label>Second deposit</mat-label>
                    <input matInput maxlength="2" formControlName="second" required>
                    <span matPrefix class="small-deposits">$0.&nbsp;</span>
                </mat-form-field>
            </div>
        </div>
        
    </form>
    
    <form [formGroup]="editCardForm" fxflex="1 1 auto" *ngIf="data && data.object == 'card'">
        <div fxlayout="column" class="edit-form-column">
            <div>
                <div fxLayout="row wrap">
                    <mat-form-field  appearance="standard" class="expanding-form-inputSmall">
                        <mat-label>MM</mat-label>
                        <input matInput  maxlength="2" formControlName="exp_month" required>
                        <mat-error *ngIf="editCardForm.get('exp_month').errors && editCardForm.get('exp_month').errors.required">Month is required</mat-error>
                    </mat-form-field>

                    <mat-form-field appearance="standard" class="expanding-form-inputSmall">
                        <mat-label>YY</mat-label>
                        <input matInput  maxlength="4" formControlName="exp_year" required>
                        <mat-error *ngIf="editCardForm.get('exp_year').errors && editCardForm.get('exp_year').errors.required">Year is required</mat-error>
                        <mat-error *ngIf="editCardForm.get('exp_year').errors && editCardForm.get('exp_year').errors.pattern  && !editCardForm.get('exp_year').errors.minlength">Invalid year</mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="column wrap" class="pm-card-error-list">
                    <mat-error *ngIf="editCardForm.get('exp_month').errors && editCardForm.get('exp_month').errors.minlength">Month must be a number between 01 and 12</mat-error>
                    <mat-error *ngIf="editCardForm.get('exp_month').errors && editCardForm.get('exp_month').errors.pattern  && !editCardForm.get('exp_month').errors.minlength">Month must be a number between 01 and 12</mat-error>
                    <mat-error *ngIf="editCardForm.get('exp_year').errors && editCardForm.get('exp_year').errors.minlength">Year must contain 2 numbers</mat-error>
                </div>
                  <div fxLayout="row wrap">
                      <mat-form-field  appearance="standard" class="expanding-form-input">
                          <mat-label>ZIP / Postal code</mat-label>
                          <input matInput  maxlength="6"  formControlName="address_zip" required>
                          <mat-error *ngIf="editCardForm.get('address_zip').errors && editCardForm.get('address_zip').errors.required">ZIP / Postal code is required</mat-error>
                      </mat-form-field>
                  </div>
            </div>
          </div>
     </form>
</div>
<div mat-dialog-actions align="end" *ngIf="data">
    <button mat-button color="primary" mat-dialog-close>CANCEL</button>
    <button mat-button color="warn" *ngIf="data.isDefault !== true" (click)="removePm(data)">REMOVE</button>
    <button mat-flat-button color="primary" [disabled]="editCardForm.invalid" *ngIf="data.object == 'card'" (click)="updateCard(editCardForm.value, data)">UPDATE</button>
    <button mat-flat-button color="primary" *ngIf="data.object == 'bank_account' && data.status == 'verified' && data.isDefault !== true" (click)="setPrimary(data)">SET AS PRIMARY</button>
    <button mat-flat-button color="primary" *ngIf="data.object == 'card' &&  data.allow_cc && data.isDefault !== true" (click)="setPrimary(data)">SET AS PRIMARY</button>
    <button mat-flat-button color="primary" [disabled]="bankVerificationForm.invalid" *ngIf="data.object == 'bank_account' && data.status !== 'verified'" (click)="bankVerification(bankVerificationForm.value, data)">VERIFY</button>
</div>