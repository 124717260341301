import {Injectable} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class SnackService {

  constructor(public snackBar: MatSnackBar){}
    
  openSnackBar(message: string, action: string) {
      this.snackBar.open(message, action, {
         duration: 5000,
         horizontalPosition: 'right',
      });
   } 
}