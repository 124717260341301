// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
/*
export const environment = {
  production: false,
  apiUrl: 'http://localhost:5001/southpaw-dev/us-central1',//apiUrl: 'https://us-central1-southpaw-dev.cloudfunctions.net',
  firebase: {
    apiKey: "AIzaSyA06fznCvajuxMtfg05C5wBsvrMo71uI-8",
    authDomain: "southpaw-dev.firebaseapp.com",
    databaseURL: "https://southpaw-dev.firebaseio.com",
    projectId: "southpaw-dev",
    storageBucket: "southpaw-dev.appspot.com",
    messagingSenderId: "139566872029",
    appId: "1:139566872029:web:e399ceac2a6c160ef5f234",
    measurementId: "G-81S79M8NYJ"
  },
  cloud_token: "APA91bHAUYPGFQI2449eiYCqU1d4V5B4fpcGGTR77EUvrEX4DVLoXqRSoMWaDtUm9S-CMZkK4soeVHt8Oj6-FCbhCeMtMp2lt-xVkSwCna0Z8q2nHgRWag1vOeklrY_uZtttNIM6t"
};
*/
export const environment = {
  production: true,
  apiUrl: 'https://us-central1-southpaw-portal.cloudfunctions.net',
  firebase: {
    apiKey: "AIzaSyBwQsQ4JwS62kM9Zby5JKaC_nxupYpBfOg",
    authDomain: "billing.hiviewsolutions.com",
    databaseURL: "https://southpaw-portal.firebaseio.com",
    projectId: "southpaw-portal",
    storageBucket: "southpaw-portal.appspot.com",
    messagingSenderId: "711211555594",
    appId: "1:711211555594:web:85fd31bf6a1a68f332b8f3",
    measurementId: "G-81S79M8NYJ"
  },
  cloud_token: "APA91bHAUYPGFQI2449eiYCqU1d4V5B4fpcGGTR77EUvrEX4DVLoXqRSoMWaDtUm9S-CMZkK4soeVHt8Oj6-FCbhCeMtMp2lt-xVkSwCna0Z8q2nHgRWag1vOeklrY_uZtttNIM6t"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
