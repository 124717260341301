import { Component, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import {MediaMatcher} from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from "../../shared/services/auth/auth.service";
import { ProgressService } from "../../shared/services/navigation/progress.service";
import { RoutesService } from "../../shared/services/navigation/routes.service";
import { CustomersService } from '@shared/services/customers/customers.service'
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';

interface Menu {
  link: string;
  name: string;
  icon: string;
  alt: string;
}

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})


export class NavigationComponent implements OnInit {
  settings;
  currentRoute: string;
  inProgress: boolean;
  mobileQuery: MediaQueryList;
  @ViewChild('drawer') drawer: MatSidenav;

  public menus: Menu[] = [
    {name: 'Home', link:'dashboard', icon: 'home', alt:'Home'},
   // {name: 'Subscriptions', link:'./subscriptions', icon: 'apps', alt:'Subscriptions'},
    {name: 'Billing', link:'/billing', icon: 'credit_card', alt:'Billing'},
    {name: 'Contact us', link:'/support', icon: 'contact_support', alt:'Contact Us'},
    {name: 'Settings', link:'/settings', icon: 'settings', alt:'Settings'},
  ]
  private _mobileQueryListener: () => void;

  constructor(
    changeDetectorRef: ChangeDetectorRef, 
    media: MediaMatcher, 
    public auth:AuthService,
    private progress: ProgressService,
    private crouter:RoutesService,
    private afs: AngularFirestore,   // Inject Firestore service
    private customers: CustomersService,
    ) {

    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    //get general settings
    this.afs.collection('settings').doc('general').collection('app').doc('site').valueChanges().subscribe( res => {
      this.settings = res;
    });

    this.auth.user.subscribe(user =>{
      this.addMenuOption(user,{name: 'Signature Management', link:'/signature', icon: 'people', alt:'Signature Management'},2,'signature');
    });
    
  }

  ngOnInit(): void {
    this.progress.getProgress().subscribe((value) => {
      this.inProgress = value;
    });

    this.crouter.getRoute().subscribe((value) => {
      this.currentRoute = value;
    });
  }

  addMenuOption(user,menuOption,index,module): void{
    if(user)
      this.customers.getDbCustomer(user.email).valueChanges().pipe(map((res:any) => {
        const customer = res[0];
        if(module == 'signature')
          if(customer.signatureSA && customer.signatureSA !== '') {
            this.menus = [
              {name: 'Home', link:'dashboard', icon: 'home', alt:'Home'},
             // {name: 'Subscriptions', link:'./subscriptions', icon: 'apps', alt:'Subscriptions'},
              {name: 'Billing', link:'/billing', icon: 'credit_card', alt:'Billing'},
              {name: 'Contact us', link:'/support', icon: 'contact_support', alt:'Contact Us'},
              {name: 'Settings', link:'/settings', icon: 'settings', alt:'Settings'},
            ];
            this.menus.splice(index,0,menuOption);
          }
      })).subscribe();
      
  }

}
