<div class="dashboard-content" >
    <mat-card fxFlex="100%" fxLayoutAlign="center">
        <div fxFlex.gt-sm="90%"  fxLayout="row wrap" fxFlex.gt-md="80%" fxFlex.lt-md="100%" fxLayoutAlign="center">
            <div class="support-box" fxFlex.gt-sm="40%"  fxFlex.gt-md="30%" fxFlex.lt-md="100%">
                <div class="support-title">
                    How can we help you?
                </div>
                <div class="support-message">
                    <p class="support-field">Drop us a note, we’ll be in touch ASAP</p>
                    <p>7:00am – 5:00pm PST, Monday through Friday</p>
                    <p><span class="support-field">Phone</span>  805.762.4042</p>
                    <p><span class="support-field">Email</span>  support@hiviewsolutions.com</p>   
                    <div class="support-img">
                        <mat-icon class="support-icon">support_agent</mat-icon>
                    </div> 
                </div>
            </div>
            <mat-card class="mat-elevation-z0" fxFlex.gt-sm="60%"  fxFlex.gt-md="70%" fxFlex.lt-md="100%">
            
            <form #conctactus #conctactusForm="ngForm" action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8" method="POST">
                
                <input type=hidden name="orgid" value="00D3i000000tSzy">
                <input type=hidden name="retURL" value="http://billing.hiviewsolutions.com/sent">
                
                <!--  ----------------------------------------------------------------------  -->
                <!--  NOTE: These fields are optional debugging elements. Please uncomment    -->
                <!--  these lines if you wish to test in debug mode.                          -->
                <!--  <input type="hidden" name="debug" value=1>                              -->
                <!--  <input type="hidden" name="debugEmail"                                  -->
                <!--  value="juan@hiviewsolutions.com">                                       -->
                <!--  ----------------------------------------------------------------------  -->
                
                <p>
                  <mat-form-field appearance="outline" fxFlex.gt-sm="80%"  fxFlex.gt-md="70%" fxFlex.lt-md="100%">
                    <mat-label for="name">Contact Name</mat-label>
                    <input matInput placeholder="Ex. John Smith" id="name" name="name" value="{{userInfo ? userInfo.displayName : ''}}"  ngModel>
                  </mat-form-field>
                </p>
                <table class="example-full-width" cellspacing="0">
                    <tr fxFlex="auto" fxLayout="row wrap" fxLayoutGap="10px">
                        <td  fxFlex.gt-xs="50%" fxFlex.lt-sm="100%">
                            <mat-form-field appearance="outline" fxFlex="auto">
                                <mat-label for="email">Email</mat-label>
                                <input matInput placeholder="Ex. support@hiviewsolutions.com" id="email" name="email" value="{{userInfo ? userInfo.email : ''}}" ngModel>
                            </mat-form-field>
                        </td>
                        <td fxFlex.gt-xs="48%" fxFlex.lt-sm="100%">
                            <mat-form-field appearance="outline" fxFlex="auto">
                                <mat-label for="phone">Phone number</mat-label>
                                <input matInput  id="phone" name="phone" ngModel required>
                            </mat-form-field>
                        </td>
                    </tr>
                </table>
                
                <p>
                    <mat-form-field appearance="outline" fxFlex="100%">
                        <mat-label>Request Type</mat-label>
                        <mat-select id="type" name="type">
                          <mat-option value="">--None--</mat-option>
                          <mat-option value="Admin Console General Issue">Admin Console General Issue</mat-option>
                          <mat-option value="Chrome License Purchase">Chrome License Purchase</mat-option>
                          <mat-option value="DNS Change">DNS Change</mat-option>
                          <mat-option value="G-Suite Access Issues">G-Suite Access Issues</mat-option>
                          <mat-option value="Increase Licenses">Increase Licenses</mat-option>
                          <mat-option value="Invoice Issue">Invoice Issue</mat-option>
                          <mat-option value="Login Issues">Login Issues</mat-option>
                          <mat-option value="Meet Connection Issues">Meet Connection Issues</mat-option>
                          <mat-option value="Org Unit Name Change">Org Unit Name Change</mat-option>
                        </mat-select>
                    </mat-form-field>
                </p>
                <p>
                    <mat-form-field appearance="outline"  fxFlex="100%">
                      <mat-label for="subject">Subject</mat-label>
                      <input matInput  id="subject" name="subject" ngModel required>
                    </mat-form-field>
                </p>
                
                <p>
                    <mat-form-field appearance="outline"  fxFlex="100%">
                      <mat-label for="description">Description</mat-label>
                      <textarea matInput id="description" name="description" ngModel required></textarea>
                    </mat-form-field>
                </p>
                
                <button mat-flat-button color="primary" (click)="conctactus.submit()" [disabled]="conctactusForm.invalid" type="submit">Send Message</button>
                
                </form>
            </mat-card>
        </div>  

    </mat-card>

</div>
