<div class="dashboard-content" fxFlex="100%" fxLayoutAlign="center" >
 <div fxFlex.gt-sm="90%"  fxFlex.gt-md="80%" fxFlex.lt-md="100%">
    <div fxLayout="row wrap" fxLayoutAlign="start end">
        <div fxFlex="auto" fxFlex.gt-xs="50%" fxFlex.lt-sm="100%" *ngFor="let pay_method of paymethods; let i=index">
            <mat-card class="paymethod-card">
                <div class="pay_method-verification-status" *ngIf="pay_method.object !== 'card'">
                    <mat-icon class="verified" matTooltip="Verified" *ngIf="pay_method.status == 'verified'">verified_user_outlined</mat-icon>
                    <mat-icon class="pending" matTooltip="Pending verification" *ngIf="pay_method.status !== 'verified'">error</mat-icon>
                </div>
                <div class="pay_method-payment-card-panel">
                    <div class="details-header">
                        <div class="details-label">
                            {{(pay_method.object == 'card')? pay_method.brand : pay_method.bank_name  | titlecase}} •••• {{pay_method.last4}}
                        </div>
                        <div class="details-description">
                            <div>
                                <span *ngIf="pay_method.exp_month">Expires {{pay_method.exp_month}}/{{pay_method.exp_year}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <img *ngIf="pay_method.object == 'card'" src="../../../assets/billing/cards/{{pay_method.brand}}.png">
                        <div *ngIf="pay_method.object !== 'card'" class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--32 Box-root Flex-flex">
                            <svg height="70" width="100" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" fill-rule="evenodd">
                                    <path d="M0 0h32v32H0z" fill="rgb(247, 250, 255)"></path>
                                    <path d="M7.274 13.5a1.25 1.25 0 0 1-.649-2.333C7.024 10.937 10.15 9.215 16 6c5.851 3.215 8.976 4.937 9.375 5.167a1.25 1.25 0 0 1-.65 2.333zm12.476 10v-8.125h3.75V23.5H25a1 1 0 0 1 1 1V26H6v-1.5a1 1 0 0 1 1-1h1.5v-8.125h3.75V23.5h1.875v-8.125h3.75V23.5z" fill="rgb(41, 134, 218)"></path>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
                <div class="pay_method-details-actions-bar">
                    <div class="group" *ngIf="pay_method.isDefault == true">
                        <div class="group-label">
                                <span>Primary</span>
                        </div>
                    </div>
                    <div class="default-actions">
                        <div class="details-action">
                            <button mat-button *ngIf="pay_method.isDefault !== true" (click)="editPm(pay_method, i, allow_cc)" class="app-primary-color">Edit</button>
                            <button mat-button *ngIf="pay_method.isDefault == true && pay_method.object == 'card'" (click)="editPm(pay_method, i, allow_cc)" class="app-primary-color">Edit</button>
                            <button mat-button *ngIf="pay_method.isDefault == true && pay_method.object !== 'card' && pay_method.status !== 'verified'" (click)="editPm(pay_method, i)" class="app-primary-color">Verify</button>
                        </div>
                    </div>
                </div>
            </mat-card>
        </div>
        <div fxFlex="auto" fxFlex.gt-xs="50%" fxFlex.lt-sm="100%">
            <mat-card class="paymethod-card-add mat-elevation-z0"  [routerLink]="['/add-payment-method']">
                <div class="new">
                    <svg width="27px" height="23px" viewBox="0 0 27 23" version="1.1" class="b3-svg-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                        <g fill-rule="evenodd">
                            <path d="M19,21 C15.691,21 13,18.309 13,15 C13,11.691 15.691,9 19,9 C22.309,9 25,11.691 25,15 C25,18.309 22.309,21 19,21 L19,21 L19,21 Z M2,8 L15.13,8 C12.946,9.21 11.392,11.413 11.069,14 L2,14 L2,8 L2,8 Z M2,4 L18,4 L18,2 L2,2 L2,4 L2,4 Z M20,7.069 L20,2 C20,0.896 19.104,0 18,0 L2,0 C0.896,0 0,0.896 0,2 L0,14 C0,15.104 0.896,16 2,16 L11.069,16 C11.562,19.945 14.921,23 19,23 C23.418,23 27,19.418 27,15 C27,10.921 23.945,7.562 20,7.069 Z M20,11 L18,11 L18,14 L15,14 L15,16 L18.012,16 L18,19 L20,19 L20,16 L23,16 L23,14 L20,14 L20,11 Z" fill="#4285f4">
                            </path>
                        </g>
                    </svg>
                    <span class="paymethod-card-add-text">ADD PAYMENT METHOD</span>
                </div>
            </mat-card>
        </div>
    </div>
 </div>
</div>
