<div class="signature-content" fxFlex="100%">
    
    <mat-card class="mat-elevation-z0">
        <div class="loadingCircle" *ngIf="inProgress">
            <mat-spinner class="inprogressCircle"></mat-spinner>
        </div>
        <div class="customer-form-container">
            <div fxLayout="row">
                <mat-card class="mat-elevation-z0" fxFlex="auto" fxFlex.gt-xs="60%" fxFlex.lt-sm="100%">
                    <div *ngIf="signatureForm">
                        <form [formGroup]="signatureForm"  fxFlex="auto" fxLayout="column">
                            <h2> Domain Information </h2>
                            
                            <div fxlayout="column" class="edit-form-column">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Domain</mat-label>
                                    <input *ngIf="userInfo" matInput formControlName="domain" placeholder="Ex. hiviewsolutions.com" required  [value]="userInfo.domain" />
                                </mat-form-field>
                                
                                <div fxLayout="row" class="settings-applyTo" fxFlex="100%">
                                    Apply to all employees?
                                    <mat-slide-toggle class="signature-all-toggle" color="primary" formControlName="applyTo" [checked]="applyTo.oneEmployee" (change)="applyToAllWarning($event, signatureForm.value)"></mat-slide-toggle>  
                                </div>
                                <div>
                                    <mat-form-field appearance="outline" class="signature-form-full-width">
                                        <mat-label>Apply to specific users</mat-label>
                                        <mat-select [disabled]="!displaySelection" formControlName="emails" multiple>
                                            <div _ngcontent-iwp-c2="" class="box-search">
                                                <input #filter matInput placeholder="Search..."  />
                                            </div>
                                            <mat-option *ngFor="let user of usersList"
                                            [class.hide]="filter.value !== '' && user.toLowerCase().indexOf(filter.value.toLowerCase()) === -1"
                                            [value]="user" (click)="applytoUsers(user)">
                                            {{user}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <mat-form-field  appearance="outline" class="signature-form-full-width">
                                    <mat-label>Load an existing signature</mat-label>
                                    <input matInput #employee type="text" formControlName="employee" placeholder="email" [matAutocomplete]="auto" class="signature-employee" />
                                    <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption >
                                        <mat-option *ngFor="let option of filteredEmployee | async" [value]="option" (onSelectionChange)="getEmployeeSignature($event,editor,signatureForm.value)">
                                        {{option}}
                                        </mat-option>
                                    </mat-autocomplete>
                                </mat-form-field>
                                <div>
                                <mat-form-field appearance="outline" class="signature-form-half-width" fxFlex="auto" fxFlex.gt-xs="95%" fxFlex.lt-sm="100%">
                                    <mat-label>Select a template</mat-label>
                                    <mat-select #templateSelector [(value)]="selected" formControlName="templateSelector" (selectionChange)="loadTemplate(selected,editor,signatureForm.value)">
                                      <mat-option >None</mat-option>
                                      <mat-option *ngFor="let template of templatesList_ids"
                                        [value]="template">
                                        Template {{template}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="mat-elevation-z0" style="margin-top: 10px;margin-left: 10px;" >
                                    <button mat-mini-fab color="primary" (click)="openInfo()" aria-label="What place holders can I use?">
                                      <mat-icon>help_outline</mat-icon>
                                    </button>
                                  </div>
                                </div>
                                
                            </div>
                            
                        </form>
                    </div>
                    
                </mat-card>
                
                <mat-card class="mat-elevation-z0" fxFlex="auto" fxFlex.gt-xs="58%" fxFlex.lt-sm="100%">
                    <h2> Employee Information </h2>
                    <div>
                        <div class="signature-profile-img">
                            <img mat-card-image src="../../../assets/HSGirl_Graphic_2.png" alt="Employee" >
                        </div>
                        <mat-card-content fxFlex.gt-xs="58%">
                            <div *ngIf="userProfileName">
                                <mat-card-header *ngIf="userProfileName">
                                    <img mat-card-avatar alt="{{userProfileName}}" [src]="userPhoto"> 
                                     <mat-card-title class="signature-displayName">{{userProfileName}}</mat-card-title>
                                </mat-card-header>
                                <div *ngIf="userProfile[1]" class="signature-contact-details" fxlayoutalign="start center">
                                    <mat-icon aria-hidden="true" class="signature-contact-details-icon">email</mat-icon> 
                                    <span #userProfileEmail>{{userProfile[1]}}</span>
                                </div>
                                <div *ngIf="userProfile[2]" class="signature-contact-details" fxlayoutalign="start center">
                                    <mat-icon aria-hidden="true" class="signature-contact-details-icon">domain</mat-icon> 
                                    <span #userProfileDomain>{{userProfile[2]}}</span>
                                </div>
                                <div *ngIf="userProfileAddr" class="signature-contact-details" fxlayoutalign="start center">
                                    <mat-icon aria-hidden="true" class="signature-contact-details-icon">location_on</mat-icon>
                                    <span [innerHTML]="userProfileAddr"></span>
                                </div>
                            </div>
                        </mat-card-content>
                    </div>
                    
                </mat-card>
                
            </div>
            <div fxLayout="row">
                <mat-card class="mat-elevation-z0" fxFlex="auto" fxFlex.gt-xs="100%" fxFlex.lt-sm="100%">
                    <div class="mat-form-field-wrapper">
                        <editor
                          #editor
                          apiKey="f598dmz80rfdsak4elunu80vsfrzdimqxdtmxtzxnknlho2y"
                          class="mat-elevation-z1"
                          (onInit)="handleEditorInit($event,'')"
                          [init]="{
                            skin: 'borderless',
                            plugins: 'wordcount preview image link code',
                            imagetools_cors_hosts: ['picsum.photos'],
                            menubar: 'edit view insert format tools table help',
                            toolbar: 'undo redo | bold italic underline | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | ltr rtl',
                            min_height: 150,
                            height: 350,
                            branding: false
                          }"
                        ></editor>
                    
                        
                    </div>
                    <div class="actions-container">
                        <button mat-stroked-button color="warn" (click)="clearForm(signatureForm.value,editor)">Discard</button>
                        <button mat-stroked-button color="secondary"   (click)="saveTemplate(editor,signatureForm.value)">Template Actions</button>
                        <button mat-stroked-button color="primary"   (click)="signatureSubmit(signatureForm.value,editor)">Save Signature</button>
                    </div>
                </mat-card>
            </div>
            
        </div>
    </mat-card>
    
    
</div>