import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@environments/environment';
import { Customer } from "@shared/interfaces/customer";
import { map } from 'rxjs/operators';

const BASE_API_URL = environment.apiUrl;
const TOKEN = environment.cloud_token;

const httpOptions = {
    headers: new HttpHeaders({ 'Access-Control-Allow-Methods':'GET, POST', responseType: 'json', 'Access-Control-Allow-Origin': '*', 'Authorization': 'Bearer ' + TOKEN})
}

@Injectable({
  providedIn: 'root'
})
export class CustomersService {
  constructor(
    public http: HttpClient,
    public afs: AngularFirestore,   // Inject Firestore service
    ) { }

  getAll(){
    return this.afs.collection('customers').valueChanges();
  }

  createCustomer(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/createCustomer ',data, httpOptions);
  }


  createOffline(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/offline/createCustomer ',data, httpOptions);
  }

  scheduleSub(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/scheduleSub ',data, httpOptions);
  }
  
  getGSuiteCustomer(customer){
    let body = new HttpParams();
    body = body.set('customer', customer);
    return this.http.post(BASE_API_URL+'/gsuiteCustomer',body, httpOptions);
  } 


  getGSuiteSubscriptions(customer){
    let body = new HttpParams();
    body = body.set('customer', customer);
    return this.http.post(BASE_API_URL+'/gsuiteSubscriptions', body, httpOptions);
  }

  getStripeCustomer(customer, identifier){
    let body = new HttpParams();
    if (identifier == "id"){
      body = body.set('id', customer);
    }else{
      body = body.set('customer', customer);
    }
    return this.http.post(BASE_API_URL+'/retrieveStripeCustomer',body, httpOptions);
  }

  getStripeBasic(customer){
    let body = new HttpParams();
    body = body.set('id', customer.id); 
    body = body.set('pm_id', customer.default_source.id);
    body = body.set('pm_type', customer.default_source.type);
    return this.http.post(BASE_API_URL+'/retrieveStripeBasic ',body, httpOptions);
  }

  getStripePaymethods(id){
    let body = new HttpParams();
    body = body.set('id', id);
    return this.http.post(BASE_API_URL+'/retrieveStripePaymethods ',body, httpOptions);
  }

  updateStripeCustomer(s){
    httpOptions.headers.append('Content-Type', 'application/json');
    let body = new HttpParams();
    body = s;
    return this.http.post(BASE_API_URL+'/updateStripeCustomer',body, httpOptions);
  }

  addSource(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/stripeCreateSource ',data, httpOptions);
  }

  updateDefaultSource(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/updateDefaultsource ',data, httpOptions);
  }

  verifySource(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/sourceVerification ',data, httpOptions);
  }


  async updateDefaultSourceDB(data){
    const db = this.afs.collection('customers').doc(data.customer);
    return await db.ref.get().then(function (doc:any){
      if (doc.exists) {
        if(doc.data().default_source && doc.data().default_source.id){
          let old_value = doc.data().default_source.id;
          //remove old value
          db.collection('payment_method').doc(old_value).update(
            {isDefault:false}
            ).then(()=>{
              //now lets set the new value
              db.update({
                ['default_source']: { id:data.id, type: data.object}
              }).then(()=>{
                db.collection('payment_method').doc(data.id).update(
                  {isDefault:true}
                  )
                })
              })
        }else{
          //Set the new value
          db.update({
            ['default_source']: { id:data.id, type: data.object}
          }).then(()=>{
            db.collection('payment_method').doc(data.id).update(
              {isDefault:true}
              )
            })
        }
      } else {
        console.log("There is no document!");
      }
    });
  }
  

  updateSource(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/stripeUpdateSource ',data, httpOptions);
  }

  removeSource(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/stripeDeleteSource ',data, httpOptions);
  }
  
  getDbCustomer(customer){
    return this.afs.collection('customers', ref => ref.where('team', 'array-contains',  customer).where('portal', '==',  'online'));
  }

  addDbCustomer(s, g, user){
    let p_method = s.invoice_settings.default_payment_method? s.invoice_settings.default_payment_method : (s.default_source ? s.default_source : '');
    let pm_type = 'card',
    source = s.sources.data;

    if(p_method.startsWith("ba_")){
      pm_type = 'bank_account';
    }
    
    const db = this.afs.collection('customers').doc(s.id);
    
    const customerData: Customer = {
      id:s.id,
      gid: g.customerId,
      organization: s.name,
      domain: g.customerDomain,
      team:[s.email],
      billing: {
        address:{
          country: g.postalAddress.countryCode,
          line1: g.postalAddress.addressLine1,
          line2: g.postalAddress.addressLine2 ? g.postalAddress.addressLine2 : '',
          city: g.postalAddress.locality,
          state: g.postalAddress.region,
          postal_code: g.postalAddress.postalCode
        },
        name: g.postalAddress.contactName,
      },
      default_source:{
        id:p_method,
        type: pm_type
      },
      adminEmail: s.email,
      alternateEmail: g.alternateEmail,
      phone: s.phone,
      createdOn: new Date()
    }
    return db.set(customerData, {
      merge: true
    }).then(()=>{
      for (var i = 0; i < source.length; i++) {
         //do not store ACH Transfer
        if(source[i].id.startsWith('src')) {
          source.splice(i,1);
          return false;
        }
        source[i].isDefault = false;
        if(source[i].id=== s.default_source){
          source[i].isDefault = true;
        }
        db.collection('payment_method').doc(source[i].id).set(source[i]);
        }
      }).catch(err => {
        console.log(err)
      })
  }

  updateDbCustomer(data){
    return this.updateStripeCustomer(data).pipe(map((res:any) => {
      if(res.id){
        return this.afs.collection('customers').doc(data.id).set(data, {
            merge: true
          })
      }else{
        console.log(res)
        window.alert(res)
      }
      }))
  }
  
  getPaymentMethodDB(id){
    return this.afs.collection('customers').doc(id).collection('payment_method');
  }

  addPaymentMethodDB(sid, data){
    return this.afs.collection('customers').doc(sid).collection('payment_method').doc(data.id).set(data,
      {merge: true})
  }

  removePaymentMethodDB(sid, id){
    return this.afs.collection('customers').doc(sid).collection('payment_method').doc(id).delete();
  }

  getInvoices(id){
    let body = new HttpParams();
    body = body.set('id', id);
    return this.http.post(BASE_API_URL+'/bilingDetails ',body, httpOptions);
  }

  addLicenses(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/unitsUp ',data, httpOptions);
  }
  
  getPrices(){
    return this.http.get(BASE_API_URL+'/listPrices ',httpOptions);
  }
  
  qboCustomers(){
    return this.http.get(BASE_API_URL+'/qbo/listqbo ',httpOptions);
  }
  
  sf(){
    return this.http.get(BASE_API_URL+'/sf/reAccount ',httpOptions);
  }
  
  getCoupons(){
    return this.http.get(BASE_API_URL+'/coupons ',httpOptions);
  }

  updateFee(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/feeUpdate', data, httpOptions);
  }

  voiceTax(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/addVoiceTax', data, httpOptions);
  }
  
  addProduct(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/setProduct ',data, httpOptions);
  }
  
  addPrice(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/setPrice ',data, httpOptions);
  }

  
  activateSubscriptions(data){
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/activateSubs ',data, httpOptions);
  }

  
  qboAuth(){
    return this.http.get(BASE_API_URL+'/qbo/authUri ',httpOptions);
  }
  
  addSubscriptionDB(data, productData){
    return this.afs.collection('customers').doc(data.customer.id).collection('subscriptions').doc(productData.sku).set(productData, {merge:true});
  }


}


