<div class="app-main-content" fxLayout="row wrap" fxLayoutAlign="center">
    <div class="pm-content-card  mat-elevation-z2"  fxFlex="auto" fxFlex.gt-sm="60%" fxFlex.lt-md="100%">
        <div class="pm-card-header" data-was-visible="true">Add a payment method</div>
        <mat-accordion [multi]="false" class="mat-elevation-z0">
            <mat-expansion-panel class="mat-elevation-z0"  [expanded]="expandedpm_bank" *ngIf="expandedpm_card == false" (closed)="expandedpm_bank = false" (opened)="expandedpm_bank = true">
              <mat-expansion-panel-header >
                <mat-panel-title >
                    <div class="expanding-form-selector-container">
                        <div class="ratio-actions">
                            <mat-icon *ngIf="expandedpm_bank == true">account_balance</mat-icon>
                            <mat-radio-button *ngIf="expandedpm_bank == false"></mat-radio-button>
                        </div>
                    </div>
                    Add a bank account
                </mat-panel-title>
              </mat-expansion-panel-header>
              <form [formGroup]="bankForm" fxflex="1 1 auto" *ngIf="bankloadStatus == false">
                  <div class="pm-card-expanded-message">
                    <span>Bank account must be located in United States and in US Dollar currency</span>
                  </div>
                  <div fxlayout="column" class="edit-form-column">
                        <div fxflex="100%">
                            <mat-form-field appearance="standard" class="expanding-form-input">
                              <mat-label>Name on bank account</mat-label>
                              <input matInput placeholder="Ex. HiView Solutions" formControlName="account_holder_name" required>
                              <mat-icon matTooltip="Enter your full name as shown on your bank statements." matSuffix>info_outline</mat-icon>
                              <mat-error *ngIf="bankForm.get('account_holder_name').errors && bankForm.get('account_holder_name').errors.required">Name on bank account is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field  appearance="standard" class="expanding-form-input">
                                <mat-label>Account holder type</mat-label>
                                <mat-select [(value)]="selected" formControlName="account_holder_type" required>
                                  <mat-option value="individual">Individual</mat-option>
                                  <mat-option value="company">Company</mat-option>
                                </mat-select>
                                <mat-error *ngIf="bankForm.get('account_holder_type').errors && bankForm.get('account_holder_type').errors.required">Account holder type is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field  appearance="standard" class="expanding-form-input">
                                <mat-label>Routing number</mat-label>
                                <input matInput placeholder="Ex. Individual" formControlName="routing_number" required>
                                <mat-error *ngIf="bankForm.get('routing_number').errors && bankForm.get('routing_number').errors.required">Routing number is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div>
                            <mat-form-field  appearance="standard" class="expanding-form-input">
                                <mat-label>Account number</mat-label>
                                <input matInput placeholder="Ex. Individual" formControlName="account_number" required>
                                <mat-error *ngIf="bankForm.get('account_number').errors && bankForm.get('account_number').errors.required">Account number is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="expanding-form-infoimg">
                            <img src="../../../assets/billing/bankrouting.png">
                        </div>
                        <div class="expanding-form-infotext">
                            <span>By continuing, you authorize HiView Solutions to automatically credit and debit your bank account electronically, 
                                  including debits to fund any negative balance that may arise.
                            </span>
                        </div>
                    </div>
              </form>
              <div class="actions-container">
                  <button mat-button color="primary" (click)="expandedpm_bank = false; bankForm.reset()">Discard</button>
                  <button mat-flat-button color="primary" [disabled]="bankForm && bankForm.invalid" (click)="addBank(bankForm.value)">Save</button>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel  class="mat-elevation-z0" [expanded]="expandedpm_card"  *ngIf="expandedpm_bank == false" (closed)="expandedpm_card = false" (opened)="expandedpm_card = true">
              <mat-expansion-panel-header>
                  <mat-panel-title>
                    <div class="expanding-form-selector-container">
                        <div class="ratio-actions">
                            <mat-icon *ngIf="expandedpm_card == true">credit_card</mat-icon>
                            <mat-radio-button *ngIf="expandedpm_card == false"></mat-radio-button>
                        </div>
                    </div>
                    Add credit or debit card
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <form [formGroup]="cardForm" fxflex="1 1 auto" *ngIf="cardloadStatus == false">
                <div fxlayout="column" class="edit-form-column">
                    <div >
                        <mat-form-field  appearance="standard" class="expanding-form-input">
                            <mat-label>Card number</mat-label>
                            <input matInput maxlength="20"
                            type="tel"
                            #ccNumber
                            (keyup)="creditCardNumberSpacing()" formControlName="number" required>
                            <img class="pm-card-sufix-img" *ngIf="cardType" src="../../../assets/billing/cards/{{cardType}}.png" matSuffix>
                            <mat-error *ngIf="cardForm.get('number').errors && cardForm.get('number').errors.required">Card number is required</mat-error>
                            <mat-error *ngIf="cardForm.get('number').errors && cardForm.get('number').errors.validateCC">Invalid credit card</mat-error>
                        </mat-form-field>
                    </div>
                    <div *ngIf="cardType && !cardForm.get('number').errors">
                        <div fxLayout="row wrap">
                            <mat-form-field  appearance="standard" class="expanding-form-inputSmall">
                                <mat-label>MM</mat-label>
                                <input matInput  maxlength="2" formControlName="exp_month" required>
                                <mat-error *ngIf="cardForm.get('exp_month').errors && cardForm.get('exp_month').errors.required">Month is required</mat-error>
                            </mat-form-field>
    
                            <mat-form-field appearance="standard" class="expanding-form-inputSmall">
                                <mat-label>YY</mat-label>
                                <input matInput  maxlength="2" formControlName="exp_year" required>
                                <mat-error *ngIf="cardForm.get('exp_year').errors && cardForm.get('exp_year').errors.required">Year is required</mat-error>
                                <mat-error *ngIf="cardForm.get('exp_year').errors && cardForm.get('exp_year').errors.pattern  && !cardForm.get('exp_year').errors.minlength">Invalid year</mat-error>
                            </mat-form-field>
                            
                            <mat-form-field appearance="standard" class="expanding-form-input-cvc">
                                <mat-label>CVC</mat-label>
                                <input matInput maxlength="4" formControlName="cvc" required>
                                <img *ngIf="cardType !=='amex'" matTooltip="CVC is the last three digits of the number that appears on the back of your card in the signature bar." 
                                src="../../../assets/billing/cards/cvc.png" matSuffix>
                                <img *ngIf="cardType =='amex'"  matTooltip="CVC is the four digits located on the front of the card, on the right side." 
                                src="../../../assets/billing/cards/cvc.png" matSuffix>
                                <mat-error *ngIf="cardForm.get('cvc').errors && cardForm.get('cvc').errors.required">CVC is required</mat-error>
                            </mat-form-field>
                        </div>
                        <div fxLayout="column wrap" class="pm-card-error-list">
                            <mat-error *ngIf="cardForm.get('exp_month').errors && cardForm.get('exp_month').errors.minlength">Month must be a number between 01 and 12</mat-error>
                            <mat-error *ngIf="cardForm.get('exp_month').errors && cardForm.get('exp_month').errors.pattern  && !cardForm.get('exp_month').errors.minlength">Month must be a number between 01 and 12</mat-error>
                            <mat-error *ngIf="cardForm.get('exp_year').errors && cardForm.get('exp_year').errors.minlength">Year must contain 2 numbers</mat-error>
                            <mat-error *ngIf="cardForm.get('cvc').errors && cardForm.get('cvc').errors.pattern">CVC must contain numbers only</mat-error>
                        </div>
                          <div fxLayout="row wrap">
                              <mat-form-field appearance="standard" class="expanding-form-input">
                                <mat-label>Cardholder name</mat-label>
                                <input matInput placeholder="Ex. HiView Solutions" formControlName="name" required>
                                <mat-icon matTooltip="Enter your full name as shown on your Credit card statements." matSuffix>info_outline</mat-icon>
                                <mat-error *ngIf="cardForm.get('name').errors && cardForm.get('name').errors.required">Card holder name is required</mat-error>
                              </mat-form-field>
                          </div>
                          <div fxLayout="row wrap">
                              <mat-form-field  appearance="standard" class="expanding-form-input">
                                  <mat-label>Country</mat-label>
                                  <mat-select [(value)]="selected" formControlName="address_country" required>
                                    <mat-option *ngFor="let country of countries" [value]="country.code">
                                      {{country.name}}
                                    </mat-option>
                                  </mat-select>
                                  <mat-error *ngIf="cardForm.get('address_country').errors && cardForm.get('address_country').errors.required">Country is required</mat-error>
                              </mat-form-field>
                          </div>
                          <div fxLayout="row wrap">
                              <mat-form-field  appearance="standard" class="expanding-form-input">
                                  <mat-label>ZIP / Postal code</mat-label>
                                  <input  maxlength="6" matInput formControlName="address_zip" required>
                              </mat-form-field>
                          </div>
                          <div fxLayout="row wrap" class="expanding-form-infotext">
                              <span>By continuing, you authorize HiView Solutions to automatically credit and debit your Credit Card electronically, 
                                    including debits to fund any negative balance that may arise.
                              </span>
                          </div>

                    </div>
                  </div>
             </form>
             <div class="actions-container">
                 <button mat-button color="primary" (click)="expandedpm_card = false; cardForm.reset()">Discard</button>
                 <button mat-flat-button color="primary" [disabled]="cardForm && cardForm.invalid" (click)="addCard(cardForm.value)">Save</button>
             </div>
            </mat-expansion-panel>
        </mat-accordion>
        
        <div class="actions-container">
            <button mat-button color="primary" *ngIf="expandedpm_bank == false && expandedpm_card == false" (click)="goBack()">CANCEL</button>
        </div>

    </div>
    
</div>
  