<div class="main-content">
 <mat-card>
  <mat-card class="edit-form mat-elevation-z0">
    <div class="loadingCircle" *ngIf="loadStatus">
        <mat-spinner class="inprogressCircle"></mat-spinner>
    </div>
    <div class="customer-form-container">
        <div fxlayout="row">
            <form [formGroup]="mainForm" fxflex="1 1 auto" *ngIf="loadStatus == false">
                <h2> Organization information </h2>
                <div fxlayout="column" class="edit-form-column" formGroupName="organization">
                    <mat-form-field appearance="outline">
                        <mat-label>Organization Name</mat-label>
                        <input matInput placeholder="Ex. HiView Solutions" formControlName="name" required>
                    </mat-form-field>
                      
                    <mat-form-field appearance="outline">
                        <mat-label>Alternate email</mat-label>
                        <input matInput type="email" placeholder="john@gmail.com" formControlName="email" required>
                    </mat-form-field>
                </div>
                <div></div>
                <h2> Billing information </h2>
                <div fxlayout="column"  class="edit-form-column" formGroupName="user">
                    <table class="example-full-width" cellspacing="0"><tr>
                        <td><mat-form-field class="address-full-width" appearance="outline">
                          <mat-label>First name</mat-label>
                          <input matInput placeholder="Ex. Miles" formControlName="name">
                        </mat-form-field></td>
                        <td><mat-form-field class="address-full-width" appearance="outline">
                            <mat-label>Last name</mat-label>
                            <input matInput placeholder="Ex. Hischier" formControlName="lastname">
                          </mat-form-field></td>
                      </tr>
                    </table>
                    <mat-form-field appearance="outline">
                        <mat-label>Street address</mat-label>
                        <input matInput placeholder="Ex. 100 Main St" formControlName="street" required>
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>Street address Line 2</mat-label>
                        <input matInput placeholder="Ex. 100 Main St" formControlName="street2">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput placeholder="Ex. San Francisco" formControlName="city" required>
                    </mat-form-field>
                    <table class="example-full-width" cellspacing="0"><tr>
                        
                        <td><mat-form-field  class="address-full-width" appearance="outline">
                                <mat-label>Country</mat-label>
                                <mat-select formControlName="country">
                                    <mat-option [value]="country.code" *ngFor="let country of countries">{{country.name}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td><mat-form-field class="address-full-width" appearance="outline">
                          <mat-label>State / Province / Region</mat-label>
                          <input matInput placeholder="Ex. California" formControlName="state" >
                        </mat-form-field></td>
                        <td><mat-form-field class="address-full-width" appearance="outline">
                          <mat-label>Postal / Zip Code</mat-label>
                          <input matInput #postalCode maxlength="5" placeholder="Ex. 94105" value="" formControlName="postal_code" >
                          <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                        </mat-form-field></td>
                      </tr>
                    </table>
                    <mat-form-field appearance="outline">
                        <mat-label>Phone number</mat-label>
                        <input matInput placeholder="" formControlName="phone">
                    </mat-form-field>
                </div>
                <div class="actions-container">
                    <button mat-stroked-button color="primary" (click)="goBack()">Discard</button>
                    <button mat-stroked-button color="warn"  [disabled]="mainForm.invalid"  (click)="updateCustomer(mainForm.value)">Save</button>
                </div>
            </form>
        </div>
    </div>
  </mat-card>
 </mat-card>
</div>