<div class="dashboard-content admin-dashboard">
    <mat-tab-group dynamicHeight>
        <mat-tab label="Customers">
            <div class="admin-tabs-box  mat-elevation-z2" fxFlex="auto">
                <mat-card  fxLayout="row wrap" fxFlex="100%">
                    <mat-card-content fxFlex="100%" fxLayout="column  wrap">
                        <div *ngIf="loading" class="spinner-border spinner-border-sm"></div>
                        <div class="admin-customers-actions" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="end"> 
                            <div>
                                <button mat-flat-button color="accent" matTooltip="Add online customer" (click)="addCustomer()">
                                  <mat-icon>add</mat-icon> Add online customer
                                </button>
                            </div>
                            <div>
                                <button mat-flat-button color="warn" matTooltip="Add offline customer" (click)="addOffline()">
                                  <mat-icon>add</mat-icon> Add offline customer
                                </button>
                            </div>
                            <div class="div-space"></div>
                            <div class="export-container">
                              <button mat-raised-button color="primary" (click)="exportUsers()">Export Customers</button>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxFlex="100%">
                            <mat-form-field class="search-box">
                                <mat-label>Search</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. hiview" #input>
                                <mat-icon matSuffix color="primary">search</mat-icon>
                            </mat-form-field>                              
                            <mat-table #table [dataSource]="customersSource" multiTemplateDataRows fxFlex="100%">
                                <ng-container matColumnDef="{{column}}" *ngFor="let column of columnsToDisplay">
                                    <mat-header-cell *matHeaderCellDef>
                                        {{column == 'adminEmail' ? 'Admin Email' :  column == 'setup_status' ? 'Setup Status' : column == 'pm_status' ? 'Verification Status' : column | titlecase}}
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" [ngClass]="element[column]"> 
                                        {{column == 'setup_status' && element.portal == 'offline'? 'Not required' : element[column]}} 
                                    </mat-cell>
                                </ng-container>
                                <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
                                <ng-container matColumnDef="expandedDetail">
                                    <mat-cell *matCellDef="let element; let i = dataIndex" [attr.colspan]="columnsToDisplay.length" fxLayout="row wrap">
                                        <div class="admin-customers-detail" fxLayout="row wrap" fxLayoutAlign="center start" fxFlex="100%" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                                            <div class="payment-action" fxLayout="row wrap" fxFlex="100%" *ngIf="element.portal == 'online'">
                                                <div fxFlex="auto" fxFlex.gt-xs="40%" fxFlex.lt-sm="100%">
                                                    Allow customer to use credit card as the default payment method?<mat-checkbox color="primary" [checked]="element.allow_cc" (change)="eventCheck($event.checked, element, i)"></mat-checkbox>
                                                </div>
                                                <div fxFlex="auto" fxFlex.gt-xs="30%" fxFlex.lt-sm="100%">
                                                    <mat-form-field  appearance="outline">
                                                        <mat-label>Add Processing Fee</mat-label>
                                                        <mat-select [(value)]="element.fee" (selectionChange)="feeUpdate($event.value, element)">
                                                            <mat-option [value]="true">Yes</mat-option>
                                                            <mat-option [value]="false">No</mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                                <div fxFlex="auto" fxFlex.gt-xs="30%" fxFlex.lt-sm="100%">
                                                    <mat-form-field  appearance="outline">
                                                        <mat-label>Google Voice tax rate</mat-label>
                                                        <input matInput type="text" [value]="element.voiceTax" (input)="voiceTax($event.target.value, element)">
                                                    </mat-form-field>
                                                </div>
                                            </div>
                                            <div fxLayoutAlign="start" fxFlex="100%">
                                                <div class="admin-customers-description" fxFlex="auto" fxFlex.gt-xs="30%" fxFlex.lt-sm="100%">
                                                    <h2 matLine> Users</h2>
                                                    <mat-list>
                                                        <mat-list-item *ngFor="let user of element.team; let last = last">
                                                                <span> {{user}} </span>
                                                            <div class="admin-customer-item-actions"><mat-icon matTooltip="Remove user" class="hover-red" (click)="removeUser(user, element, i)">delete</mat-icon></div>
                                                            <mat-divider *ngIf="!last"></mat-divider>
                                                        </mat-list-item>
                                                    </mat-list>
                                                </div>
                                                <div class="admin-customers-description admin-customers-description-subs" fxFlex="auto" fxFlex.gt-xs="50%" fxFlex.lt-sm="100%" *ngIf="element.portal == 'online'">
                                                    <h2 matLine>Subscriptions</h2>
                                                    <div fxflex="1 1 auto" fxLayout="row wrap">
                                                        <mat-card fxFlex="auto" fxFlex.gt-xs="160px" fxFlex.lt-sm="100%" class="mat-elevation-z0 dashboard-subscriptions-card admin-services-add" (click)="addProduct(i)">
                                                            <div class="dashboard-newservice-logo-container" fxflex="0 0 100%" >
                                                                <mat-icon svgicon="channel:new-service-icon" aria-hidden="true">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="100%" version="1.1" viewBox="0 0 40 40" width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                                                        <g fill="none" fill-rule="evenodd" id="new-fab-icon" stroke="none" stroke-width="1">
                                                                            <g id="FAB/Regular/Raised">
                                                                                <g id="Icons/GFAB/Create">
                                                                                    <g id="gFAB">
                                                                                        <polygon fill="#4285F4" id="Fill-2" points="16.6666667 16.6666667 16.6666667 23.335 23.4383333 23.335 40 23.335 40 16.6666667"></polygon>
                                                                                        <polygon fill="#FBBC05" id="Fill-3" points="16.6666667 16.6666667 0 16.6666667 0 23.335 16.6666667 23.335 23.335 16.6666667"></polygon>
                                                                                        <rect fill="#34A853" height="16.6666667" id="Rectangle" width="6.66666667" x="16.6666667" y="23.3333333"></rect>
                                                                                        <polygon fill="#EA4335" id="Fill-4" points="16.6666667 0 16.6666667 23.3333333 23.335 16.6666667 23.335 0"></polygon>
                                                                                    </g>
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                </mat-icon>
                                                            </div>
                                                            <div class="dashboard-newservice-text" fxflex="1 1 auto" fxlayout="row" fxlayoutalign="space-between center">
                                                               Add Services
                                                            </div>
                                                        </mat-card>
                                                        <mat-card fxFlex.gt-xs="160px" fxFlex.lt-sm="100%" class="mat-elevation-z0 dashboard-subscriptions-card"  *ngFor="let product of element.subscriptions; let i = index">
                                                            <div class="dashboard-subscriptions-logo-container" fxflex="0 0 100%">
                                                                <img src="../../../assets/billing/{{product.icon}}.png">
                                                            </div>
                                                            <div class="details" fxflex="0 1 100%" >
                                                                <h3>{{product.name}}</h3>
                                                            </div>
                                                            <div class="dashboard-subscriptions-card-content" fxflex="1 1 auto" fxlayout="row" fxlayoutalign="space-between center" *ngIf="product.quantity">
                                                                {{product.quantity}} Licenses
                                                            </div>
                                                        </mat-card>
                                                    </div>
                                                </div>
                                                <div class="admin-customers-description-actions" fxFlex="auto" fxFlex.gt-xs="15%" fxFlex.lt-sm="100%">
                                                    <button m mat-mini-fab color="primary" matTooltip="Whitelist a user" (click)="addUser(i)"><mat-icon>person_add</mat-icon></button>
                                                    <!--
                                                    <p>
                                                        <button mat-mini-fab matTooltip="Add product" (click)="addProduct(i)"><mat-icon>playlist_add</mat-icon></button>
                                                    </p>
                                                      -->
                                                </div>
                                            </div>
                                        </div>
                                    </mat-cell>
                                    </ng-container>
                                    <mat-header-row *matHeaderRowDef="columnsToDisplay"></mat-header-row>
                                    <mat-row *matRowDef="let element; columns: columnsToDisplay" 
                                        class="admin-customers-row"
                                        [class.customers-expanded-row]="expandedElement === element"
                                        (click)="expandedElement = expandedElement === element ? null : element">
                                    </mat-row>
                                    <mat-row *matRowDef="let row; columns: ['expandedDetail']" class="customers-detail-row"></mat-row>

                                    <!-- Row shown when there is no matching data. -->
                                    <tr class="mat-row" *matNoDataRow>
                                      <td class="mat-cell search-results">No data matching the search "{{input.value}}"</td>
                                    </tr>
                            </mat-table>
                        </div>
                        <mat-paginator #customersPaginator="matPaginator" [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons></mat-paginator>
                    </mat-card-content>   
                </mat-card>
            </div>
        </mat-tab>
        <mat-tab label="Settings">
          <div class="admin-tabs-box" fxFlex="auto">
            <mat-card>
                <mat-card-header>
                  <mat-card-title>General Settings</mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                    <div fxLayout="row" fxFlex="100%"  fxLayoutAlign="center" *ngIf="settingsForm">
                        <form [formGroup]="settingsForm">
                            <div fxFlex="auto" fxLayout="column">
                                <div fxLayout="row wrap" fxFlex="100%" fxLayoutGap="25px">
                                    <mat-form-field  appearance="fill">
                                        <mat-label>Site Name</mat-label>
                                        <input matInput placeholder="Ex. HiView Billing"  formControlName="siteName" required>
                                        <mat-error *ngIf="settingsForm.get('siteName').errors">Site Name is required</mat-error>
                                    </mat-form-field>
                                    <mat-form-field class="settings-input-large" appearance="fill">
                                        <mat-label>Notifications Email Address</mat-label>
                                        <input matInput placeholder="Ex. miles@hiviewcloud.com"  type="email" formControlName="email" required>
                                        <mat-error *ngIf="settingsForm.get('email').errors">Email address is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="row wrap" fxFlex="100%" fxLayoutGap="25px">
                                    <span>Enable left side menu?</span><mat-checkbox color="primary" formControlName="leftBar"></mat-checkbox>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div fxLayout="row" fxFlex="100%"> 
                        <div fxFlex="100%" align="end" fxLayoutGap="25px">
                            <button mat-raised-button *ngIf="!isEditable" (click)="enableForm()">
                                <mat-icon>edit</mat-icon>Edit
                            </button>
                            <button mat-raised-button *ngIf="isEditable" color="warn" (click)="disableForm()">
                                 <mat-icon>cancel</mat-icon> Cancel
                            </button>
                            <button mat-raised-button color="primary" [disabled]="!isEditable" (click)="updateSettings(settingsForm.value)" cdkFocusInitial>Save</button>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
            <!-- QBO Settings -->
            <mat-card class="admin-settings-box">
                <mat-card-header>
                  <mat-card-title>QuickBooks Access</mat-card-title>
                </mat-card-header>
                <mat-card-content fxLayout="column">
                    <div fxLayout="row" fxFlex="100%"  fxLayoutAlign="center" class="admin-qbo-div" fxLayoutGap="100px">
                        <!--
                        <form [formGroup]="qboForm">
                            <div fxFlex="auto" fxLayout="column">
                                <div fxLayout="row wrap" fxFlex="100%">
                                    <mat-form-field  appearance="fill">
                                        <mat-label>Service/Product ID</mat-label>
                                        <input matInput placeholder="Ex. 1"  formControlName="serviceID" required>
                                        <mat-error *ngIf="qboForm.get('serviceID').errors">The QBO service ID is required</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </form>
                     -->
                        <button class="admin-qbo-button" (click)="qboAuth()"></button>
                    </div>
                    <!--
                    <div fxLayout="row" fxFlex="100%"> 
                        <div fxFlex="100%" align="end" fxLayoutGap="25px">
                            <button mat-raised-button *ngIf="!qboEditable" (click)="enableqboForm()">
                                <mat-icon>edit</mat-icon>Edit
                            </button>
                            <button mat-raised-button *ngIf="qboEditable" color="warn" (click)="disableqboForm()">
                                 <mat-icon>cancel</mat-icon> Cancel
                            </button>
                            <button mat-raised-button color="primary" [disabled]="!qboEditable" (click)="qboSettings(qboForm.value)" cdkFocusInitial>Save</button>
                        </div>
                    </div>
                    -->
                </mat-card-content>
            </mat-card>
          </div>
        </mat-tab>
    </mat-tab-group>

</div>


<!-- Add Product Dialog template -->

<ng-template #addProductDialog  let-data>
    <button mat-icon-button class="mat-dialog-cancel" color="primary" (click)="closeaddProductDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <h4 mat-dialog-title> Add Product to {{data.customer.organization}} </h4>
    <div mat-dialog-content>
        <div fxLayout="column">
            <div class="loading-circle" *ngIf="loading == true" fxFlex="100%" fxLayout="row" fxLayoutAlign="center">
                <mat-spinner></mat-spinner>
            </div>
            <div *ngIf="productForm && loading == false" fxLayout="row">
                <form [formGroup]="productForm" fxFlex="auto" fxLayout="column">
                    <mat-form-field  appearance="outline">
                        <mat-label>Google Workspace Product</mat-label>
                        <mat-select placeholder="Google Workspace Product" formControlName="sku">
                         <mat-option *ngFor="let product of productsList" [value]="product">
                           {{ product.skuName }}
                         </mat-option>
                        </mat-select>
                        <mat-error *ngIf="productForm.get('sku').errors">Google Workspace SKU is required</mat-error>
                    </mat-form-field>
                    <mat-form-field  appearance="outline">
                        <mat-label>Stripe Price</mat-label>
                        <mat-select placeholder="Select Price Nickname" formControlName="price">
                         <mat-option *ngFor="let price of pricesList" [value]="price">
                           {{ price.nickname }}
                         </mat-option>
                       </mat-select>
                        <mat-error *ngIf="productForm.get('price').errors">Price ID is required</mat-error>
                    </mat-form-field>
                    <!--
                    <mat-form-field  appearance="outline">
                        <mat-label>Coupon (Optional)</mat-label>
                        <mat-select placeholder="Select Price Nickname" formControlName="coupon">
                         <mat-option *ngFor="let coupon of couponsList" [value]="coupon">
                           {{ coupon.name }}
                         </mat-option>
                       </mat-select>
                    </mat-form-field>
                    -->
                    <mat-form-field  appearance="outline">
                        <mat-label>Proration Date (Optional)</mat-label>
                        <input matInput [matDatepicker]="picker" [value]="" [min]=""  formControlName="date" (click)="picker.open()" placeholder="Choose a date" readonly required>
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker touchUi #picker></mat-datepicker>
                    </mat-form-field>
                </form>
            </div>
        </div>
    </div>
    <div mat-dialog-actions align="end" fxLayout="row">
        <button mat-button color="primary" (click)="closeaddProductDialog()">Cancel</button>
        <button mat-button color="warn" [disabled]="productForm && productForm.invalid" (click)="addProductSave(productForm.value, data)" cdkFocusInitial>Continue</button>
    </div>
</ng-template>


  <!-- Add User Dialog template -->

<ng-template #addUserDialog  let-data>
    <button mat-icon-button class="mat-dialog-cancel" color="primary" (click)="closeaddUserDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <h4 mat-dialog-title> Whitelist a user for {{data.customer.organization}} </h4>
    <div mat-dialog-content>
        <form [formGroup]="addUserForm" fxFlex="auto" fxLayout="column">
            <mat-form-field  appearance="outline">
                <mat-label>Email Address</mat-label>
                <input matInput placeholder="Ex. miles@hiviewcloud.com"  type="email" formControlName="email" required>
                <mat-error *ngIf="addUserForm.get('email').errors">Email is required</mat-error>
            </mat-form-field>
        </form>
    </div>
    <div mat-dialog-actions align="end">
        <button mat-button color="primary" (click)="closeaddUserDialog()">Cancel</button>
        <button mat-button color="warn" [disabled]="addUserForm && addUserForm.invalid" (click)="addUserSave(addUserForm.value, data)" cdkFocusInitial>Continue</button>
    </div>
</ng-template>

<!-- Add Customer Dialog template -->

<ng-template #addCustomerDialog  let-data>
    <button mat-icon-button class="mat-dialog-cancel" color="primary" (click)="closeaddCustomerDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <h4 mat-dialog-title> Add a new subscription </h4>
    <div mat-dialog-content>
        <mat-accordion [multi]="false" class="mat-elevation-z0">
            <mat-expansion-panel class="mat-elevation-z0"  [expanded]="expanded_single" *ngIf="expanded_multi == false" (closed)="expanded_single = false" (opened)="expanded_single = true">
                <mat-expansion-panel-header >
                    <mat-panel-title >
                        <div class="expanding-form-selector-container">
                            <div class="ratio-actions">
                                <mat-icon *ngIf="expanded_single == true">360</mat-icon>
                                <mat-radio-button *ngIf="expanded_single == false"></mat-radio-button>
                            </div>
                        </div>
                        Single commitment
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-horizontal-stepper linear #stepper *ngIf="expanded_single == true">
                    <mat-step [completed]="false">
                        <ng-template matStepLabel>Customer details</ng-template>
                        <form [formGroup]="customerForm" fxFlex="auto" fxLayout="column">
                            <mat-form-field  appearance="outline">
                                <mat-label>Organization Name</mat-label>
                                <input matInput placeholder="Ex. Roger Martinez" formControlName="name" required>
                                <mat-error *ngIf="customerForm.get('name').errors">Organization Name is required</mat-error>
                            </mat-form-field>
                            <div fxLayout="row" fxLayoutGap="5px" fxFlex="auto">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Google Workspace Admin Email</mat-label>
                                    <input matInput placeholder="Ex. miles@hiviewsolutions.com"  type="email" formControlName="email" required>
                                    <mat-error *ngIf="customerForm.get('email').errors">Email is required</mat-error>
                                </mat-form-field><mat-form-field  appearance="outline">
                                    <mat-label>SF Account</mat-label>
                                    <mat-select placeholder="Ex. HiView Dev" formControlName="sfId" >
                                        <mat-option *ngFor="let sf of sfList" [value]="sf.Id">
                                        {{ sf.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="customerForm.get('sfId').errors">The QBO account is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutGap="5px">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Primary Domain</mat-label>
                                    <input matInput placeholder="Ex. hiviewsolutions.com" formControlName="domain" required>
                                    <mat-error *ngIf="customerForm.get('domain').errors">Primary domain is required</mat-error>
                                </mat-form-field>
                                <mat-form-field  appearance="outline">
                                    <mat-label>QBO Account</mat-label>
                                    <mat-select placeholder="Ex. HiView Dev" formControlName="qbo" >
                                        <mat-option *ngFor="let qb of qboList" [value]="qb.Id">
                                        {{ qb.DisplayName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="customerForm.get('qbo').errors">The QBO account is required</mat-error>
                                </mat-form-field>
                            </div>
                            
                        </form>
                        <div align="end">
                            <button mat-button color="primary" [disabled]="customerForm && customerForm.invalid" matStepperNext type="button" (click)="nextStep(customerForm.value)">Next</button>
                        </div>  
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Subscription</ng-template>
                        <div fxLayout="column">
                            <div *ngIf="loading == true" fxFlex="100%" fxLayout="row" fxLayoutAlign="center">
                                <mat-spinner></mat-spinner>
                            </div>
                            <div *ngIf="loading == false" fxLayout="row">
                                <form [formGroup]="subscriptionForm" fxFlex="auto" fxLayout="column">
                                    <mat-form-field  appearance="outline">
                                        <mat-label>Google Workspace Product</mat-label>
                                        <mat-select placeholder="Google Workspace Product" formControlName="sku">
                                         <mat-option *ngFor="let product of productsList" [value]="product">
                                           {{ product.skuName }}
                                         </mat-option>
                                       </mat-select>
                                        <mat-error *ngIf="subscriptionForm.get('sku').errors">Google Workspace SKU is required</mat-error>
                                    </mat-form-field>
                                    <mat-form-field  appearance="outline">
                                        <mat-label>Stripe Price</mat-label>
                                        <mat-select placeholder="Select Price Nickname" formControlName="price">
                                         <mat-option *ngFor="let price of pricesList" [value]="price">
                                           {{ price.nickname }}
                                         </mat-option>
                                       </mat-select>
                                        <mat-error *ngIf="subscriptionForm.get('price').errors">Price ID is required</mat-error>
                                    </mat-form-field>
                                   
                                    <div fxLayout="row"  fxLayoutGap="20px">
                                        <!-- <mat-form-field  appearance="outline">
                                            <mat-label>Coupon (Optional)</mat-label>
                                            <mat-select placeholder="Select Price Nickname" formControlName="coupon">
                                             <mat-option *ngFor="let coupon of couponsList" [value]="coupon">
                                               {{ coupon.name }}
                                             </mat-option>
                                           </mat-select>
                                        </mat-form-field>
                                        -->
                                        <mat-form-field class="form-proration-date" appearance="outline">
                                            <mat-label>Proration Date</mat-label>
                                            <input matInput [matDatepicker]="picker" [value]="" [min]=""  formControlName="date" (click)="picker.open()" placeholder="Choose a date" readonly required>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #picker></mat-datepicker>
                                            <mat-error *ngIf="subscriptionForm.get('date').errors">Proration date is required</mat-error>
                                        </mat-form-field>
                                        <mat-form-field  appearance="outline">
                                            <mat-label>Add Processing Fee</mat-label>
                                            <mat-select [(value)]="selected" formControlName="fee" required>
                                              <mat-option [value]="true">Yes</mat-option>
                                              <mat-option [value]="false">No</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                   </div>
                                    
                                </form>
                            </div>
                            <div mat-dialog-actions align="end" fxLayout="row">
                                <button mat-button color="primary" (click)="closeaddCustomerDialog()">Cancel</button>
                                <button mat-button color="warn" [disabled]="subscriptionForm && subscriptionForm.invalid" (click)="addCustomerSave(customerForm.value, subscriptionForm.value)" cdkFocusInitial>Continue</button>
                            </div>    
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </mat-expansion-panel>
            <mat-expansion-panel  class="mat-elevation-z0" [expanded]="expanded_multi"  *ngIf="expanded_single == false" (closed)="expanded_multi = false" (opened)="expanded_multi = true">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="expanding-form-selector-container">
                            <div class="ratio-actions">
                                <mat-icon *ngIf="expanded_multi == true">multiple_stop</mat-icon>
                                <mat-radio-button *ngIf="expanded_multi == false"></mat-radio-button>
                            </div>
                        </div>
                        Staggered Discount commitment
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <mat-horizontal-stepper linear #stepper  *ngIf="expanded_multi == true">
                    <mat-step [completed]="false">
                        <ng-template matStepLabel>Customer details</ng-template>
                        <form [formGroup]="customerForm" fxFlex="auto" fxLayout="column">
                            <mat-form-field  appearance="outline">
                                <mat-label>Organization Name</mat-label>
                                <input matInput placeholder="Ex. Roger Martinez" formControlName="name" required>
                                <mat-error *ngIf="customerForm.get('name').errors">Organization Name is required</mat-error>
                            </mat-form-field>
                            <mat-form-field  appearance="outline">
                                <mat-label>Google Workspace Admin Email</mat-label>
                                <input matInput placeholder="Ex. miles@hiviewsolutions.com"  type="email" formControlName="email" required>
                                <mat-error *ngIf="customerForm.get('email').errors">Email is required</mat-error>
                            </mat-form-field>
                            <div fxLayout="row" fxLayoutGap="5px">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Primary Domain</mat-label>
                                    <input matInput placeholder="Ex. hiviewsolutions.com" formControlName="domain" required>
                                    <mat-error *ngIf="customerForm.get('domain').errors">Primary domain is required</mat-error>
                                </mat-form-field>
                                <mat-form-field  appearance="outline">
                                    <mat-label>QBO Account</mat-label>
                                    <mat-select placeholder="Ex. 1" formControlName="qbo" required>
                                        <mat-option *ngFor="let qb of qboList" [value]="qb.Id">
                                        {{ qb.DisplayName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="customerForm.get('qbo').errors">The QBO account is required</mat-error>
                                </mat-form-field>
                            </div>
                            
                        </form>
                        <div align="end">
                            <button mat-button color="primary" [disabled]="customerForm && customerForm.invalid" matStepperNext type="button" (click)="nextStep(customerForm.value)">Next</button>
                        </div>  
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Subscription</ng-template>
                        <div fxLayout="column">
                            <div *ngIf="loading == true" fxFlex="100%" fxLayout="row" fxLayoutAlign="center">
                                <mat-spinner></mat-spinner>
                            </div>
                            <div *ngIf="loading == false" fxLayout="row">
                                <form [formGroup]="multiSubsForm" fxFlex="auto" fxLayout="column">
                                    <div fxLayout="row"  fxLayoutGap="20px">
                                        <mat-form-field  appearance="outline">
                                            <mat-label>Google Workspace Product</mat-label>
                                            <mat-select placeholder="Google Workspace Product" formControlName="sku">
                                                <mat-option *ngFor="let product of productsList" [value]="product">
                                                {{ product.skuName }}
                                                </mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="multiSubsForm.get('sku').errors">Google Workspace SKU is required</mat-error>
                                        </mat-form-field>
                                        
                                        <mat-form-field  appearance="outline">
                                            <mat-label>Add Processing Fee</mat-label>
                                            <mat-select [(value)]="selected" formControlName="fee" required>
                                            <mat-option [value]="true">Yes</mat-option>
                                            <mat-option [value]="false">No</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                
                                    <div fxLayout="row"  fxLayoutGap="20px">
                                        <mat-form-field  appearance="outline">
                                            <mat-label>Stripe Price</mat-label>
                                            <mat-select placeholder="Select Price Nickname" formControlName="price1">
                                            <mat-option *ngFor="let price of pricesList" [value]="price">
                                            {{ price.nickname }}
                                            </mat-option>
                                        </mat-select>
                                            <mat-error *ngIf="multiSubsForm.get('price1').errors">Price ID is required</mat-error>
                                        </mat-form-field>
                                        
                                        <mat-form-field class="form-proration-date" appearance="outline">
                                            <mat-label>Proration Date</mat-label>
                                            <input matInput [matDatepicker]="picker" formControlName="date1"  placeholder="Choose a date" readonly required>
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #picker></mat-datepicker>
                                            <mat-error *ngIf="multiSubsForm.get('date1').errors">Date is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"  fxLayoutGap="20px">
                                        <mat-form-field  appearance="outline">
                                            <mat-label>Stripe Price</mat-label>
                                            <mat-select placeholder="Select Price Nickname" formControlName="price2">
                                            <mat-option *ngFor="let price of pricesList" [value]="price">
                                            {{ price.nickname }}
                                            </mat-option>
                                        </mat-select>
                                            <mat-error *ngIf="multiSubsForm.get('price2').errors">Price ID is required</mat-error>
                                        </mat-form-field>
                                        
                                        <mat-form-field class="form-proration-date" appearance="outline">
                                            <mat-label>Second Year</mat-label>
                                            <input matInput [matDatepicker]="picker2" formControlName="date2" placeholder="Choose a date" readonly required>
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #picker2></mat-datepicker>
                                            <mat-error *ngIf="multiSubsForm.get('date2').errors">Date is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"  fxLayoutGap="20px">
                                        <mat-form-field  appearance="outline">
                                            <mat-label>Stripe Price</mat-label>
                                            <mat-select placeholder="Select Price Nickname" formControlName="price3">
                                            <mat-option *ngFor="let price of pricesList" [value]="price">
                                            {{ price.nickname }}
                                            </mat-option>
                                        </mat-select>
                                            <mat-error *ngIf="multiSubsForm.get('price3').errors">Price ID is required</mat-error>
                                        </mat-form-field>

                                        <mat-form-field class="form-proration-date" appearance="outline">
                                            <mat-label>Third Year</mat-label>
                                            <input matInput [matDatepicker]="picker3" [value]="" formControlName="date3" placeholder="Choose a date" readonly required>
                                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #picker3></mat-datepicker>
                                            <mat-error *ngIf="multiSubsForm.get('date3').errors">Date is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    <div fxLayout="row"  fxLayoutGap="20px">
                                        <mat-form-field  appearance="outline">
                                            <mat-label>Stripe Price</mat-label>
                                            <mat-select placeholder="Select Price Nickname" formControlName="price4">
                                            <mat-option *ngFor="let price of pricesList" [value]="price">
                                            {{ price.nickname }}
                                            </mat-option>
                                        </mat-select>
                                            <mat-error *ngIf="multiSubsForm.get('price4').errors">Price ID is required</mat-error>
                                        </mat-form-field>
                                        
                                        <mat-form-field class="form-proration-date" appearance="outline">
                                            <mat-label>Remaining commitment</mat-label>
                                            <input matInput [matDatepicker]="picker4" formControlName="date4"  placeholder="Choose a date" readonly required>
                                            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                            <mat-datepicker touchUi #picker4></mat-datepicker>
                                            <mat-error *ngIf="multiSubsForm.get('date4').errors">Date is required</mat-error>
                                        </mat-form-field>
                                    </div>
                                    
                                </form>
                            </div>
                            <div mat-dialog-actions align="end" fxLayout="row">
                                <button mat-button color="primary" (click)="closeaddCustomerDialog()">Cancel</button>
                                <button mat-button color="warn" [disabled]="multiSubsForm && multiSubsForm.invalid" (click)="multiSubsSave(customerForm.value, multiSubsForm.value)" cdkFocusInitial>Continue</button>
                            </div>    
                        </div>
                    </mat-step>
                </mat-horizontal-stepper>
            </mat-expansion-panel>
        </mat-accordion>
    </div>    
</ng-template>


<!-- Add Offline Customer Dialog template -->

<ng-template #addOfflineDialog  let-data>
    <button mat-icon-button class="mat-dialog-cancel" color="primary" (click)="closeaddOfflineDialog()">
        <mat-icon>close</mat-icon>
    </button>
    <h4 mat-dialog-title> Add a new subscription </h4>
    <div mat-dialog-content>
        <form [formGroup]="offlineForm" fxFlex="auto" fxLayout="column">
                            <div fxLayout="row" fxLayoutGap="5px" fxFlex="auto">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Google Workspace Admin Email</mat-label>
                                    <input matInput placeholder="Ex. miles@hiviewsolutions.com"  type="email" formControlName="email" required>
                                    <mat-error *ngIf="offlineForm.get('email').errors">Email is required</mat-error>
                                </mat-form-field><mat-form-field  appearance="outline">
                                    <mat-label>SF Account</mat-label>
                                    <mat-select placeholder="Ex. HiView Dev" formControlName="sfId" >
                                        <mat-option *ngFor="let sf of sfList" [value]="sf.Id">
                                        {{ sf.Name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="offlineForm.get('sfId').errors">The SF account is required</mat-error>
                                </mat-form-field>
                            </div>
                            <div fxLayout="row" fxLayoutGap="10px">
                                <mat-form-field  appearance="outline">
                                    <mat-label>Primary Domain</mat-label>
                                    <input matInput placeholder="Ex. hiviewsolutions.com" formControlName="domain" required>
                                    <mat-error *ngIf="offlineForm.get('domain').errors">Primary domain is required</mat-error>
                                </mat-form-field>
                                <mat-form-field  appearance="outline">
                                    <mat-label>QBO Account</mat-label>
                                    <mat-select placeholder="Ex. 1" formControlName="qbo" required>
                                        <mat-option *ngFor="let qb of qboList" [value]="qb.Id">
                                        {{ qb.DisplayName }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="offlineForm.get('qbo').errors">The QBO account is required</mat-error>
                                </mat-form-field>
                            </div>
                            
                        </form>
    </div>    
    <div mat-dialog-actions align="end">
            <button mat-button color="primary" (click)="closeaddOfflineDialog()">Cancel</button>
            <button mat-button color="warn" [disabled]="offlineForm.value && offlineForm.invalid" (click)="addOfflineSave(offlineForm.value)" cdkFocusInitial>Continue</button>
        
    </div>
</ng-template>