<mat-card *ngIf="confirmation" class="mat-elevation-z0">
    <h2 mat-dialog-title>Signature management</h2>
    <mat-progress-bar mode="indeterminate" *ngIf="!signature"></mat-progress-bar>
    <mat-dialog-content [formGroup]="form">
        <p>{{message}}</p>
    </mat-dialog-content>
    
    <mat-dialog-actions>
            <button mat-raised-button color="warn" (click)="close()" *ngIf="type!='template'">No</button>
            <button mat-raised-button color="primary" (click)="save()" *ngIf="type!='template'">Yes</button>
            <button mat-raised-button color="primary" (click)="saveTemplate(1)" *ngIf="type=='template'">Save as new template</button>
            <button mat-raised-button color="accent" (click)="saveTemplate(2)" *ngIf="type=='template'">Update existing template</button>
            <button mat-raised-button color="warn" (click)="saveTemplate(3)" *ngIf="type=='template' && this.f.templateSelector">Delete existing template</button>
            <button mat-raised-button color="basic" (click)="cancelTemplate()" *ngIf="type=='template'">Cancel</button>
    </mat-dialog-actions>
</mat-card>

<mat-card *ngIf="tooltip" class="mat-elevation-z0" >
    <mat-card-header>
        <mat-card-title >Placeholders samples</mat-card-title>
    </mat-card-header>
    <mat-divider _ngcontent-rwj-c175="" role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal" style="margin-bottom: 10px;"></mat-divider>
    <div style="padding: 10px;">
        <div class="signature-contact-details" fxlayoutalign="start center">
            <mat-icon aria-hidden="true" class="signature-contact-details-icon">badge</mat-icon> 
            <span >employee_id: WD1234</span>
        </div>
        <div class="signature-contact-details" fxlayoutalign="start center">
            <mat-icon aria-hidden="true" class="signature-contact-details-icon">person</mat-icon> 
            <span >employee_name: John Doe</span>
        </div>
        <div class="signature-contact-details" fxlayoutalign="start center">
            <mat-icon aria-hidden="true" class="signature-contact-details-icon">picture_in_picture</mat-icon> 
            <span>employee_title: Designer</span>
        </div>
        <div class="signature-contact-details" fxlayoutalign="start center">
            <mat-icon aria-hidden="true" class="signature-contact-details-icon">support_agent</mat-icon> 
            <span>employee_W_phone: +0 123-456-789</span>
        </div>
        <div class="signature-contact-details" fxlayoutalign="start center">
            <mat-icon aria-hidden="true" class="signature-contact-details-icon">contact_phone</mat-icon> 
            <span>employee_H_phone: +0 155-222-333</span>
        </div>
        <div class="signature-contact-details" fxlayoutalign="start center">
            <mat-icon aria-hidden="true" class="signature-contact-details-icon">phone_iphone</mat-icon> 
            <span>employee_mobile: +0 555-236-789</span>
        </div>
        <div class="signature-contact-details" fxlayoutalign="start center">
            <mat-icon aria-hidden="true" class="signature-contact-details-icon">email</mat-icon> 
            <span>employee_email: mail@address.com</span>
        </div>
        <div class="signature-contact-details" fxlayoutalign="start center">
            <mat-icon aria-hidden="true" class="signature-contact-details-icon">domain</mat-icon>
            <span>employee_department: Marketing department</span>
        </div>
        <div class="signature-contact-details" fxlayoutalign="start center">
            <mat-icon aria-hidden="true" class="signature-contact-details-icon">location_on</mat-icon>
            <span>employee_address: Country, Street, 123/234</span>
        </div>
    </div>
        
        <mat-divider _ngcontent-rwj-c175="" role="separator" class="mat-divider mat-divider-horizontal" aria-orientation="horizontal" style="margin-bottom: 10px;"></mat-divider>
        <mat-dialog-actions>
            <button mat-raised-button color="accent" (click)="close()">Close</button>
        </mat-dialog-actions>
</mat-card>
