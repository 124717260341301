import { Component, OnInit, ViewChild, TemplateRef, Inject } from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import firebase from 'firebase/app';
import { first, map } from 'rxjs/operators';
import { User } from '@shared/interfaces/user';
import { CustomersService } from '@shared/services/customers/customers.service';
import { RoutesService } from '@shared/services/navigation/routes.service';
import { ProgressService } from "@shared/services/navigation/progress.service";
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatDialogConfig, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';
import { BreakpointObserver } from '@angular/cdk/layout';
import { TableUtil } from "./tableUtil";

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class AdminComponent implements OnInit {
  timeout: any = null;
  expanded_single = false;
  expanded_multi = false;
  loading = false;
  users2: User[] = [];
  expandedElement: any;
  isEditable: boolean = false;
  qboEditable: boolean = false;
  productForm;
  addUserForm;
  gsuiteSubscriptions;
  pricesList;
  qboList;
  sfList;
  productsList;
  couponsList;
  subscriptionForm;
  multiSubsForm;
  customerForm;
  offlineForm;
  settingsForm;
  qboForm;

  @ViewChild('stepper') stepper;

  customersSource  = new MatTableDataSource([]); //projects paginator
  @ViewChild('customersPaginator', {static: true}) customersPaginator: MatPaginator;
  columnsToDisplay = ['organization', 'domain', 'adminEmail', 'setup_status', 'pm_status', 'portal'];

  @ViewChild(MatTable) table: MatTable<any>;

  @ViewChild('addCustomerDialog') addCustomerDialog: TemplateRef<any>;
  public addCustomerDialogRef: MatDialogRef<TemplateRef<any>>;

  @ViewChild('addOfflineDialog') addOfflineDialog: TemplateRef<any>;
  public addOfflineDialogRef: MatDialogRef<TemplateRef<any>>;

  @ViewChild('addProductDialog') addProductDialog: TemplateRef<any>;
  public addProductDialogRef: MatDialogRef<TemplateRef<any>>;

  @ViewChild('addUserDialog') addUserDialog: TemplateRef<any>;
  public addUserDialogRef: MatDialogRef<TemplateRef<any>>;
  
  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    private accounts: CustomersService,
    private crouter: RoutesService,    
    public dialog: MatDialog,
    public snack: MatSnackBar,
    private fb: FormBuilder,
    private progress: ProgressService,
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {

      this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
        this.columnsToDisplay = result.matches ?
        ['organization'] :
        ['organization', 'domain', 'adminEmail', 'setup_status', 'pm_status', 'portal'];
      });
       
      this.accounts.getPrices().subscribe(prices => {
        this.pricesList = prices;
      });
       
      this.accounts.sf().subscribe(res=>{
        console.log(res);
        this.sfList = res;
      })
      
      this.accounts.qboCustomers().subscribe((res:any) => {
        console.log('QBO response', res)
        this.qboList = res.QueryResponse.Customer;
      });
      /* Get coupons (temporary removed)
      this.accounts.getCoupons().subscribe(res => {
        this.couponsList = res;
      });
      */
      this.productsList = [];
      this.productForm = this.fb.group({
        sku: ['', [Validators.required]],
        price: ['', [Validators.required]],
        coupon: [''],
        date: ['']
      });
      
      this.addUserForm = this.fb.group({
        email: ['', [Validators.required]],
      });
      this.customerForm = this.fb.group({
        name: ['', [Validators.required]],
        email: ['', [Validators.required]],
        domain: ['', [Validators.required]],
        qbo: [''],
        sfId: ['']
      });

      
      this.offlineForm = this.fb.group({
        email: ['', [Validators.required]],
        domain: ['', [Validators.required]],
        sfId: [''],
        qbo: ['']
      });
  
      this.subscriptionForm = this.fb.group({
        sku: ['', [Validators.required]],
        price: ['', [Validators.required]],
        coupon: [''],
        date: ['', [Validators.required]],
        fee: ['', [Validators.required]]
      });

      this.multiSubsForm = this.fb.group(({
        sku: ['', [Validators.required]],
        price1: ['', [Validators.required]],
        price2: ['', [Validators.required]],
        price3: ['', [Validators.required]],
        price4: ['', [Validators.required]],
        date1: ['', [Validators.required]],
        date2: ['', [Validators.required]],
        date3: ['', [Validators.required]],
        date4: ['', [Validators.required]],
        fee: ['', [Validators.required]]
      }))
      

    //get general settings
    this.afs.collection('settings').doc('general').collection('app').doc('site').valueChanges().subscribe( res => {
      this.settingsFormInit(res);
    })
    
      

    //get qbo settings
    this.afs.collection('settings').doc('qb').valueChanges().subscribe( res => {
      this.qboFormInit(res);
    })
    

   }

   exportUsers() {
    const newArray = [];
    const newSubs = [];
    for(let obj of this.customersSource.filteredData){
      for(let e of obj.team || []){
        let newObj = {
          "Domain": obj.domain,
          "Customer": obj.organization,
          "Email": e
        }
        newArray.push(newObj)
      }
      for (let i = 0; i < obj.subscriptions.length; i++) {
        obj['price'+[i]] = obj.subscriptions[i]['price_id']
        obj['name'+[i]]  =  obj.subscriptions[i]['name']
        obj['sku'+[i]] = obj.subscriptions[i]['sku']
        obj['Quantity'+[i]] = obj.subscriptions[i]['quantity']
      }
      
      obj.team     =  obj.team? Object.values(obj.team).join(",") : [];
      obj.default_source = obj.default_source?.type || null;
    }
    TableUtil.exportArrayToExcel(this.customersSource.filteredData);
  }

   qboAuth(){
     this.progress.setProgress(true);
     this.accounts.qboAuth().subscribe((res:any)=>{
       console.log(res);
       window.open(res.authUri, "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=400,height=400");
       this.progress.setProgress(false);
     })
   }

   qboFormInit(data){
     this.qboForm = this.fb.group({
       serviceID: [{value: data? data.serviceID: '', disabled: true}, [Validators.required]],
     });
   }

   qboSettings(v){
     this.qboEditable = false;
     this.afs.collection('settings').doc('qb').set(v, { merge: true }).then( ()=>{
       this.openSnackBar("The settings were updated", "X Close");
       this.disableForm();
     }).catch(err =>{
       this.openSnackBar("Error: "+err, "X Close");
     });
   }  

  settingsFormInit(data){
    this.settingsForm = this.fb.group({
      siteName: [{value: data? data.siteName: '', disabled: true}, [Validators.required]],
      email: [{value: data? data.email : '', disabled: true}, [Validators.required]],
      leftBar: [{value: data? data.leftBar: true, disabled: true}, [Validators.required]]
    });
  }  

  updateSettings(v){
    this.isEditable = false;
    this.afs.collection('settings').doc('general').collection('app').doc('site').set(v).then( ()=>{
      this.openSnackBar("The settings were updated", "X Close");
      this.disableForm();
    }).catch(err =>{
      this.openSnackBar("Error: "+err, "X Close");
    });
  }
    
  enableForm(){
    this.settingsForm.controls['siteName'].enable();
    this.settingsForm.controls['email'].enable();
    this.settingsForm.controls['leftBar'].enable();
    this.isEditable = true;
  }

  disableForm(){
    this.settingsForm.controls['siteName'].disable();
    this.settingsForm.controls['email'].disable();
    this.settingsForm.controls['leftBar'].disable();
    this.isEditable = false;
  }
    
  enableqboForm(){
    this.qboForm.controls['serviceID'].enable();
    this.qboEditable = true;
  }

  disableqboForm(){
    this.qboForm.controls['serviceID'].disable();
    this.qboEditable = false;
  }

  addPrice(){
    const data = {id: ''}
    this.progress.setProgress(true);
    this.accounts.addPrice(data).subscribe((res:any) => {
      if(res.id){
      this.openSnackBar("The price has been created", "X Close");
      this.progress.setProgress(false);
      }else{
        console.log(res.error)
        this.openSnackBar(res.error, "X Close");
        this.progress.setProgress(false);
      }
    })
  }

  addCustomer(): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.restoreFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.role = 'dialog';
    dialogConfig.width = '650px';
  //  dialogConfig.width = '550px'; +coupons dialog -temp removal 
    dialogConfig.data = {products: [], prices:[], loading :false};
    this.addCustomerDialogRef = this.dialog.open(this.addCustomerDialog,  dialogConfig);
  }

 removeCustomer(plan, customer, index){
  this.progress.setProgress(true);
  this.afs.collection('customers').doc(customer.id).collection('subscriptions').doc(plan.sku).delete().then(() => {
    this.openSnackBar(plan.name+ " was removed from " +customer.organization, "X Close");
    this.expandedElement = this.expandedElement.id === this.customersSource.data[index].id ? this.customersSource.data[index] : this.expandedElement;
    this.progress.setProgress(false);
 }).catch(function(error) {
   this.openSnackBar(error, "X Close");
   this.progress.setProgress(true);
 })

 }

 feeUpdate(v:Boolean, c){
  // v = true;
   this.progress.setProgress(true);
   if(c.subscription) {
     const Data = {
       id: c.id,
       subscription: c.subscription,
       fee: v,
       default: c.default_source.type
     }
     this.accounts.updateFee(Data).subscribe((res:any) => { 
       if(!res.error){
         this.openSnackBar('The Google Workspace Process Fee has been updated', "X Close");
          this.progress.setProgress(false);
       }else {
         this.openSnackBar(res.error, "X Close");
         this.progress.setProgress(false);
       }
     })
   }
   else{
     this.afs.collection('customers').doc(c.id).update({fee: v}).then(()=>{
       this.openSnackBar('The Google Workspace Process Fee has been updated', "X Close");
       this.progress.setProgress(false);
      }).catch(() => {
        this.openSnackBar('An error ocurred', "X Close");
        this.progress.setProgress(false);
      })
    }
  }

  voiceTax(v:Boolean, c){
    clearTimeout(this.timeout);
    var $this = this;
    $this.timeout = setTimeout(function () {
      // v = true;
    $this.progress.setProgress(true);
    if(c.subscription) {
      const Data = {
        id: c.id,
        subscription: c.subscription,
        per: v,
        fee: c.fee,
        default_source: c.default_source.type
      }
      $this.accounts.voiceTax(Data).subscribe((res:any) => { 
        if(!res.error){
            $this.openSnackBar('The Google Voice Tax rate has been updated', "X Close");
            this.progress.setProgress(false);
        }else {
          $this.openSnackBar(res.error, "X Close");
          $this.progress.setProgress(false);
        }
      })
    }
    else{
      $this.afs.collection('customers').doc(c.id).update({voiceTax: v}).then(()=>{
        $this.openSnackBar('The Google Voice Tax rate has been updated', "X Close");
        $this.progress.setProgress(false);
       }).catch(() => {
        $this.openSnackBar('An error ocurred', "X Close");
        $this.progress.setProgress(false);
       })
     }

    }, 2000);
}


  eventCheck(checked: boolean, e, index){
    this.progress.setProgress(true);
    this.afs.collection('customers').doc(e.id).update({allow_cc: checked}).then(()=>{
      this.openSnackBar('The setting has been updated', 'X Close');
      this.expandedElement = this.expandedElement.id === this.customersSource.data[index].id ? this.customersSource.data[index] : this.expandedElement;
      this.progress.setProgress(false);
    }).catch(function(error) {
      this.openSnackBar(error, "X Close");
      this.progress.setProgress(true);
    })
  }

  addProduct(index): void {
    this.getProducts(this.expandedElement.domain);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.restoreFocus = false;
    dialogConfig.autoFocus = false;
    dialogConfig.role = 'dialog';
    dialogConfig.width = '450px';
    dialogConfig.data = {customer: this.expandedElement, products: [], index:index};
    this.addProductDialogRef = this.dialog.open(this.addProductDialog,  dialogConfig);
  }

  addProductSave(v, data){
  //  console.log(data)
    this.progress.setProgress(true);
    // Add subscription to the DB.  
    const customer = data.customer;
    const productData = {
      sid: customer.id,
      name: v.sku.skuName,
      price_id: v.price.id,
      sku: v.sku.subscriptionId,
      quantity: v.sku.seats.numberOfSeats ? v.sku.seats.numberOfSeats : v.sku.seats.maximumNumberOfSeats,
      subscription_id: customer.subscription ? customer.subscription : '',
      billing_cycle_anchor :  '',
      backdate_start_date: v.date ? v.date : '',
      coupon: v.coupon ? v.coupon.id : ''
    }
    if(customer && customer.subscription) {
      this.closeaddProductDialog();
      productData['fee']     = customer.fee? customer.fee : false;
      productData['default'] = customer.default_source? customer.default_source.type : '' ;
      this.accounts.addProduct([productData]).subscribe((res:any) =>{
        if(res.id){
              if (productData.name.includes('Voice')){
                productData['icon'] = 'voice'; 
              }else if (productData.name.includes('Identity')){
                productData['icon'] = 'identity'; 
              }else if (productData.name.includes('Drive')){
                productData['icon'] = 'drive'; 
              }else{
                productData['icon'] = 'gapps'; 
              }
            
            data.customer.subscriptions.push(productData);
            this.openSnackBar(productData.name+ " was added to " +data.customer.organization, "X Close");
            this.expandedElement = this.expandedElement.id === this.customersSource.data[data.index].id ? this.customersSource.data[data.index] : this.expandedElement;
            this.progress.setProgress(false);
          }else {
            this.openSnackBar('An error ocurred, please contact support', "X Close");
            this.progress.setProgress(false);
          }
        })
    } else{
      this.closeaddProductDialog();
      productData.backdate_start_date = (Date.parse(productData.backdate_start_date) / 1000) + (30 * 60 * 1000);
      this.accounts.addSubscriptionDB(data, productData)
      .then(() =>  {
        if (productData.name.includes('Voice')){
          productData['icon'] = 'voice'; 
        }else if (productData.name.includes('Identity')){
          productData['icon'] = 'identity'; 
        }else if (productData.name.includes('Drive')){
          productData['icon'] = 'drive'; 
        }else{
          productData['icon'] = 'gapps'; 
        }
        data.customer.subscriptions.push(productData);
        this.openSnackBar(productData.name+ " was added to " +data.customer.organization, "X Close");
        this.expandedElement = this.expandedElement.id === this.customersSource.data[data.index].id ? this.customersSource.data[data.index] : this.expandedElement;
        this.progress.setProgress(false);
      }).catch(function(error) {
        this.openSnackBar(error, "X Close");
        this.progress.setProgress(false);
      })
    }
  }

 closeaddProductDialog(): void {
  this.addProductDialogRef.close();
  this.productForm.reset();
 }

 removeProduct(plan, customer, index){
  this.progress.setProgress(true);
  this.afs.collection('customers').doc(customer.id).collection('subscriptions').doc(plan.sku).delete().then(() => {
    for(var i = customer.subscriptions.length - 1; i >= 0; i--) {
      if(customer.subscriptions[i].sku === plan.sku) {
        customer.subscriptions.splice(i, 1);
      }
    }
    this.openSnackBar(plan.name+ " was removed from " +customer.organization, "X Close");
    this.expandedElement = this.expandedElement.id === this.customersSource.data[index].id ? this.customersSource.data[index] : this.expandedElement;
    this.progress.setProgress(false);
 }).catch(function(error) {
   this.openSnackBar(error, "X Close");
   this.progress.setProgress(true);
 })

 }
  
 addUser(index): void {
   const dialogConfig = new MatDialogConfig();
   dialogConfig.restoreFocus = false;
   dialogConfig.autoFocus = false;
   dialogConfig.role = 'dialog';
   dialogConfig.width = '450px';
   dialogConfig.data = {customer: this.expandedElement, index:index};
   this.addUserDialogRef = this.dialog.open(this.addUserDialog,  dialogConfig);
 }

 addUserSave(values, data){
  console.log(data)
   this.progress.setProgress(true);
   this.afs.collection('customers').doc(data.customer.id).update( {
    team: firebase.firestore.FieldValue.arrayUnion(values.email)
  })
   .then(() => {
     data.customer.team.push(values.email);
     this.openSnackBar(values.email+ " was added to " +data.customer.organization, "X Close");
     this.expandedElement = this.expandedElement.id === this.customersSource.data[data.index].id ? this.customersSource.data[data.index] : this.expandedElement;
     this.closeaddUserDialog();
     this.progress.setProgress(false);
  }).catch(function(error) {
    console.log(error)
    this.closeaddUserDialog();
    this.openSnackBar(error, "X Close");
    this.progress.setProgress(false);
  
  })
 }

closeaddUserDialog(): void {
 this.addUserDialogRef.close();
 this.addUserForm.reset();
}

removeUser(u, customer, index){
  this.afs.collection('customers').doc(customer.id).update( {
     team: firebase.firestore.FieldValue.arrayRemove(u)
   }).then(() => {

    for(var i = customer.team.length - 1; i >= 0; i--) {
      if(customer.team[i] === u) {
        customer.team.splice(i, 1);
      }
    }
    this.openSnackBar(u+ " was removed from " +customer.organization, "X Close");
    this.expandedElement = this.expandedElement.id === this.customersSource.data[index].id ? this.customersSource.data[index] : this.expandedElement;
 }).catch(function(error) {
   this.openSnackBar(error, "X Close");
 })

}

 openSnackBar(message: string, action: string) {
   this.snack.open(message, action, {
     duration: 3000,
     horizontalPosition: 'right',
    });
  }
  
  getProducts(domain){
    this.loading = true;
    //clear any previous customers products
    this.productsList = [];
    this.accounts.getGSuiteSubscriptions(domain).subscribe(products => {
      this.productsList = products;
      console.log(products);
      this.loading = false;
    })
  }

  

  addCustomerSave(customer, product){

    const data = {customer, product:[product]};
    this.progress.setProgress(true);
    this.closeaddCustomerDialog();
    this.accounts.createCustomer(data).subscribe( (res:any) => {
      if(res.customer.id){
        const newCustomer = res.customer;
        newCustomer.subscriptions = res.subscription;
        for (let sub of newCustomer.subscriptions) {
          if (sub.name.includes('Voice')){
            sub.icon = 'voice'; 
          }else if (sub.name.includes('Identity')){
            sub.icon = 'identity'; 
          }else if (sub.name.includes('Drive')){
            sub.icon = 'drive'; 
          }else{
            sub.icon = 'gapps'; 
          }
        }
        newCustomer.pm_status = 'pending'
        let d = this.customersSource.data;
        d.push(newCustomer);
        this.customersSource.data = d;
        this.table.renderRows();
        this.openSnackBar('The customer '+customer.name+' has been created', 'X Close');
        this.progress.setProgress(false);
      }else if(res.error) {
        if(res.error.raw){
          this.openSnackBar(res.error.raw.message, "X Close");
        }else{
          this.openSnackBar(res.error, "X Close");
        }
        this.progress.setProgress(false);
      }else{
        this.openSnackBar('Unknown error', "X Close");
      }
    })
  }

 closeaddCustomerDialog(): void {
  this.addCustomerDialogRef.close();
  this.customerForm.reset();
  this.subscriptionForm.reset();
  this.expanded_single = false;
  this.expanded_multi  = false;
 }

 

 addOffline(): void {
  const dialogConfig = new MatDialogConfig();
  dialogConfig.restoreFocus = false;
  dialogConfig.autoFocus = false;
  dialogConfig.role = 'dialog';
  dialogConfig.width = '550px';
//  dialogConfig.width = '550px'; +coupons dialog -temp removal 
  dialogConfig.data = {products: [], prices:[], loading :false};
  this.addOfflineDialogRef = this.dialog.open(this.addOfflineDialog,  dialogConfig);
}
  

addOfflineSave(customer){
  this.progress.setProgress(true);
  this.closeaddOfflineDialog();
  this.accounts.createOffline(customer).subscribe( (res:any) => {
    if(res && res.id){
      let d = this.customersSource.data;    
      res['pm_status'] = 'Not required';
      d.push(res);
      this.customersSource.data = d;
      this.table.renderRows();
      this.openSnackBar('The customer '+res.organization+' has been whitelisted', 'X Close');
      this.progress.setProgress(false);
    }else if(res.error) {
        this.openSnackBar(res.error, "X Close");
      this.progress.setProgress(false);
    }else{
      this.openSnackBar('Unknown error', "X Close");
    }
  })
}

closeaddOfflineDialog(): void {
this.addOfflineDialogRef.close();
this.offlineForm.reset();
}



 multiSubsSave(customer, product){
  const data = {customer, product};
  this.progress.setProgress(true);
  this.closeaddCustomerDialog();
  this.accounts.scheduleSub(data).subscribe( (res:any) => {
    if(res.customer.id){
      const newCustomer = res.customer;
      newCustomer.subscriptions = [res.subscription];
      for (let sub of newCustomer.subscriptions) {
        if (sub.name.includes('Voice')){
          sub.icon = 'voice'; 
        }else if (sub.name.includes('Identity')){
          sub.icon = 'identity'; 
        }else if (sub.name.includes('Drive')){
          sub.icon = 'drive'; 
        }else{
          sub.icon = 'gapps'; 
        }
      }
      newCustomer.pm_status = 'pending'
      let d = this.customersSource.data;
      d.push(newCustomer);
      this.customersSource.data = d;
      this.table.renderRows();
      this.openSnackBar('The customer '+customer.name+' has been created', 'X Close');
      this.progress.setProgress(false);
    }else if(res.error) {
      if(res.error.raw){
        this.openSnackBar(res.error.raw.message, "X Close");
      }else{
        this.openSnackBar(res.error, "X Close");
      }
      this.progress.setProgress(false);
    }else{
      this.openSnackBar('Unknown error', "X Close");
    }
  })
}


 async nextStep(v) {
  await this.getProducts(v.domain);
  // complete the current step
  this.stepper.selected.completed = true;
  // move to next step
  this.stepper.next();
}

applyFilter(event: Event) {
  const filterValue = (event.target as HTMLInputElement).value;
  this.customersSource.filter = filterValue.trim().toLowerCase();
}

  ngOnInit() {
      this.progress.setProgress(true);
      this.accounts.getAll().pipe(first()).subscribe(res => {
          res.forEach((cus:any) => {
            //list users associated to subscriptions
              this.afs.collection('customers').doc(cus.id).collection('subscriptions').valueChanges().pipe(first()).subscribe(subscriptions => {
                for (let sub of subscriptions) {
                  if (sub.name.includes('Voice')){
                    sub.icon = 'voice'; 
                  }else if (sub.name.includes('Identity')){
                    sub.icon = 'identity'; 
                  }else if (sub.name.includes('Drive')){
                    sub.icon = 'drive'; 
                  }else{
                    sub.icon = 'gapps'; 
                  }
                }
                cus.subscriptions = subscriptions;
              });
              
            //Get payment method status
            if(cus.setup_status == 'complete' && cus.default_source.type == 'bank_account'){
              this.afs.collection('customers').doc(cus.id).collection('payment_method').doc(cus.default_source.id).valueChanges().pipe(first()).subscribe((pm:any)=> {
                if(pm.status != ('verified' || 'validated')){
                  cus.pm_status = 'pending';
                }else{   
                  cus.pm_status = 'verified';
                }
              });
            }else if(cus.setup_status == 'complete'){
              cus.pm_status = 'verified';
            }else{
              cus.pm_status = 'pending';
            }

            if(cus.portal =='offline'){
              cus.pm_status = 'Not required';
            }
            
          })
          this.customersSource.data = res;
          this.progress.setProgress(false);
          console.log(res)
      });
      
    this.crouter.setRoute('Admin Panel');
    
    this.customersSource.paginator = this.customersPaginator;
    let date = new Date(), m = date.getMonth(), y = date.getFullYear(), sy  = date.getFullYear()+1, ty  = date.getFullYear()+2, ly  = date.getFullYear()+3;
    let fd   = new Date(y, m, 1), sd   = new Date(sy, m, 1), td   = new Date(ty, m, 1), ld   = new Date(ly, m, 1);
    this.multiSubsForm.controls['date1'].setValue(fd);
    this.multiSubsForm.controls['date2'].setValue(sd);
    this.multiSubsForm.controls['date3'].setValue(td);
    this.multiSubsForm.controls['date4'].setValue(ld);
  }

}