import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from "../../shared/services/auth/auth.service";
import { CustomersService } from '../../shared/services/customers/customers.service';
import { ProgressService } from "../../shared/services/navigation/progress.service";
import { RoutesService } from "../../shared/services/navigation/routes.service";
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SnackService } from '../../shared/services/snackbar/snack.service';

export interface DialogData {
  data:any;
}

@Component({
  selector: 'app-paymethod',
  templateUrl: './paymethod.component.html',
  styleUrls: ['./paymethod.component.css']
})
export class PaymethodComponent implements OnInit {
  
  paymethods;
  editCardForm;
  cardloadStatus;
  allow_cc;

  constructor(
    private customers:CustomersService, 
    private auth:AuthService, 
    public dialog: MatDialog,
    private fb: FormBuilder,
    private crouter:RoutesService,
    private progress: ProgressService
    ) {
    this.auth.user.subscribe(user => {
      this.getCustomerDetails(user);
      this.listPayMethods(user);
    })
   }
  
  getCustomerDetails(user){
    this.customers.getDbCustomer(user.email).valueChanges().subscribe((cus:any) =>{
      this.allow_cc = cus[0].allow_cc;
    })
  }
  listPayMethods(user){
    this.progress.setProgress(true);
    this.customers.getPaymentMethodDB(user.sid).valueChanges().subscribe((res :any) => {
      
      // display the default payment method first and add new default value
      res.forEach(function(item,i){
        if(item.isDefault === true){
          res.splice(i, 1);
          res.unshift(item);
        }
      });
      this.paymethods = res;
      this.progress.setProgress(false);
    })
  } 

  editPm(data, i, allow?){
    data.allow_cc = allow;
    this.dialog.open(editPaymentMethodDialog, {
      data: data,
      height: 'auto',
      width: '450px',
    });
  }

  ngOnInit(): void {
    this.crouter.setRoute('Billing > Payment methods');
  }

}


@Component({
  selector: 'edit-payment-method-dialog',
  templateUrl: './edit-payment-method-dialog.html',
  styleUrls: ['./paymethod.component.css']
})

export class editPaymentMethodDialog {
  
  editCardForm;
  cardloadStatus;
  cardData;
  bankVerificationForm;

  constructor(
    public editDialogRef: MatDialogRef<editPaymentMethodDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private fb: FormBuilder, 
    private snack: SnackService,
    public dialog: MatDialog,
    private auth :AuthService,
    private customers:  CustomersService,
    private progress: ProgressService
    ) {
     this.initiateFormCard(this.data)
     this.initiateVerificationForm(this.data);
  }

  initiateFormCard(card){
    this.editCardForm = this.fb.group({
      object: ['card'],
      exp_month: [card.exp_month, [Validators.required, Validators.pattern('^(0?[1-9]|1[012])$'), Validators.minLength(2)]],
      exp_year: [card.exp_year, [Validators.required, Validators.pattern('^2[0-9]|20[2-9][0-9]$'), Validators.minLength(2)]],
      address_zip: ['', [Validators.required, Validators.minLength(4)]]
    })
    this.cardloadStatus = false;
  }

  initiateVerificationForm(data){
    this.bankVerificationForm = this.fb.group({
      first: ['', [Validators.required, Validators.minLength(2)]],
      second: ['', [Validators.required, Validators.minLength(2)]],
    })
  }

  removePm(data): void {
    const confirmationDialogRef = this.dialog.open(confirmationDialog, {
      width: '450px',
      data: data
    });

    confirmationDialogRef.afterClosed().subscribe(data => {
      if(data){
          this.progress.setProgress(true);
          let source = {'source': data.id, 'id': data.customer};
          this.customers.removeSource(source).subscribe((card:any) => {
            if(!card.error && card.deleted == true){
              this.customers.removePaymentMethodDB(data.customer, data.id).then(data => {
                this.snack.openSnackBar('The payment method has been deleted', "X Close");
              }).catch(function(error) {
               this.snack.openSnackBar('error', "X Close");
              })
            }else{
              this.snack.openSnackBar('An error ocurred, please try again later or contact us at support@hiviewsolutions.com', "X Close");
            }
           })
        
      }
      this.editDialogRef.close();
      this.progress.setProgress(false);
    });
  }

  bankVerification(values, data){
    this.progress.setProgress(true);
    const newObj = {
      object: data.object,
      id: data.id,
      customer: data.customer,
      amounts: [values.first, values.second]

    }
    
    this.customers.verifySource(newObj).subscribe((new_item:any) =>{
      if(!new_item.error){
        this.customers.addPaymentMethodDB(data.customer, new_item).then(() => {
            this.snack.openSnackBar('Successful verification!', "X Close");
            this.editDialogRef.close();
            this.progress.setProgress(false);
          }).catch(function(error) {
           console.log('Error:'+error);
           this.snack.openSnackBar('Error', "X Close");
           this.editDialogRef.close();
           this.progress.setProgress(false);
          })
        }else{
          if(new_item.error.includes("The amounts")){
            this.snack.openSnackBar(new_item.error, "X Close");
            this.editDialogRef.close();
            this.progress.setProgress(false);
          }else{
            this.snack.openSnackBar('An error ocurred, please try again later or contact us at support@hiviewsolutions.com', "X Close");
            this.editDialogRef.close();
            this.progress.setProgress(false);
          }
        }
    })
  }

  updateCard(values, data){
   
    this.progress.setProgress(true);
    const newObj = {
      exp_month: values.exp_month,
      exp_year: values.exp_year,
      address_zip: values.address_zip
    }
    
    let source = {'source': data.id, 'id': data.customer, 'params': newObj};
    this.customers.updateSource(source).subscribe((new_item:any) =>{
      if(!new_item.error){
        this.customers.addPaymentMethodDB(data.customer, new_item).then(() => {
            this.progress.setProgress(false);
            this.snack.openSnackBar('The payment method has been saved', "X Close");
          }).catch(function(error) {
           this.progress.setProgress(false);
           console.log('Error:'+error);
           this.snack.openSnackBar('Error', "X Close");
          })
        }else{
        this.progress.setProgress(false);
        this.snack.openSnackBar('An error ocurred, please try again later or contact us at support@hiviewsolutions.com', "X Close");
      }
    })
    this.editDialogRef.close();
  }
  
  setPrimary(data){
    this.progress.setProgress(true);
    const source = {id: data.customer, default_source: data.id}
    this.customers.updateDefaultSource(source).subscribe((res:any) =>{
      if(!res.error && res.id){
        this.customers.updateDefaultSourceDB(data).then(()=>{
          this.snack.openSnackBar('The default payment method has been updated', 'X close');
          this.progress.setProgress(false);
        }).catch(err => {
          console.log(err)
          this.snack.openSnackBar('Error', "X Close");
          this.progress.setProgress(false);
        })
      }else if(res.error){
        console.log(res.error)
        this.snack.openSnackBar('Connection error', "X Close");
        this.progress.setProgress(false);
      }
    })
    this.editDialogRef.close();
  }

}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: './confirmation-dialog.html',
  styleUrls: ['./paymethod.component.css']
})

export class confirmationDialog {
  constructor(
    public dialogRef: MatDialogRef<confirmationDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

    onNoClick(): void {
      this.dialogRef.close();
    }
}