import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../shared/services/auth/auth.service";
import { RoutesService } from '@app/shared/services/navigation/routes.service';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {

  userInfo;

  constructor(private auth:AuthService, private crouter:RoutesService) {
    this.auth.user.subscribe(user =>{
      this.userInfo = user;
    })
   }

   go(){
    // console.log('test')
   }


  ngOnInit(): void {
    this.crouter.setRoute('Contact Us');
  }

}
