import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

import {FormBuilder, Validators, FormGroup} from "@angular/forms";
import { MailService } from "../../shared/services/mail/mail.service";

@Component({
    selector: 'confirm-sg-dialog',
    templateUrl: './confirm-sg-dialog.component.html',
    styleUrls: ['./confirm-sg-dialog.component.css']
})
export class ConfirmSgDialogComponent implements OnInit {

    form: FormGroup;
    description:string;
    userInfo;
    f;
    e;
    message;
    type;
    signature = true;
    template = false;
    confirmation = true;
    tooltip = false;

    constructor(
        private fb: FormBuilder,
        private mail:MailService,
        private dialogRef: MatDialogRef<ConfirmSgDialogComponent>, 
        @Inject(MAT_DIALOG_DATA) {
            form_,
            editor,
            user,
            message,
            type
        }: any ) {

        this.description = 'Dummy';
        this.userInfo = user;
        this.f = form_;
        this.e = editor;
        this.message = message;
        this.type = type;

        if(this.type == 'tooltip'){
            this.confirmation = false;
            this.tooltip = true;
        }else{
            this.confirmation = true;
            this.tooltip = false;
        }

        this.form = fb.group({
            description: ['Dummy', Validators.required],
            category: ['Dummy', Validators.required],
            releasedAt: ['Dummy', Validators.required],
            longDescription: ['Dummy',Validators.required]
        });

    }

    ngOnInit() {

    }


    save() {
        if(this.type=='save'){
            this.signature = false;
            let data;
            data = {"sendAsParam":this.f.employee,
                    "userIdParam":"me",
                    "body":this.e.editor.getContent(),
                    "customer":this.userInfo.gid,
                    "applyToAll": this.f.applyTo,
                    "emails": this.f.emails,
                    "domain": this.f.domain
                };
            this.mail.setSignature(data).subscribe((res:any) =>{
                if(res){
                    this.signature = true;
                    this.dialogRef.close('saved');
                
                }
            });
        }else{
            this.dialogRef.close(true);
        }
        
          
    }

    close() {
        if(this.type=='all')
            this.dialogRef.close('uncheck');
        else
            this.dialogRef.close();
    }

    saveTemplate(op){
        this.signature = false;
        let data;
        switch(op){
            case 1://save a new template
                data = {
                    "html":this.e.editor.getContent(),
                    "customer":this.userInfo.gid,
                    "template_id" : 0,
                    "action": "save"
                };
                break;
            case 2://update existing template
                data = {
                    "html":this.e.editor.getContent(),
                    "customer":this.userInfo.gid,
                    "template_id" : this.f.templateSelector,
                    "action": "update"
                };
                break;
            case 3://Delete existing template
                data = {
                    "html":this.e.editor.getContent(),
                    "customer":this.userInfo.gid,
                    "template_id" : this.f.templateSelector,
                    "action": "delete"
                };
                break;
        }
        this.mail.saveTemplate(data).subscribe((res:any) =>{
            if(res){
                this.signature = true;
                this.dialogRef.close('templateSaved');
            
            }
        });
    }

    cancelTemplate(){
        this.dialogRef.close();
    }
}