<div class="subscriptions-content">
 <div class="subscriptionWidgetLabel">Subscriptions</div>
 <div class="loadingCircle" *ngIf="inProgress">
 <mat-spinner class="inprogressCircle"></mat-spinner>
 </div>
 <div class="subscriptions-box">
  <mat-accordion class="subscriptions-headers-align" multi>
    <mat-expansion-panel [togglePosition]="'before'" *ngFor="let product of customer">
      <mat-expansion-panel-header  [collapsedHeight]="'100px'" [expandedHeight]="'100px'">
        <mat-panel-title  class="multiSkuSubscriptionHeaderMiddleSection">     
        <div class="subscriptionHeaderLeft">
            <img class="logoContainer" src="../../../assets/billing/{{product.icon}}.png">
        </div> 
        <div class="subscriptionEntityName">{{product.skuName}}</div>
        <div class="horizontalFieldColumn" *ngIf="product.seats">Users: {{product.seats.licensedNumberOfSeats}}</div>
        </mat-panel-title>
        <mat-panel-description class="singleSubscriptionHeaderRight">
           <span class="subscription-actions-text">Actions</span>
          <mat-icon>arrow_drop_down</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
       <div class="subscription-detail">
        <div class="subscription-detail-field">Payment plan</div><div class="subscription-detail-value">{{product.plan.planName}}</div>
        <div class="subscription-detail-field" *ngIf="product.seats">Users</div>
        <div class="subscription-detail-value" *ngIf="product.seats"> {{product.seats.licensedNumberOfSeats}}</div>
        <div class="subscription-detail-field" *ngIf="product.seats && product.seats.numberOfSeats">Licenses</div>
        <div class="subscription-detail-value" *ngIf="product.seats && product.seats.numberOfSeats"> {{product.seats.numberOfSeats}}</div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
 </div> 
</div>