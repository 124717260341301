import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import {FormControl, FormBuilder, Validators} from '@angular/forms';
import { AuthService } from "../../shared/services/auth/auth.service";
import { MailService } from "../../shared/services/mail/mail.service";
import { RoutesService } from '@app/shared/services/navigation/routes.service';
import { Settings } from '@shared/interfaces/settings';
import {MatDialog, MatDialogRef, MatDialogConfig, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AsyncSubject, Subject, Observable } from 'rxjs';
import { Observer } from 'rxjs/Rx';
import { map, startWith } from 'rxjs/operators';
import { ProgressService } from '@app/shared/services/navigation/progress.service';
import {ConfirmSgDialogComponent} from "./confirm-sg-dialog.component";
import {MatSnackBar} from '@angular/material/snack-bar';
import { HttpClient } from '@angular/common/http';

//import { maxLength } from './maxlength.validator';
export interface DialogData {
  animal: string;
  name: string;
}


@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.css']
})
export class SignatureComponent implements OnInit {

  userInfo;
  customerInfo;
  signatureForm;
  applyTo: Settings =  {emails: [], receiveAlerts: false, alertsThreshold: 0, receiveInvoice: false, oneEmployee: false};
  loadStatus;
  selected;
  employees: string[] = [''];
  filteredEmployee: Observable<string[]>;
  bodyControl = new FormControl("Hola!", Validators.required, );
  applyToControl = new FormControl(false, Validators.required, );
  employeeControl = new FormControl();
  emailsControl  = new FormControl();
  templateSelControl  = new FormControl();
  userProfile: string[] = ['','','',''];
  body;
  public userProfileName: string;
  public userProfileAddr: String;
  public userPhoto: SafeUrl;
  inProgress;
  durationInSeconds = 5;
  usersList: any;
  templatesList: any;
  templatesList_ids: any = [];
  selectedUsers: any = [];
  previousSelected;
  displaySelection: boolean = true;
  elRef: ElementRef;


  private editorSubject: Subject<any> = new AsyncSubject();

  constructor(
    private auth:AuthService, 
    private crouter:RoutesService, 
    private fb: FormBuilder,
    private mail:MailService,
    private dialog: MatDialog,
    protected sanitizer: DomSanitizer,
    private progress: ProgressService,
    private _snackBar: MatSnackBar
    ) {
  
    this.auth.user.subscribe(user =>{
      this.userInfo = user;
      this.formInit(user);
    })
  }

  formInit(user) {
    this.signatureForm = this.fb.group({
      domain: [{value: user.domain, disabled: true}, [Validators.required, Validators.min(0), Validators.max(20)]],
      applyTo: this.applyToControl,
      employee: this.employeeControl,
      emails: this.emailsControl,
      body: this.bodyControl,
      templateSelector: this.templateSelControl,
    });
    this.getEmployees(false,this.userInfo.domain,this.signatureForm.value);
    this.getTemplates(this.userInfo.gid);
  }

  signatureSubmit(f,e) {
    if(!this.displaySelection){
      f.emails = [];
    }
    if(!f.applyTo && !f.emails && f.employee==null){
      alert('Warning: Please select one of the options:\n-Apply to all\n-Apply to specific users\n-An existing signature to update.');
      return 0;
    }
    if(e.editor.getContent()==''){
      alert("Warning: Signature can't be empty.");
      return 0;
    }

    this.openDialog(f,e,'Are you sure you want to update the signature(s)?','save');
  }

  saveTemplate(e,f) {
    if(e.editor.getContent()==''){
      alert("Warning: Template content can't be empty.");
      return 0;
    }
    this.openDialog(f,e,'What do you want to do?','template');
  }

  openSnackBar(message) {
    this._snackBar.open("Success: " + message,'Close',{
      duration: this.durationInSeconds * 1000,
      panelClass: 'pizza-party',
    });
  }

  ngOnInit(): void {
    this.crouter.setRoute('Signature');
    
    this.filteredEmployee = this.employeeControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
  }
  
  getEmployees(checked,domain,f){
    this.inProgress = true;
    if(checked)
      f.applyTo = true;
    else
      f.applyTo = false;
    this.mail.getAllUsersByDomain({'domain': domain,'customer':this.userInfo.gid}).subscribe((res:any) =>{
      this.employees = res;
      this.usersList = res;
      this.filteredEmployee = this.employeeControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );
      this.inProgress = false;
      });
    
  }

  getTemplates(id){
    this.mail.getCustomerTemplates({'customer':id}).subscribe((res:any) =>{
      this.templatesList = res;
      let count = 1;
      this.templatesList_ids = [];
      this.templatesList.forEach(element => {
        this.templatesList_ids.push(count);
        count++;
      });
    });
  }

  applyToAllWarning(event,f){
    let checked = event.checked;
    if(checked){
      this.previousSelected = this.selectedUsers;
      f.applyTo = true;
      this.applyToControl.setValue(true);
      let message = "Warning: This action will update all employee\'s default signature. \nChanges can't be undone.\n\n Do you want to proceed?";

      this.openDialog(f,event,message,'all');
    }else{
      f.applyTo = false;
      this.applyToControl.setValue(false);
      this.selectedUsers = this.previousSelected;
      this.displaySelection = true;
    } 
    
  }
  applytoUsers(user){
    this.selectedUsers.push(user)
  }

  loadTemplate(selected,e,f){
    if(selected){
      e.editor.setContent(this.templatesList[selected-1].html);
    }else{
      e.editor.setContent('');
    }
  }

  getEmployeeSignature(event,e,f) {
    this.inProgress = true;
    if (event.source.selected) {
      let data = {"sendAsParam":event.source.value,"userIdParam":"me","user":event.source.value,'customer':this.userInfo.gid};
      this.mail.getSignature(data).subscribe((res:any) =>{
        e.editor.setContent(res.data.signature); 
        f.employee = event.source.value;
      });

      this.mail.getUserProfile(data).subscribe((res:any) =>{
        if(res){
          this.inProgress = false;
          this.userPhoto = res.data.thumbnailPhotoUrl;
          this.userProfileName = res.data.name.fullName;
          this.userProfileAddr = res.data.addresses? res.data.addresses[0]['formatted'] : null;
          this.userProfile[1] = res.data.primaryEmail;
          this.userProfile[2] = res.data.phones? res.data.phones[0]['value']:null;
        }else{
          this.userProfile = ['','','',''];
        }
      });
      
    }
  }

  clearForm(f,e){
    this.employeeControl.setValue('');
    this.applyToControl.setValue(false);
    this.applyTo['oneEmployee'] = false;
    this.selectedUsers = this.previousSelected;
    this.displaySelection = true;
    this.userProfile = ['','','',''];
    this.userProfileName = '';
    this.userProfileAddr = '';
    e.editor.setContent('');
    this.templateSelControl.setValue(null);
    this.emailsControl.setValue('');
    
  }

  handleEditorInit(e,value) {
    this.editorSubject.next(e.editor);
    this.editorSubject.complete();
    e.editor.setContent(value);
  }

  private _filter(value: string): string[] {
    const filterValue = this._normalizeValue(value);
    return this.employees.filter(employee => this._normalizeValue(employee).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  
  openDialog(f,e,m,type) {
    const dialogConfig = new MatDialogConfig();//this.dialog.open(DialogContentExampleDialog);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      form_: f,
      editor: e,
      user: this.userInfo,
      message: m,
      type: type,
      inProgress: this.inProgress
    };

    const dialogRef = this.dialog.open(ConfirmSgDialogComponent, dialogConfig);

    
    dialogRef.afterClosed().subscribe(result => {
      if(result=='saved'){
        this.clearForm(f,e);
        this.openSnackBar('Signature(s) saved successfully.');
      }else if(result=='templateSaved'){
        this.getTemplates(this.userInfo.gid);
        this.clearForm(f,e);
        this.openSnackBar('Template change(s) applied successfully.');
      }else if(result=='uncheck'){
        this.applyToControl.setValue(false);
      }else if (result == true && type == 'all'){
          this.selectedUsers = []
          this.displaySelection = false;
      }
        
    });
    
    
  }

  openInfo(){
    const dialogConfig = new MatDialogConfig();//this.dialog.open(DialogContentExampleDialog);
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      form_: null,
      editor: null,
      user: this.userInfo,
      message: '',
      type: 'tooltip',
      inProgress: this.inProgress
    };

    this.dialog.open(ConfirmSgDialogComponent, dialogConfig);
  }

   /**Method that will create Blob and show in new window */
   createBlobImageFileAndShow(uri): void {
    this.dataURItoBlob(uri).subscribe((blob: Blob) => {
      const imageBlob: Blob = blob;
      const imageName: string = this.generateName();
      const imageFile: File = new File([imageBlob], imageName, {
        type: "image/jpeg"
      });
      let generatedImage = window.URL.createObjectURL(imageFile);
      this.userPhoto = this.sanitizer.bypassSecurityTrustUrl(generatedImage);
    });
  }
  
    /* Method to convert Base64Data Url as Image Blob */
  dataURItoBlob(dataURI: string): Observable<Blob> {
    return Observable.create((observer: Observer<Blob>) => {
      const byteString: string = window.atob(dataURI);
      const arrayBuffer: ArrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array: Uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "image/jpeg" });
      observer.next(blob);
      observer.complete();
    });
  }

  /**Method to Generate a Name for the Image */
  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = "";
    const possibleText: string =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + "." + text + ".jpeg";
  }

  /* Method called from the UI */
  getImage(imageUrl: string) {
    this.getBase64ImageFromURL(imageUrl).subscribe((base64Data: string) => {
      this.createBlobImageFileAndShow(base64Data);
    });
  }

  /* Method to fetch image from Url */
  getBase64ImageFromURL(url: string): Observable<string> {
    return Observable.create((observer: Observer<string>) => {
      // create an image object
      let img = new Image();
      img.crossOrigin = "Anonymous";
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = err => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  /* Method to create base64Data Url from fetched image */
  getBase64Image(img: HTMLImageElement): string {
    // We create a HTML canvas object that will create a 2d image
    var canvas: HTMLCanvasElement = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
    // This will draw image
    ctx.drawImage(img, 0, 0);
    console.log(canvas);
    // Convert the drawn image to Data URL
    let dataURL: string = canvas.toDataURL("image/png");
    //this.base64DefaultURL = dataURL;
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
  }
  
}

@Component({
  selector: 'snack-bar-component',
  templateUrl: 'snack-bar-component.html',
  styles: [`
    .pizza-party {
      color: #9ccc65;
    }
  `],
})
export class PizzaPartyComponent {}