import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.goToUrl()
  }

  ngOnInit(): void {
  }

  goToUrl(): void {
    this.document.location.href = 'https://hiviewsolutions.my.site.com/customerportal/s/login/'; 
  }

}
