import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  private inProgress: BehaviorSubject<boolean>;

  constructor() {
    this.inProgress = new BehaviorSubject<boolean>(false);
  }

  getProgress(): Observable<boolean> {
    return this.inProgress.asObservable();
  }

  setProgress(newValue): void {
    this.inProgress.next(newValue);
  }


}