import { Component, OnInit } from '@angular/core';
import { CustomersService } from '../../shared/services/customers/customers.service'

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.css']
})
export class SubscriptionsComponent implements OnInit {
  customer: any;
  inProgress:boolean;
  constructor(
    public customers : CustomersService,
  ) {
    let user = JSON.parse(localStorage.getItem('user'));
    this.getCustomer(user.domain)
  }
  
  getCustomer(domain){
    this.inProgress = true;
    this.customers.getGSuiteSubscriptions(domain).subscribe((data:any) =>{
     // console.log(data)
      for (var i = 0; i < data.length; i++) {
        if (data[i].skuName.includes('Voice')){
          data[i].icon = 'voice'; 
        }else if (data[i].skuName.includes('Identity')){
          data[i].icon = 'identity'; 
        }else if (data[i].skuName.includes('Drive')){
          data[i].icon = 'drive'; 
        }else{
          data[i].icon = 'gapps'; 
        }

        if (data[i].plan.planName.includes('FLEXIBLE')){
          data[i].plan.planName = 'Flexible Plan'; 
        }else if (data[i].plan.planName.includes('ANNUAL')){
          data[i].plan.planName = 'Annual Commitment'; 
        }else if (data[i].plan.planName.includes('FREE')){
          data[i].plan.planName = 'Free Plan'; 
        }
      }
      
     this.inProgress = false;
     this.customer = data;
    // console.log(this.customer);
    })
  }
  ngOnInit(): void {
  }

}
