import { Injectable, NgZone} from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { share, switchMap, first, takeUntil, filter, startWith} from 'rxjs/operators';
import { User } from "@shared/interfaces/user";
import firebase from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { CustomersService } from '@shared/services/customers/customers.service';


@Injectable({
  providedIn: 'root'
})

export class AuthService {
 
  currentUser: Observable<User>;
  userMenu:  any;
  isAdmin:boolean;
  userData: any; // Save logged in user data
  customerDomain: any; // Save logged in user data

  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router, 
    public ngZone: NgZone, // NgZone service to remove outside scope warning
    private customer:  CustomersService
  ) {    

    this.currentUser = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.doc<User>(`users/${user.uid}`).valueChanges();
        } else {
          return observableOf(null);
        }
      })
    );

    /* Saving user data in localstorage when 
    logged in and setting up null when logged out */
    this.afAuth.authState.subscribe(user => {
      if (user) {
       this.GetUserInfo(user);
       localStorage.setItem('user', JSON.stringify(user));
       JSON.parse(localStorage.getItem('user'));
     } else {
       localStorage.setItem('user', null);
       JSON.parse(localStorage.getItem('user'));
     }
   });

 }


  get user(): Observable<User> {
    return this.currentUser.pipe(share());
  }

    // Get user info from the firebase database
  GetUserInfo(user) {
    this.afs.collection('users').doc(user.uid).valueChanges().subscribe((response) => {
      this.userData = response;
    });
  }

  // Returns true when user is looged in
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null) ? true : false;
  }

  // Returns true when user is looged in
   authStatus() {
    return this.afAuth.authState.pipe(first())
  }

  GoogleAuth() {
    return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

  // Auth logic to run auth provider
  AuthLogin(provider) {
    return this.afAuth.signInWithPopup(provider).then((result) => { 
      //user'domain
      let userEmail = result.user.email;
      if(userEmail == 'admin@hiviewsolutions.com' || userEmail == 'narjit@hiviewsolutions.com' || userEmail == 'jacobo@hiviewsolutions.com' || userEmail == 'jennifer.reese@hiviewsolutions.com' || userEmail == 'miles@hiviewsolutions.com' || userEmail == 'juan@hiviewsolutions.com'){
        result.user['role'] = 'admin';
        this.SetUserData(result.user).then(() => {
          this.ngZone.run(() => {
            this.router.navigateByUrl('/admin');
            //window.location.reload() //temp routing workaround
          });
        });
      }else{
        // Check if the user is authorized to access the service
        console.log('Checking if '+userEmail+ ' is whitelisted to access');
        this.customer.getDbCustomer(userEmail).valueChanges().subscribe((res:any) => {
          res = res[0]
          if (res && res.id){
            console.log(userEmail+ ' is already registered, checking the setup status');
            if(res.setup_status == 'pending'){
              console.log('Setup process is incomplete, redirecting to the setup page');
              result.user['sid'] = res.id;
              result.user['gid'] = res.gid;
              //add their domain
              result.user['domain'] = res.domain;
              this.SetUserData(result.user).then(() => {
                this.ngZone.run(() => {
                  this.router.navigateByUrl('/setup');
                  //window.location.reload() //temp routing workaround
                });
              });
  
            }else{
              console.log('Setup process is complete');
              result.user['sid'] = res.id;
              result.user['gid'] = res.gid;
              //add their domain
              result.user['domain'] = res.domain;
              this.SetUserData(result.user).then(() => {
                this.ngZone.run(() => {
                  this.router.navigateByUrl('/dashboard');
                  //window.location.reload() //temp routing workaround
                });
              });
            }
          }else{
            console.log('User Not found, removing the users data');
            this.SignOut();
            // Delete user
            this.deleteUser(result.user);
            window.alert('You are not authorized to access, please contact support@hiviewsolutions.com');
          }
        })
      }
    }).catch((error) => {
      window.alert(error)
    })
  }


  /* Setting up user data when sign in*/
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      role: user.role ? user.role : 'user',
      uid: user.uid,
      email: user.email,
      domain: user.domain ? user.domain : '',
      sid: user.sid ? user.sid : '', 
      gid: user.gid ? user.gid : '', 
      displayName: user.displayName,
      photoURL: user.photoURL,
      signatureSA: user.signatureSA ? user.signatureSA : '',
      signatureKey: user.signatureKey ? user.signatureKey : ''
    }
    return userRef.set(userData, {
      merge: true
    });
  }

  GetUserDomain(email) {
    let domain = null;
    const pos = email.search('@'); // get position of domain
    if (pos > 0) {
      domain = email.slice(pos+1); // use the slice method to get domain name, "+1" mean domain does not include "@"
    }
    return domain;
  }

 
  // Sign out 
  SignOut() {
    return firebase.auth().signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
      this.userData = '';
    })
  }

  deleteUser(user){
    user.delete().then(function() {
      // User deleted from auth
    this.SignOut();
    }).catch(function(error) {
      // An error happened.
    });
  }

}