import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';

const BASE_API_URL = environment.apiUrl;
//const TOKEN = 'ya29.a0AfH6SMDfPXpNutGVrYBHMkVVmeYy_PafsjttslZZeuDHGKhK7qWEnKHfILH_UqmCYvYVdVtC6zz84sH9M_oazNKEMxOpq102yjxdpfGPslPPSyhNqcuX_o4jMTJbWNUpCygYS58Ua3msue-TJKauRUPd5lBEBucS-3p7So0hWA';
const httpOptions = {
    headers: new HttpHeaders({ 'Access-Control-Allow-Methods':'GET, POST, PUT', responseType: 'json', 'Access-Control-Allow-Origin': '*'})
}

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private pageTokens: Array<string | number | null> = [null]; 

  constructor(public http: HttpClient,) { }

  //getSignature
  getSignature(data){
    let body = new HttpParams();
    body = body.set('userIdParam', data.userIdParam);
    body = body.set('sendAsParam',data.sendAsParam);
    body = body.set('customer',data.customer);
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/getSignature',body, httpOptions);
  }

  //setSignature
  setSignature(data){
    let body = new HttpParams();
    body = body.set('userIdParam', data.userIdParam);
    body = body.set('sendAsParam',data.sendAsParam);
    body = body.set('body',data.body);
    body = body.set('customer',data.customer);
    body = body.set('applyToAll',data.applyToAll);
    body = body.set('domain',data.domain);
    body = body.set('emails',data.emails);
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.put(BASE_API_URL+'/setSignature',body, httpOptions);
  }

  //setSignature
  getUserProfile(data){
    let body = new HttpParams();
    body = body.set('user',data.user);
    body = body.set('customer',data.customer);
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/getUserProfile',body, httpOptions);
  }

  

  //getAllUsers
  getAllUsersByDomain(data){
    let body = new HttpParams();
    body = body.set('domain',data.domain);
    body = body.set('customer',data.customer);
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/getAllUsersByDomain',body,httpOptions);
  }

  getCustomerTemplates(data){
    let body = new HttpParams();
    body = body.set('customer',data.customer);
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.post(BASE_API_URL+'/getCustomerTemplates',body,httpOptions);
  }
  //saveTemplate
  saveTemplate(data){
    let body = new HttpParams();
    body = body.set('html',data.html);
    body = body.set('customer',data.customer);
    body = body.set('template_id',data.template_id);
    body = body.set('action',data.action);
    httpOptions.headers.append('Content-Type', 'application/json');
    return this.http.put(BASE_API_URL+'/saveTemplate',body, httpOptions);
  }

}