import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { CustomersService } from '../../shared/services/customers/customers.service';
import { AuthService } from "../../shared/services/auth/auth.service";
import { RoutesService } from "../../shared/services/navigation/routes.service";
import { MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, Validators } from '@angular/forms';
import { ProgressService } from '@app/shared/services/navigation/progress.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  customer;
  subscriptions;
  inProgress;
  payinfo;
  prodDetails;
  licensesForm;
  updateSeatsForm;
  selectedIndex;

  @ViewChild('addLicensesDialog') addLicensesDialog: TemplateRef<any>;
  public addLicensesDialogRef: MatDialogRef<TemplateRef<any>>;


  @ViewChild('updateLicensesDialog') updateLicensesDialog: TemplateRef<any>;
  public updateLicensesDialogRef: MatDialogRef<TemplateRef<any>>;

  constructor(
    public customers: CustomersService,
    private auth:  AuthService,
    private crouter:RoutesService,  
    public dialog: MatDialog,
    public snack: MatSnackBar,
    private fb: FormBuilder,
    private progress: ProgressService,
  ) {
    this.licensesForm = this.fb.group({
      units: ['', [Validators.required, Validators.min(0), Validators.max(20)]]
    });

    this.auth.user.subscribe( user => {
      if(user && user.gid){
        this.getCustomer(user);
        this.listSubscriptions(user);
      }else{
        //sing out user so they can reauthenticate
        this.auth.SignOut();
      }
    })
   }
  
  getCustomer(user){
    this.customers.getDbCustomer(user.email).valueChanges().subscribe( data =>{
      this.customer = data[0];
      if(this.customer.id){
        this.getStripeCustomer(this.customer);
      }
    })
  }

  getStripeCustomer(customer){
    this.customers.getStripeBasic(customer).subscribe( (data:any) =>{
      if(data && !data.error){
        //console.log(data.balance)
        data.balance = data.balance ? "$" + (data.balance.amount_due/100).toFixed(2) : '$0.00';
        this.payinfo = data;
        //console.log(data)
      }else if (data && data.error){
        data.balance =  '$0.00';
      }
    })
  }

  listSubscriptions(user){
    this.inProgress = true;
    this.customers.getGSuiteSubscriptions(user.gid).subscribe((data:any) =>{
      //console.log(data)
      for (var i = 0; i < data.length; i++) {
        if (data[i].skuName.includes('Voice')){
          data[i].icon = 'voice'; 
        }else if (data[i].skuName.includes('Identity')){
          data[i].icon = 'identity'; 
        }else if (data[i].skuName.includes('Drive')){
          data[i].icon = 'drive'; 
        }else{
          data[i].icon = 'gapps'; 
        }

        if (data[i].plan.planName.includes('FLEXIBLE')){
          data[i].plan.planName = 'Flexible Plan'; 
        }else if (data[i].plan.planName.includes('ANNUAL')){
          data[i].plan.planName = 'Commitment Plan'; 
        }else if (data[i].plan.planName.includes('FREE')){
          data[i].plan.planName = 'Free Plan'; 
        }
      }
      
     this.inProgress = false;
     this.subscriptions = data;
    // console.log(this.subscriptions);
    })
  }

  productInfo(data, i){
    this.prodDetails = data;
    this.selectedIndex = i;    
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
    //console.log(data)
  }

  addLicenses(prod): void {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.restoreFocus = false;
      dialogConfig.autoFocus = false;
      dialogConfig.role = 'dialog';
      dialogConfig.width= '500px';
      dialogConfig.data = {prod};
  
      this.addLicensesDialogRef = this.dialog.open(this.addLicensesDialog,  dialogConfig);
 
  }


  closeaddLicensesDialog(): void {
    this.licensesForm.reset();
    this.addLicensesDialogRef.close();
  }

  addLicensesSave(v, prod){
    this.progress.setProgress(true);
    const data = {
      user: JSON.parse(localStorage.getItem('user')),
      domain: prod.customerDomain,
      sid: this.customer.id,
      gid: prod.customerId,
      skuName: prod.skuName,
      skuId: prod.subscriptionId,
      plan: prod.plan,
      newSeats: v.units,
      totalSeats : +prod.seats.numberOfSeats + +v.units,
      oldSeats : prod.seats.numberOfSeats,
      subscription: this.customer.subscription,
      fee: this.customer.fee,
      voiceTax: this.customer.voiceTax,
      default:  this.customer.default_source.type
    }
    this.closeaddLicensesDialog();
    this.customers.addLicenses(data).subscribe((res:any)=>{
      if(res && res.id){
        //console.log('new:'+v.units);
        this.openSnackBar('Successfully added '+v.units+ ' new licenses to '+prod.skuName, 'X Close');
        prod.seats.numberOfSeats =  data.totalSeats;
        this.progress.setProgress(false);
      }else{
        this.progress.setProgress(false);
        this.openSnackBar('An error ocurred, please contact support', 'X Close')
        
      }
    })
  }

  updateLicenses(prod): void {

    this.updateSeatsForm = this.fb.group({
      units: [prod.seats.maximumNumberOfSeats, [Validators.required, Validators.min(prod.seats.licensedNumberOfSeats)]]
    });
    
      const dialogConfig = new MatDialogConfig();
      dialogConfig.restoreFocus = false;
      dialogConfig.autoFocus = false;
      dialogConfig.role = 'dialog';
      dialogConfig.width= '500px';
      dialogConfig.data = {prod};
  
      this.updateLicensesDialogRef = this.dialog.open(this.updateLicensesDialog,  dialogConfig);
 
  }


  closeupdateLicensesDialog(): void {
    this.licensesForm.reset();
    this.updateLicensesDialogRef.close();
  }

  updateLicensesSave(v, prod){
    this.progress.setProgress(true);
    const data = {
      user: JSON.parse(localStorage.getItem('user')),
      domain: prod.customerDomain,
      sid: this.customer.id,
      gid: prod.customerId,
      skuName: prod.skuName,
      skuId: prod.subscriptionId,
      plan: prod.plan,
      newSeats: v.units,
      oldSeats : prod.seats.maximumNumberOfSeats,
      subscription: this.customer.subscription,
      fee: this.customer.fee,
      voiceTax: this.customer.voiceTax,
      default:  this.customer.default_source.type
    }
    this.closeupdateLicensesDialog();
    this.customers.addLicenses(data).subscribe((res:any)=>{
      if(res && res.id){
        //console.log('new:'+v.units);
        this.openSnackBar('Successfully updated the '+prod.skuName+ ' licenses to '+v.units, 'X Close');
        prod.seats.maximumNumberOfSeats =  v.units;
        this.progress.setProgress(false);
      }else{
        this.progress.setProgress(false);
        this.openSnackBar('An error ocurred, please contact support', 'X Close')
        
      }
    })
  }

  openSnackBar(message: string, action: string) {
    this.snack.open(message, action, {
       duration: 3000,
       horizontalPosition: 'right',
    });
 } 

  
  scrollToBottom() {
    var elmnt = document.getElementById("prodDetails");
    elmnt.scrollIntoView(false); // Bottom
  }

  ngOnInit(): void {
    this.crouter.setRoute('Main Dashboard');
  }

}
