import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RoutesService {

  private routerInfo: BehaviorSubject<string>;

  constructor() {
    this.routerInfo = new BehaviorSubject<string>('');
  }

  getRoute(): Observable<string> {
    return this.routerInfo.asObservable();
  }

  setRoute(newValue): void {
    this.routerInfo.next(newValue);
  }


}