import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(
      private router: Router,
      private auth: AuthService
  ) { }
  
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

      return this.auth.currentUser.pipe(map( user => {
       if (user) {
           // check if route is restricted by role
           if (user.role !== route.data.role) {
               // role not authorized so redirect to home page
               this.router.navigate(['/']);
               return false;
           }
           // authorised so return true
           return true;
       }
 
       // not logged in so redirect to login page with the return url
       this.router.navigate(['/sign-in']);
       return false;
     }))
  }

}
