<div class="dashboard-content">
    <mat-card>
        <mat-card-header>
          <mat-card-title>General Settings</mat-card-title>
        </mat-card-header>
        <mat-card-content fxLayout="row">
            <mat-divider></mat-divider>
            <div fxLayoutGap="50px" fxLayout="row wrap" class="settings-main" fxFlex="100%">
                <div class="settings-description" fxFlex.gt-xs="35%" fxFlex.lt-sm="100%">
                    <h3 matLine>List of emails who should receive notifications/alerts</h3>
                    <mat-list>
                        <mat-list-item *ngFor="let email of settings.emails; let last = last">
                                <span> {{email}} </span>
                            <div class="settings-user-item-actions"><mat-icon matTooltip="Remove email" class="hover-red" (click)="removeEmail(email, user.sid, settings)">remove_circle_outline</mat-icon></div>
                            <mat-divider *ngIf="!last"></mat-divider>
                        </mat-list-item>
                    </mat-list>
                    <button m mat-mini-fab color="primary" class="settings-add-email" matTooltip="Add an email address" (click)="addEmail(settings, user)"><mat-icon>add</mat-icon></button>
                </div>
                <div class="settings-description settings-actions" fxLayout="column" fxFlex.gt-xs="50%" fxFlex.lt-sm="100%">
                    <div fxLayout="row" class="settings-invoice" fxFlex="100%">
                        Receive copy of paid invoices?<mat-slide-toggle color="primary" [checked]="settings.receiveInvoice" (change)="invoiceAlerts($event.checked, user)"></mat-slide-toggle>  
                    </div>
                    <!-- Alerts feature on hold
                    <div fxLayout="row" class="settings-invoice settings-invoice-alerts" fxFlex="100%">
                        <div fxFlex.gt-xs="50%" fxFlex.lt-sm="100%">
                            Receive current Google Workspace Bill balance alert?<mat-checkbox color="primary" [checked]="settings.receiveAlerts" (change)="balanceAlerts($event.checked, user); settings.receiveAlerts = $event.checked"></mat-checkbox>
                        </div>
                        <div fxFlex.gt-xs="30%" fxFlex.lt-sm="100%">
                            <mat-form-field  appearance="outline">
                                <mat-label>Balance Alerts Threshold</mat-label>
                                <mat-select [(value)]="settings.alertsThreshold"  [disabled]="!settings.receiveAlerts" (selectionChange)="alertsDays($event.value, user)">
                                  <mat-option value="1">Every day</mat-option>
                                  <mat-option value="5">Every 5 days</mat-option>
                                  <mat-option value="10">Every 10 days</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                     -->
                </div>
                
            </div>
            
        </mat-card-content>
    </mat-card>  
    
    <!-- Add Email Dialog template -->

    <ng-template #addEmailDialog  let-data>
        <button mat-icon-button class="mat-dialog-cancel" color="primary" (click)="closeaddEmailDialog()">
            <mat-icon>close</mat-icon>
        </button>
        <h4 mat-dialog-title> Add an email address</h4>
        <div mat-dialog-content>
            <form [formGroup]="addEmailForm" fxFlex="auto" fxLayout="column">
                <mat-form-field  appearance="outline">
                    <mat-label>Email Address</mat-label>
                    <input matInput placeholder="Ex. support@hiviewcloud.com"  type="email" formControlName="email" required>
                    <mat-error *ngIf="addEmailForm.get('email').errors">Email is required</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div mat-dialog-actions align="end">
            <button mat-button color="primary" (click)="closeaddEmailDialog()">Cancel</button>
            <button mat-button color="warn" [disabled]="addEmailForm && addEmailForm.invalid" (click)="addEmailSave(addEmailForm.value, data)" cdkFocusInitial>Continue</button>
        </div>
    </ng-template>
    
    