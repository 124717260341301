<div class="setup-container" fxFlex="100%">
      <mat-horizontal-stepper linear #stepper>
        <mat-step [stepControl]="infoFormGroup">
            <ng-template matStepLabel>Let's get started</ng-template>
            <mat-card class="edit-form mat-elevation-z0">
              <div class="loadingCircle" *ngIf="loadStatus">
                  <mat-spinner class="inprogressCircle"></mat-spinner>
              </div>
              <div class="customer-form-container">
                  <div fxlayout="row">
                      <form [formGroup]="infoFormGroup" fxflex="1 1 auto" *ngIf="infoFormGroup && loadStatus == false">
                        <h2> What's your billing info? </h2>
                        <div fxlayout="column"  class="edit-form-column">
                            <table class="example-full-width" cellspacing="0"><tr>
                                <td><mat-form-field class="address-full-width" appearance="outline">
                                  <mat-label>First name</mat-label>
                                  <input matInput placeholder="Ex. Miles" formControlName="name">
                                </mat-form-field></td>
                                <td><mat-form-field class="address-full-width" appearance="outline">
                                    <mat-label>Last name</mat-label>
                                    <input matInput placeholder="Ex. Hischier" formControlName="lastname">
                                  </mat-form-field></td>
                              </tr>
                            </table>
                            <mat-form-field appearance="outline">
                                <mat-label>Alternate email (Not associated with your Google Workspace account)</mat-label>
                                <input matInput type="email" placeholder="Ex. hiview@gmail.com" formControlName="email" required>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Street address</mat-label>
                                <input matInput placeholder="Ex. 100 Main St" formControlName="street" required>
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>Street address Line 2</mat-label>
                                <input matInput placeholder="Ex. 100 Main St" formControlName="street2">
                            </mat-form-field>
                            <mat-form-field appearance="outline">
                                <mat-label>City</mat-label>
                                <input matInput placeholder="Ex. San Francisco" formControlName="city" required>
                            </mat-form-field>
                            <table class="example-full-width" cellspacing="0"><tr>
                        
                                <td><mat-form-field  class="address-full-width" appearance="outline">
                                        <mat-label>Country</mat-label>
                                        <mat-select formControlName="country">
                                            <mat-option [value]="country.code" *ngFor="let country of countries">{{country.name}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                                <td><mat-form-field class="address-full-width" appearance="outline">
                                  <mat-label>State / Province / Region</mat-label>
                                  <input matInput placeholder="Ex. California" formControlName="state" >
                                </mat-form-field></td>
                                <td><mat-form-field class="address-full-width" appearance="outline">
                                  <mat-label>Postal / Zip Code</mat-label>
                                  <input matInput #postalCode maxlength="5" placeholder="Ex. 94105" value="" formControlName="postal_code" >
                                  <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                                </mat-form-field></td>
                              </tr>
                            </table>
                            <mat-form-field appearance="outline">
                                <mat-label>Phone number</mat-label>
                                <input matInput placeholder="" formControlName="phone">
                            </mat-form-field>
                        </div>
                        <div class="actions-container">
                            <button mat-flat-button color="accent" [disabled]="infoFormGroup.invalid"  (click)="updateCustomer(infoFormGroup.value)">Next</button>
                        </div>
                      </form>
                  </div>
              </div>
            </mat-card>
        </mat-step>
        <mat-step [completed]="paymentFormGroup">
          <!-- <mat-step > -->
            <ng-template matStepLabel>Payment Method</ng-template>
            <mat-card class="edit-form mat-elevation-z0">
                <div class="loadingCircle" *ngIf="loadStatus">
                    <mat-spinner class="inprogressCircle"></mat-spinner>
                </div>
                <mat-accordion [multi]="false" class="mat-elevation-z0">
                    <mat-expansion-panel class="mat-elevation-z0"  [expanded]="expandedpm_bank" *ngIf="expandedpm_card == false" (closed)="expandedpm_bank = false" (opened)="expandedpm_bank = true">
                      <mat-expansion-panel-header >
                        <mat-panel-title >
                            <div class="expanding-form-selector-container">
                                <div class="ratio-actions">
                                    <mat-icon *ngIf="expandedpm_bank == true">account_balance</mat-icon>
                                    <mat-radio-button *ngIf="expandedpm_bank == false"></mat-radio-button>
                                </div>
                            </div>
                            Add a bank account
                        </mat-panel-title>
                      </mat-expansion-panel-header>
                      <form [formGroup]="bankFormGroup" fxflex="1 1 auto" *ngIf="bankFormGroup && loadStatus == false">
                        <div class="pm-card-expanded-message">
                          <span>Bank account must be located in United States and in US Dollar currency</span>
                        </div>
                        <div fxlayout="column" class="edit-form-column">
                              <div fxflex="100%">
                                  <mat-form-field appearance="standard" class="expanding-form-input">
                                    <mat-label>Name on bank account</mat-label>
                                    <input matInput placeholder="Ex. HiView Solutions" formControlName="account_holder_name" required>
                                    <mat-icon matTooltip="Enter your full name as shown on your bank statements." matSuffix>info_outline</mat-icon>
                                    <mat-error *ngIf="bankFormGroup.get('account_holder_name').errors && bankFormGroup.get('account_holder_name').errors.required">Name on bank account is required</mat-error>
                                  </mat-form-field>
                              </div>
                              <div>
                                  <mat-form-field  appearance="standard" class="expanding-form-input">
                                      <mat-label>Account holder type</mat-label>
                                      <mat-select [(value)]="selected" formControlName="account_holder_type" required>
                                        <mat-option value="individual">Individual</mat-option>
                                        <mat-option value="company">Company</mat-option>
                                      </mat-select>
                                      <mat-error *ngIf="bankFormGroup.get('account_holder_type').errors && bankFormGroup.get('account_holder_type').errors.required">Account holder type is required</mat-error>
                                  </mat-form-field>
                              </div>
                              <div>
                                  <mat-form-field  appearance="standard" class="expanding-form-input">
                                      <mat-label>Routing number</mat-label>
                                      <input matInput placeholder="Ex. Individual" formControlName="routing_number" required>
                                      <mat-error *ngIf="bankFormGroup.get('routing_number').errors && bankFormGroup.get('routing_number').errors.required">Routing number is required</mat-error>
                                  </mat-form-field>
                              </div>
                              <div>
                                  <mat-form-field  appearance="standard" class="expanding-form-input">
                                      <mat-label>Account number</mat-label>
                                      <input matInput placeholder="Ex. Individual" formControlName="account_number" required>
                                      <mat-error *ngIf="bankFormGroup.get('account_number').errors && bankFormGroup.get('account_number').errors.required">Account number is required</mat-error>
                                  </mat-form-field>
                              </div>
                              <div class="expanding-form-infoimg">
                                  <img src="../../../assets/billing/bankrouting.png">
                              </div>
                              <div class="expanding-form-infotext">
                                  <span>By continuing, you authorize HiView Solutions to automatically credit and debit your bank account electronically, 
                                        including debits to fund any negative balance that may arise.
                                  </span>
                              </div>
                              <div class="setup-bank-verification">
                                <mat-chip selected color="warn">
                                  Your bank account must first be verified before you can use it as a payment method. 2 micro-deposits will be sent to your bank account. These deposits take 1-2 business days to appear on your online statement.
                                </mat-chip>
                              </div>
                          </div>
                          <div class="actions-container">
                              <button mat-flat-button color="accent" [disabled]="bankFormGroup.invalid"  (click)="addBank(bankFormGroup.value)">Next</button>
                          </div>
                    </form>
                    </mat-expansion-panel>
                    <mat-expansion-panel [disabled]="customerInfo && customerInfo.allow_cc == false" class="mat-elevation-z0" [expanded]="expandedpm_card"  *ngIf="expandedpm_bank == false" (closed)="expandedpm_card = false" (opened)="expandedpm_card = true">
                      <mat-expansion-panel-header>
                          <mat-panel-title>
                            <div class="expanding-form-selector-container">
                                <div class="ratio-actions">
                                    <mat-icon *ngIf="expandedpm_card == true">credit_card</mat-icon>
                                    <mat-radio-button [disabled]="customerInfo && customerInfo.allow_cc == false" *ngIf="expandedpm_card == false"></mat-radio-button>
                                </div>
                            </div>
                            Add credit or debit card
                          </mat-panel-title>
                      </mat-expansion-panel-header>
                      <form [formGroup]="cardFormGroup" fxflex="1 1 auto" *ngIf="cardFormGroup && loadStatus == false">
                        <div fxlayout="column" class="edit-form-column">
                            <div >
                                <mat-form-field  appearance="standard" class="expanding-form-input">
                                    <mat-label>Card number</mat-label>
                                    <input matInput maxlength="20"
                                    type="tel"
                                    #ccNumber
                                    (keyup)="creditCardNumberSpacing()" formControlName="number" required>
                                    <img class="pm-card-sufix-img" *ngIf="cardType" src="../../../assets/billing/cards/{{cardType}}.png" matSuffix>
                                    <mat-error *ngIf="cardFormGroup.get('number').errors && cardFormGroup.get('number').errors.required">Card number is required</mat-error>
                                    <mat-error *ngIf="cardFormGroup.get('number').errors && cardFormGroup.get('number').errors.validateCC">Invalid credit card</mat-error>
                                </mat-form-field>
                            </div>
                            <div *ngIf="cardType && !cardFormGroup.get('number').errors">
                                <div fxLayout="row wrap">
                                    <mat-form-field  appearance="standard" class="expanding-form-inputSmall">
                                        <mat-label>MM</mat-label>
                                        <input matInput  maxlength="2" formControlName="exp_month" required>
                                        <mat-error *ngIf="cardFormGroup.get('exp_month').errors && cardFormGroup.get('exp_month').errors.required">Month is required</mat-error>
                                    </mat-form-field>
            
                                    <mat-form-field appearance="standard" class="expanding-form-inputSmall">
                                        <mat-label>YY</mat-label>
                                        <input matInput  maxlength="2" formControlName="exp_year" required>
                                        <mat-error *ngIf="cardFormGroup.get('exp_year').errors && cardFormGroup.get('exp_year').errors.required">Year is required</mat-error>
                                        <mat-error *ngIf="cardFormGroup.get('exp_year').errors && cardFormGroup.get('exp_year').errors.pattern  && !cardFormGroup.get('exp_year').errors.minlength">Invalid year</mat-error>
                                    </mat-form-field>
                                    
                                    <mat-form-field appearance="standard" class="expanding-form-input-cvc">
                                        <mat-label>CVC</mat-label>
                                        <input matInput maxlength="4" formControlName="cvc" required>
                                        <img *ngIf="cardType !=='amex'" matTooltip="CVC is the last three digits of the number that appears on the back of your card in the signature bar." 
                                        src="../../../assets/billing/cards/cvc.png" matSuffix>
                                        <img *ngIf="cardType =='amex'"  matTooltip="CVC is the four digits located on the front of the card, on the right side." 
                                        src="../../../assets/billing/cards/cvc.png" matSuffix>
                                        <mat-error *ngIf="cardFormGroup.get('cvc').errors && cardFormGroup.get('cvc').errors.required">CVC is required</mat-error>
                                    </mat-form-field>
                                </div>
                                <div fxLayout="column wrap" class="pm-card-error-list">
                                    <mat-error *ngIf="cardFormGroup.get('exp_month').errors && cardFormGroup.get('exp_month').errors.minlength">Month must be a number between 01 and 12</mat-error>
                                    <mat-error *ngIf="cardFormGroup.get('exp_month').errors && cardFormGroup.get('exp_month').errors.pattern  && !cardFormGroup.get('exp_month').errors.minlength">Month must be a number between 01 and 12</mat-error>
                                    <mat-error *ngIf="cardFormGroup.get('exp_year').errors && cardFormGroup.get('exp_year').errors.minlength">Year must contain 2 numbers</mat-error>
                                    <mat-error *ngIf="cardFormGroup.get('cvc').errors && cardFormGroup.get('cvc').errors.pattern">CVC must contain numbers only</mat-error>
                                </div>
                                  <div fxLayout="row wrap">
                                      <mat-form-field appearance="standard" class="expanding-form-input">
                                        <mat-label>Cardholder name</mat-label>
                                        <input matInput placeholder="Ex. HiView Solutions" formControlName="name" required>
                                        <mat-icon matTooltip="Enter your full name as shown on your Credit card statements." matSuffix>info_outline</mat-icon>
                                        <mat-error *ngIf="cardFormGroup.get('name').errors && cardFormGroup.get('name').errors.required">Card holder name is required</mat-error>
                                      </mat-form-field>
                                  </div>
                                  <div fxLayout="row wrap">
                                      <mat-form-field  appearance="standard" class="expanding-form-input">
                                          <mat-label>Country</mat-label>
                                          <mat-select [(value)]="selected" formControlName="address_country" required>
                                            <mat-option *ngFor="let country of countries" [value]="country.code">
                                              {{country.name}}
                                            </mat-option>
                                          </mat-select>
                                          <mat-error *ngIf="cardFormGroup.get('address_country').errors && cardFormGroup.get('address_country').errors.required">Country is required</mat-error>
                                      </mat-form-field>
                                  </div>
                                  <div fxLayout="row wrap">
                                      <mat-form-field  appearance="standard" class="expanding-form-input">
                                          <mat-label>ZIP / Postal code</mat-label>
                                          <input  maxlength="6" matInput formControlName="address_zip" required>
                                      </mat-form-field>
                                  </div>
                                  <div fxLayout="row wrap" class="expanding-form-infotext">
                                      <span>By continuing, you authorize HiView Solutions to automatically credit and debit your Credit Card electronically, 
                                            including debits to fund any negative balance that may arise.
                                      </span>
                                  </div>
        
                            </div>
                          </div>
                     </form>
                     <div class="actions-container">
                         <button mat-flat-button color="accent" [disabled]="cardFormGroup && cardFormGroup.invalid" (click)="addCard(cardFormGroup.value)">Next</button>
                     </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </mat-card>
        </mat-step>
        <mat-step [stepControl]="subscriptionFormGroup">
            <ng-template matStepLabel>Subscriptions</ng-template>
            <div fxLayout='row'>
                <mat-card class="mat-elevation-z0 setup-subscriptions-container" fxLayout='column' fxFlex="100%">
                    <mat-card-title fxLayout='row' fxFlex="100%" class="setup-subscriptions-title">
                        <h2>Review and confirm your subscriptions </h2>
                    </mat-card-title>
                    <mat-card-content>
                        <div fxLayout="row wrap" fxLayoutAlign="center start">
                            <div class="loadingCircle" *ngIf="loadStatus">
                                <mat-spinner class="inprogressCircle"></mat-spinner>
                            </div>
                            <div fxflex="1 1 auto" fxLayout="row wrap" *ngIf="loadStatus == false">
                                <mat-card fxFlex.gt-xs="160px" fxFlex.lt-sm="100%"  class="mat-elevation-z0 setup-subscriptions-card"  *ngFor="let product of subscriptions">
                                    <div class="setup-subscriptions-logo-container" fxflex="0 0 100%">
                                        <img src="../../../assets/billing/{{product.icon}}.png">
                                    </div>
                                    <div class="details" fxflex="0 1 100%" >
                                        <h2 class="setup-subscriptions-name" id="">{{product.skuName}}</h2>
                                    </div>
                                    <div class="setup-subscriptions-card-content" fxflex="1 1 auto" fxlayout="row" fxlayoutalign="space-between center" *ngIf="product.plan.planName">
                                        {{product.plan.planName}}
                                    </div>
                                    <div class="setup-subscriptions-card-content" fxflex="1 1 auto" fxlayout="row" fxlayoutalign="space-between center" *ngIf="product.seats && product.seats.numberOfSeats">
                                       {{product.seats.licensedNumberOfSeats}} / {{product.seats.numberOfSeats}} Licenses
                                    </div>
                                    <div class="setup-subscriptions-card-content" fxflex="1 1 auto" fxlayout="row" fxlayoutalign="space-between center" *ngIf="product.seats && product.seats.maximumNumberOfSeats && !product.seats.numberOfSeats">
                                       {{product.seats.licensedNumberOfSeats}} / {{product.seats.maximumNumberOfSeats}} Licenses
                                    </div>
                                </mat-card>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
            <div fxLayout='row' fxFlex="100%">
                <form [formGroup]="subscriptionFormGroup" fxLayout='row' fxFlex="100%" *ngIf="subscriptionFormGroup && loadStatus == false">
                    <div fxLayout='row' fxFlex="auto"  fxLayoutAlign="center" >
                        <mat-checkbox class="confirmation-box" formControlName="terms" color="primary" required>I confirm that the information given in this form is true, complete and accurate.</mat-checkbox>
                        <button mat-flat-button color="accent" class="confirmation-button" [disabled]="subscriptionFormGroup && subscriptionFormGroup.invalid" (click)="updateSubscriptions(subscriptionFormGroup.value, subscriptions)">Next</button>
                    </div>
                </form>
            </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <div class="loadingCircle" *ngIf="loadStatus">
              <mat-spinner class="inprogressCircle"></mat-spinner>
          </div>
          <div fxFlex="100%" class="setup-completed" fxLayoutAlign="center" *ngIf="loadStatus == false">
              Thank you! You will be redirected to the main dashboard in 3 seconds...
          </div>
        </mat-step>
      </mat-horizontal-stepper> 
</div>