import { Component, OnInit, ViewChild } from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import { AuthService } from "../../shared/services/auth/auth.service";
import { CustomersService } from '../../shared/services/customers/customers.service';
import { RoutesService } from "../../shared/services/navigation/routes.service";
import { MatTableDataSource } from '@angular/material/table';


export interface Transaction {
  date: string;
  description: string;
  total: number;
}
export interface Invoices {
  date: string;
  amount: string;
}

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.css']
})
export class BillingComponent implements OnInit {

  invoices: MatTableDataSource<Invoices>;;
  detailedInvoice;
  lastCharge;
  balance;
  invoiceColumns = ['date', 'amount'];
  displayedColumns = ['date', 'description', 'total'];
  transactions: Transaction[];

  @ViewChild(MatPaginator) paginator: MatPaginator;
 
  constructor(
    private auth: AuthService,
    private customers: CustomersService,
    private crouter:RoutesService
  ) {
    this.auth.user.subscribe((user:any)=>{
      this.listInvoices(user);
    })
   }

  listInvoices(user){
    this.customers.getInvoices(user.sid).subscribe((data:any)=>{
      console.log('list',data)
      let invoiceObj = data.invoice;
      let charges    = data.charges;
      for(let i of invoiceObj){
        i.amount_due   =  "$" + (i.amount_due/100).toFixed(2);
        i.period_start = this.toHumanDate(i.period_start);
        i.period_end   =  this.toHumanDate(i.period_end);
        let items = i.lines.data;
        //lets move the cc fee to the bottom
        for(var x in items)items[x].metadata.type == "ccfee" ? items.push( items.splice(x,1)[0] ) : 0;
      }

      this.invoices = new MatTableDataSource(invoiceObj);
      this.invoices.paginator = this.paginator;
      this.balance  = invoiceObj[0];
      
      for(let c of charges){
        c.amount  =  "$" + (c.amount/100).toFixed(2);
        c.created = this.toHumanDate(c.created);
      }
      this.lastCharge  = charges[0];
    })

    //get current usage info
    let date = new Date();
    const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    const today = new Date();
    const bal = {
      start_date: firstDay,
      end_date: today,
      amount: ''
    }
    this.customers.getStripeCustomer(user.sid, 'id').subscribe( (data:any) =>{
      bal.amount= "$" + (data.balance/100).toFixed(2);
      this.balance = bal;
    })
   

  }

  invoiceDetails(invoice){
    const result = invoice.lines.data.map(i => ({
      date_start: i.period.start, 
      date_end: i.period.end, 
      description: i.description ? i.description : i.plan.name+ ': '+i.quantity+' licenses', 
      total: (i.amount/100).toFixed(2)
    }));

    this.detailedInvoice = invoice;
    this.transactions = result;
    setTimeout(() => {
      this.scrollToBottom();
    }, 100);
    
    //console.log(invoice)
  }

 toHumanDate(epoch){
    return (epoch * 1000);
  }

  
  scrollToBottom() {
    var elmnt = document.getElementById("billingDetails");
    elmnt.scrollIntoView(false); // Bottom
  }

  ngOnInit(): void {
    this.crouter.setRoute('Billing > Details');
  }


}
