import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Location, DOCUMENT } from '@angular/common';
import { CustomersService } from '../../shared/services/customers/customers.service';
import { AuthService } from '../../shared/services/auth/auth.service';
import { SnackService } from '../../shared/services/snackbar/snack.service';
import { ProgressService } from "../../shared/services/navigation/progress.service";
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-addpaymethod',
  templateUrl: './addpaymethod.component.html',
  styleUrls: ['./addpaymethod.component.css']
})

export class AddpaymethodComponent implements OnInit {
  @ViewChild('ccNumber') ccNumberField: ElementRef;

  expandedpm_bank = false;
  expandedpm_card = false;
  bankloadStatus;
  cardloadStatus;
  animations;
  bankForm;
  cardForm;
  cardType;
  countries;
  selectedCountry;

  constructor(
    private fb: FormBuilder, 
    public customers: CustomersService, 
    private auth: AuthService,
    private location: Location,
    private snack:  SnackService,
    private progress: ProgressService
    ) {
      this.auth.user.subscribe( user => {
        if(user.email){
        this.initiateFormBank(user.email);
        this.initiateFormCard(user.email);
        }
      })
    }

  initiateFormBank(customer){
    this.bankForm = this.fb.group({
      object: ['bank_account'],
      country: ['US'],
      currency : ['usd'],
      account_holder_name: ['', Validators.required],
      account_holder_type: ['', Validators.required],
      routing_number: ['', Validators.required],
      account_number: ['', Validators.required]
      })
      this.bankloadStatus = false;
   }

   initiateFormCard(customer){
    this.cardForm = this.fb.group({
      object: ['card'],
      address_country: ['US', Validators.required],
      currency : ['usd'],
      name: ['', Validators.required],
      number:  ['', [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(17), validateCC]],
      exp_month: ['', [Validators.required, Validators.pattern('^(0?[1-9]|1[012])$'), Validators.minLength(2)]],
      exp_year: ['', [Validators.required, Validators.pattern('^2[0-9]$'), Validators.minLength(2)]],
      cvc: ['', [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(3)]],
      address_zip: ['', [Validators.required, Validators.minLength(4)]]
    })
      this.countries = countryList;
      this.cardloadStatus = false;
   }  

   addCard(data){
    this.progress.setProgress(true);
    this.auth.user.pipe(first()).subscribe(user => {
      let source = {'source': data, 'id': user.sid};
      this.customers.addSource(source).subscribe((card:any) => {
        if(!card.error){
          this.customers.addPaymentMethodDB(user.sid, card).then(data => {
            this.snack.openSnackBar('The payment method has been saved', "X Close");
            this.progress.setProgress(false);
            this.cardForm.reset();
            this.location.back();
          }).catch(error => {
           this.snack.openSnackBar('error', "X Close");
          })
        }else if(card.error){
          this.snack.openSnackBar(card.error, "X Close");
          this.progress.setProgress(false);
        }else{
         this.snack.openSnackBar('An error ocurred, please try again later or contact us at support@hiviewsolutions.com', "X Close");
         this.progress.setProgress(false);
        }
       })
     })
   }  

   addBank(data){
    this.progress.setProgress(true);
     this.auth.user.pipe(first()).subscribe(user => {
       let source = {'source': data, 'id': user.sid};
       this.customers.addSource(source).subscribe((bank:any) => {
         if(!bank.error){
           this.customers.addPaymentMethodDB(user.sid, bank).then(data => {
             this.snack.openSnackBar('The bank account has been saved', "X Close");
             this.progress.setProgress(false);
             this.bankForm.reset();
             this.location.back();
           }).catch(error => {
            this.snack.openSnackBar('error', "X Close");
           })
         }else{
           this.snack.openSnackBar('An error ocurred, please try again later or contact us at support@hiviewsolutions.com', "X Close");
           this.progress.setProgress(false);
         }
        })
      })
    }

    /* Insert spaces to make the user's credit card number more legible */
    creditCardNumberSpacing() {
      const input = this.ccNumberField.nativeElement;
      const { selectionStart } = input;
      const { number } = this.cardForm.controls;
      let trimmedCardNum = number.value.replace(/\s+/g, '');
  
      if (trimmedCardNum.length > 16) {
        trimmedCardNum = trimmedCardNum.substr(0, 16);
      }
  
       /* Handle American Express 4-6-5 spacing */
      const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37') 
                         ? [4,6,5] 
                         : [4,4,4,4];
  
      const numbers = [];
      let position = 0;
      partitions.forEach(partition => {
        const part = trimmedCardNum.substr(position, partition);
        if (part) numbers.push(part);
        position += partition;
      })
  
      number.setValue(numbers.join(' '));
  
      /* Handle caret position if user edits the number later */
      if (selectionStart < number.value.length - 1) {
        input.setSelectionRange(selectionStart, selectionStart, 'none');
      }

      this.cardType = getCardType(number.value);

    }

  

   // sends the user back to the page they came from.
   goBack() {
     this.location.back();
    }
  

  ngOnInit(): void {
  }

}

function validateCC(c: FormControl) {
  //console.log(c.value)
  if(c.value){
    let type = getCardType(c.value)
    return type ? null : {
      validateCC: {
        valid: false
      }
    }
  }
}

function getCardType(number) {
  number = number.replace(/\s/g, '');

  var re = {
   'UnionPay': /^(62|88)\d+$/,
   'Visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
   'MasterCard': /^5[1-5][0-9]{14}$/,
   'American Express': /^3[47][0-9]{13}$/,
   'Diners Club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
   'Discover': /^6(?:011|5[0-9]{2})[0-9]{12}$/,
   'JCB': /^(?:2131|1800|35\d{3})\d{11}$/
}

for(var key in re) {
    if(re[key].test(number)) {
        return key
    }
}
}

const countryList = [
  {name: 'United States',code: 'US'},
  {name: 'Australia',code: 'AU'},
  {name: 'Austria',code: 'AT'},
  {name: 'Belgium',code: 'BE'},
  {name: 'Brazil',code: 'BR'},
  {name: 'Bulgaria',code: 'BG'},
  {name: 'Canada',code: 'CA'},
  {name: 'Cyprus',code: 'CY'},
  {name: 'Czech Republic',code: 'CZ'},
  {name: 'Denmark',code: 'DK'},
  {name: 'Estonia',code: 'EE'},
  {name: 'Finland',code: 'FI'},
  {name: 'France',code: 'FR'},
  {name: 'Germany',code: 'DE'},
  {name: 'Greece',code: 'GR'},
  {name: 'Hong Kong',code: 'HK'},
  {name: 'India',code: 'IN'},
  {name: 'Ireland',code: 'IE'},
  {name: 'Italy',code: 'IT'},
  {name: 'Japan',code: 'JP'},
  {name: 'Latvia',code: 'LV'},
  {name: 'Lithuania',code: 'LT'},
  {name: 'Luxembourg',code: 'LU'},
  {name: 'Malaysia',code: 'MY'},
  {name: 'Malta',code: 'MT'},
  {name: 'Mexico',code: 'MX'},
  {name: 'Netherlands',code: 'NL'},
  {name: 'New Zealand',code: 'NZ'},
  {name: 'Norway',code: 'NO'},
  {name: 'Poland',code: 'PL'},
  {name: 'Portugal',code: 'PT'},
  {name: 'Romania',code: 'RO'},
  {name: 'Singapore',code: 'SG'},
  {name: 'Slovakia',code: 'SK'},
  {name: 'Slovenia',code: 'SI'},
  {name: 'Spain',code: 'ES'},
  {name: 'Sweden',code: 'SE'},
  {name: 'Switzerland',code: 'CH'},
  {name: 'United Kingdom',code: 'GB'},
  {name: 'Kenya',code: 'KY'}
];
