import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { ProgressService } from '@app/shared/services/navigation/progress.service';
import { RoutesService } from '@app/shared/services/navigation/routes.service';
import { Settings } from '@shared/interfaces/settings';
import  firebase  from 'firebase/app';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  invoicesForm;
  alertsForm;
  isEditable: boolean = false;
  AisEditable: boolean = false;
  user;
  settings: Settings =  {emails: [], receiveAlerts: false, alertsThreshold: 0, receiveInvoice: false, oneEmployee: false};
  addEmailForm;
  emails;

  @ViewChild('addEmailDialog') addEmailDialog: TemplateRef<any>;
  public addEmailDialogRef: MatDialogRef<TemplateRef<any>>;

  constructor(
    private crouter:RoutesService,
    public afs: AngularFirestore,   // Inject Firestore service    
    public dialog: MatDialog,
    public snack: MatSnackBar,
    private fb: FormBuilder,
    private progress: ProgressService,
    private breakpointObserver: BreakpointObserver,
    private auth: AuthService,
    @Inject(MAT_DIALOG_DATA) public data: any 
    
    ) {
      //get general settings
      this.auth.currentUser.pipe(map( user => {
        if (user) {
          this.user = user;
          this.afs.collection('customers').doc(user.sid).collection('settings').doc('notifications').valueChanges().subscribe((res:any) => {
            if(res){
              this.settings = res;
            }
          })
        }
      })).subscribe();
    }  

    invoicesFormInit(data){
       this.invoicesForm = this.fb.group({
         siteName: [{value: data? data.siteName: '', disabled: true}, [Validators.required]],
         email: [{value: data? data.email : '', disabled: true}, [Validators.required]],
         leftBar: [{value: data? data.leftBar: true, disabled: true}, [Validators.required]]
       });
    }    

    invoiceAlerts(v, u){
      this.progress.setProgress(true);
      this.afs.collection('customers').doc(u.sid).collection('settings').doc('notifications')
      .set({receiveInvoice: v}, {merge:  true})
      .then(() => {
        this.openSnackBar("The setting has been updated", "X Close");
        this.progress.setProgress(false);
     }).catch(function(error) {
       console.log(error)
       this.openSnackBar(error, "X Close");
       this.progress.setProgress(false); 
     })

    }

    balanceAlerts(v, u){
      this.progress.setProgress(true);
      this.afs.collection('customers').doc(u.sid).collection('settings').doc('notifications')
      .set({receiveAlerts: v}, {merge:  true})
      .then(() => {
        this.openSnackBar("The setting has been updated", "X Close");
        this.progress.setProgress(false);
     }).catch(function(error) {
       console.log(error)
       this.openSnackBar(error, "X Close");
       this.progress.setProgress(false); 
     })
    }

    alertsDays(v, u){
      this.progress.setProgress(true);
      this.afs.collection('customers').doc(u.sid).collection('settings').doc('notifications')
      .set({alertsThreshold: v}, {merge:  true})
      .then(() => {
        this.openSnackBar("The setting has been updated", "X Close");
        this.progress.setProgress(false);
     }).catch(function(error) {
       console.log(error)
       this.openSnackBar(error, "X Close");
       this.progress.setProgress(false); 
     })
    }
    

    addEmail(s, u): void {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.restoreFocus = false;
      dialogConfig.autoFocus = false;
      dialogConfig.role = 'dialog';
      dialogConfig.width = '450px';
      dialogConfig.data = {settings: s, user: u};
      this.addEmailDialogRef = this.dialog.open(this.addEmailDialog,  dialogConfig);
    }
   
    addEmailSave(v, data){
      this.progress.setProgress(true);
      this.afs.collection('customers').doc(data.user.sid).collection('settings').doc('notifications').set( {
       emails: firebase.firestore.FieldValue.arrayUnion(v.email)
     }, {merge:true})
      .then(() => {
        this.openSnackBar(v.email+ " has been added", "X Close");
        this.closeaddEmailDialog();
        this.progress.setProgress(false);
     }).catch(function(error) {
       console.log(error)
       this.closeaddEmailDialog();
       this.openSnackBar(error, "X Close");
       this.progress.setProgress(false);
     
     })
    }
   
   closeaddEmailDialog(): void {
    this.addEmailDialogRef.close();
    this.addEmailForm.reset();
   }

    removeEmail(e, c, s){
      this.progress.setProgress(true);
      this.afs.collection('customers').doc(c).collection('settings').doc('notifications').update( {
         emails: firebase.firestore.FieldValue.arrayRemove(e)
       }).then(() => {
         for(var i = s.emails.length - 1; i >= 0; i--) {
           if(s.emails[i] === e) {
             
           }
          }
          this.openSnackBar(e+ ' has been removed', 'X Close');
          this.progress.setProgress(false);
        }).catch(function(error) {
          this.openSnackBar(error, "X Close");
          this.progress.setProgress(false);
        })
    }

    openSnackBar(message: string, action: string) {
      this.snack.open(message, action, {
        duration: 3000,
        horizontalPosition: 'right',
      });
    }

  ngOnInit(): void {
    this.crouter.setRoute('Settings');
    this.addEmailForm = this.fb.group({
      email: ['', [Validators.required]],
    });
  }

}
