<div class="navigation-container" [class.navigation-is-mobile]="mobileQuery.matches">
    <mat-sidenav-container class="navigation-sidenav-container"
                           [style.marginTop.px]="mobileQuery.matches ? 56 : 0">
      <mat-sidenav *ngIf="auth.userData" #drawer [mode]="mobileQuery.matches ? 'over' : 'side'"
                   [fixedInViewport]="mobileQuery.matches" fixedTopGap="56" [opened]="mobileQuery.matches ? 'false' : 'false'">
        <mat-nav-list>
            <div class="nav-company">
              <img class="nav-company-logo"  src="../assets/main_logo.png" alt="HiView Solutions">
            </div>
            
          <a mat-list-item class="mat-list-base-menu" *ngFor="let menu of menus" routerLink="{{menu.link}}">
            <mat-icon class="mat-list-base-menu-icon">{{menu?.icon}}</mat-icon>
            <span>{{ menu?.name }} </span>
          </a>
          <mat-divider></mat-divider>
          <a mat-list-item class="mat-list-main-menu-logout mat-list-base-menu"  matTooltip="Close session"  (click)="auth.SignOut()">
            <mat-icon class="mat-list-base-menu-icon" matTooltip="Log Out">settings_power</mat-icon>
           <span>Log Out</span>
          </a>
        </mat-nav-list>
      </mat-sidenav>

      <mat-sidenav-content [style.marginTop.px]="mobileQuery.matches ? 10 : 0">
        <mat-toolbar *ngIf="auth.isLoggedIn" color="primary" class="navigation-toolbar">
          <mat-toolbar-row>
           <button mat-icon-button (click)="drawer.toggle()" *ngIf="mobileQuery.matches? true : settings && settings.leftBar == true"><mat-icon>menu</mat-icon></button>
           <a matTooltip="Main Dashboard" class="navbar-home-link"  routerLink="./dashboard">
             <h1 class="nav-app-name">{{settings && settings.siteName? settings.siteName : 'HiView Billing'}}</h1>
            </a>
            <span class="toolbar-spacer"></span>
            <span class="toolbar-contactus" routerLink="./support" matTooltip="Contact Support">
              <svg width="24" height="24" class="n5kzDf ShoKeb" viewBox="0 0 24 24">
                <g fill="#FFFFFF">
                  <path d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 17h-2v-2h2v2zm2.07-7.75l-.9.92C13.45 12.9 13 13.5 13 15h-2v-.5c0-1.1.45-2.1 1.17-2.83l1.24-1.26c.37-.36.59-.86.59-1.41 0-1.1-.9-2-2-2s-2 .9-2 2H8c0-2.21 1.79-4 4-4s4 1.79 4 4c0 .88-.36 1.68-.93 2.25z"></path>
                </g>
              </svg>
            </span>
           <div *ngIf="auth.userData as user">
             <img class="nav-avatar" src="{{(user.photoURL) ? user.photoURL : '/assets/dummy-user.png'}}" alt="{{user.displayName}}">
           </div>
          </mat-toolbar-row>
          <mat-toolbar-row class="nav-toolbar-routing">
            <div [innerHTML]="currentRoute" class="info"></div>
          </mat-toolbar-row>
        </mat-toolbar>
        <mat-progress-bar mode="query" class="global-progress-bar" *ngIf="inProgress == true"></mat-progress-bar>
        <!-- Add Content Here -->
        <ng-content></ng-content>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>