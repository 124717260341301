<div class="dashboard-content" fxFlex="100%">
    <mat-card fxLayout="column">
        <div fxFlex="auto" fxLayout="row wrap" fxLayoutAlign="center" fxLayoutGap="20px">
            <mat-card class="mat-elevation-z0" fxFlex="auto" fxFlex.gt-xs="38%" fxFlex.lt-sm="100%">
                <mat-card-title class="dashboard-contact-title">
                    <h2> Transactions </h2>
                </mat-card-title>
                <mat-divider></mat-divider>
                <div>
                    <div class="loadingCircle" *ngIf="!invoices">
                        <mat-spinner class="inprogressCircle"></mat-spinner>
                    </div>
                    <mat-table [dataSource]="invoices" class="transactions-list">

                        <!-- Date Column -->
                        <ng-container matColumnDef="date">
                          <mat-header-cell *matHeaderCellDef> </mat-header-cell>
                          <mat-cell *matCellDef="let invoice">
                            <div class="transactions-list-item" (click)="invoiceDetails(invoice)">
                                {{invoice.period_start | date:'LLL dd'}} – {{invoice.period_end | date:'LLL dd, yyyy'}}
                            </div>
                          </mat-cell>
                        </ng-container>
                    
                        <!-- Amount Column -->
                        <ng-container matColumnDef="amount">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let invoice; let first=first">
                              <div class="transactions-list-amount">
                                <span>{{invoice.amount_due}}</span>
                                <div class="actions">
                                    <a href="{{invoice.invoice_pdf}}" *ngIf="!first">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48" fill="#757575">
                                            <g>
                                                <title>Download</title>
                                                <path d="M38 18h-8V6H18v12h-8l14 14 14-14zM10 36v4h28v-4H10z"></path>
                                                <path d="M0 0h48v48H0z" fill="none"></path>
                                            </g>
                                        </svg>
                                    </a>
                                </div>
                              </div>
                            </mat-cell>
                        </ng-container>
                        <mat-header-row *matHeaderRowDef="invoiceColumns"></mat-header-row>
                        <mat-row *matRowDef="let invoice; columns: invoiceColumns;">
                        </mat-row>
                    </mat-table>
                    <mat-paginator [hidePageSize]="true" [pageSize]="5"></mat-paginator>
                </div>
                <mat-card-content>
                    
                </mat-card-content>
            </mat-card>
            <mat-card class="mat-elevation-z0" fxFlex="auto" fxFlex.gt-xs="58%" fxFlex.lt-sm="100%">
                <mat-card-title class="dashboard-contact-title">
                    <h2>Payment details</h2>
                </mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <div class="loadingCircle" *ngIf="!balance">
                        <mat-spinner class="inprogressCircle"></mat-spinner>
                    </div>
                    <div fxFlex="auto" class="balance-box" *ngIf="balance">
                        <div fxLayout="row wrap">
                            <div class="balance-account-current" fxFlex="100%">
                                <span>Your current balance </span>
                                <span class="amount">{{balance.amount_due}}</span>
                            </div>
                        </div>
                        <div fxLayout="row wrap" class="balance-account-detail" *ngIf="lastCharge">
                            <div class="image-caption">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24">
                                    <path d="M17 12h-5v5h5v-5zM16 1v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-1V1h-2zm3 18H5V8h14v11z" fill="rgba(0,0,0,0.54)"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </div>
                            <div class="image-message">
                                <span>Your last payment was on {{lastCharge.created | date:'LLL dd'}} for {{lastCharge.amount}}.</span>
                            </div>
                        </div>
                    </div>

                    
                </mat-card-content>
            </mat-card>
        </div>
        <div fxFlex="auto" id="billingDetails">
            <mat-card class="mat-elevation-z0" fxFlex="100%" *ngIf="detailedInvoice">
                <mat-card-title class="invoice-details-table-header">
                    <h2> {{detailedInvoice.period_start | date:'LLL dd'}} – {{detailedInvoice.period_end | date:'LLL dd, yyyy'}} </h2>
                </mat-card-title>
                <mat-card-content class="invoice-details-table-content">
                    <div class="table-container mat-elevation-z0">
                        <table mat-table [dataSource]="transactions">
                          <!-- Day Column -->
                          <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef> Date </th>
                            <td mat-cell *matCellDef="let transaction">
                                {{toHumanDate(transaction.date_start) | date:'LLL dd'}} – {{toHumanDate(transaction.date_end) | date:'LLL dd, yyyy'}}   
                            </td>
                            <td mat-footer-cell *matFooterCellDef> Total </td>
                          </ng-container>
                      
                          <!-- Quantity Column -->
                          <ng-container matColumnDef="description">
                            <th mat-header-cell *matHeaderCellDef>  Description </th>
                            <td mat-cell *matCellDef="let transaction"> {{transaction.description}} </td>
                            <td mat-footer-cell *matFooterCellDef> </td>
                          </ng-container>
                      
                          <!-- Total Column -->
                          <ng-container matColumnDef="total">
                            <th mat-header-cell *matHeaderCellDef> Total </th>
                            <td mat-cell *matCellDef="let transaction"> {{transaction.total | currency}} </td>
                            <td mat-footer-cell *matFooterCellDef> {{detailedInvoice.amount_due}} </td>
                          </ng-container>
                      
                          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                          <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                        </table>
                      </div>
                    
                </mat-card-content>
            </mat-card>
        </div>
    </mat-card>
</div>
