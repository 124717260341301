import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Required components for which route services to be activated
import { SetupComponent } from '../../components/setup/setup.component';
import { SubscriptionsComponent } from '../../components/subscriptions/subscriptions.component';
import { DashboardComponent } from '../../components/dashboard/dashboard.component';
import { SignInComponent } from '../../components/sign-in/sign-in.component';
import { EditProfileComponent } from '../../components/edit-profile/edit-profile.component';
import { BillingComponent } from '../../components/billing/billing.component';
import { PaymethodComponent } from '../../components/paymethod/paymethod.component';
import { AddpaymethodComponent } from '../../components/addpaymethod/addpaymethod.component';
import { ContactusComponent } from '../../components/contactus/contactus.component';
import { MessageComponent } from '../../components/message/message.component';
import { SettingsComponent } from '../../components/settings/settings.component';
import { AdminComponent } from '../../components/admin/admin.component';
import { SignatureComponent } from '../../components/signature/signature.component';



// Import canActivate guard services
import { AuthGuard } from "../guard/auth.guard";
import { SecureInnerGuard } from "../guard/secure-inner.guard";
import { AdminGuard } from "../guard/admin.guard";
import { SetupGuard } from "../guard/setup.guard";
import { FeatureGuard } from "../guard/feature.guard";
import { RedirectComponent } from '@app/components/redirect/redirect.component';

const routes: Routes = [
  { path: '', redirectTo: '/new', pathMatch: 'full'},
  { path: '**', component: RedirectComponent},
  { path: 'new', component: RedirectComponent},
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard], data:{role:'admin'}},
  { path: 'sign-in', component: SignInComponent, canActivate: [SecureInnerGuard]},
 /* { path: 'dashboard',  redirectTo: '/new', canActivate: [AuthGuard] },
  { path: 'billing',  redirectTo: '/new', canActivate: [AuthGuard] },
  { path: 'paymentmethods',  redirectTo: '/new', canActivate: [AuthGuard] },
  { path: 'support',  redirectTo: '/new', canActivate: [AuthGuard] },
  { path: 'settings',  redirectTo: '/new', canActivate: [AuthGuard] },
 /* 
  { path: 'setup', component: SetupComponent, canActivate: [SetupGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
 // { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [AuthGuard] },
  { path: 'edit', component: EditProfileComponent, canActivate: [AuthGuard] },
  { path: 'billing', component: BillingComponent, canActivate: [AuthGuard] },
  { path: 'paymentmethods', component: PaymethodComponent, canActivate: [AuthGuard] },
  { path: 'add-payment-method', component: AddpaymethodComponent, canActivate: [AuthGuard] },
  { path: 'support', component: ContactusComponent, canActivate: [AuthGuard] },
  { path: 'sent', component: MessageComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
  { path: 'admin', component: AdminComponent, canActivate: [AdminGuard], data:{role:'admin'}},
  { path: 'signature', component: SignatureComponent, canActivate: [FeatureGuard], data:{role:'admin'} },
*/
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    RouterModule.forChild(routes)
            
  ],
  exports: [RouterModule]
})

export class AppRoutingModule { }
