import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormArray } from '@angular/forms';
import { CustomersService } from 'src/app/shared/services/customers/customers.service';
import { Location, DOCUMENT } from '@angular/common';
import { Customer } from '../../shared/interfaces/customer';
import { AuthService } from "../../shared/services/auth/auth.service";
import { ProgressService } from "../../shared/services/navigation/progress.service";
import { SnackService } from '../../shared/services/snackbar/snack.service';
import { async } from 'rxjs/internal/scheduler/async';
import { RoutesService } from '@app/shared/services/navigation/routes.service';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.css']
})
export class EditProfileComponent implements OnInit {
  
  mainForm;
  customer:any;
  loadStatus;
  countries;

  constructor(
    private fb: FormBuilder, 
    public customers: CustomersService, 
    private location: Location,
    private auth: AuthService,
    private progress: ProgressService,
    private crouter:RoutesService,  
    private snack : SnackService
    ) {
      this.auth.user.subscribe( user => {
        if(user.email){
        this.getCustomer(user.email);
        }
      })
      
      this.countries = countryList;
  }
 
 initiateForm(customer){
   console.log(customer)
  let fullName = customer.billing.name.split(' ');
  this.mainForm = this.fb.group({
    organization: this.fb.group({
      sid: [customer.id ? customer.id : ''],
      gid: [customer.g_id ? customer.g_id : ''],
      name: [customer.organization ? customer.organization : '', Validators.required],
      admin: [customer.adminEmail ? customer.adminEmail : ''],
      email: [customer.alternateEmail?  customer.alternateEmail : '', Validators.required]
    }),
    user: this.fb.group({
      name: [fullName[0] ? fullName[0] : '' , Validators.required],
      lastname: [fullName[1] ? fullName[1] : '', Validators.required],
      street: [customer.billing.address.line1 ? customer.billing.address.line1 : '', Validators.required],
      street2: [customer.billing.address.line2 ? customer.billing.address.line2 : ''],
      city: [customer.billing.address.city ? customer.billing.address.city : '', Validators.required],
      state: [customer.billing.address.state ? customer.billing.address.state : '', Validators.required],
      country: [customer.billing.address.country ? customer.billing.address.country : 'US'],
      postal_code: [customer.billing.address.postal_code ? customer.billing.address.postal_code : '', Validators.required],
      phone: [customer.phone, Validators.required]
    })
  })
 }

 getCustomer(c){
   this.loadStatus = true;
   this.customers.getDbCustomer(c).valueChanges().subscribe( data =>{
     this.initiateForm(data[0]);
     this.loadStatus = false;
     //console.log(data);
   })
 }
  updateCustomer(v){  
    this.progress.setProgress(true);
    const customerData: Customer = {
      organization: v.organization.name,
      id: v.organization.sid,
      billing: {
        address:{
          line1: v.user.street,
          line2: v.user.sreet2 ? v.user.sreet2 : '',
          city: v.user.city,
          state: v.user.state,
          country: v.user.country,
          postal_code: v.user.postal_code
        },
        name: v.user.name+' '+v.user.lastname,
      },
      adminEmail: v.organization.admin,
      alternateEmail: v.organization.email,
      phone: v.user.phone,
      lastUpdated: new Date()
    }
    this.customers.updateDbCustomer(customerData).subscribe(res => {
      res.then(() => {
        this.snack.openSnackBar('Your information has been saved', 'X Close')
        this.goBack();
        this.progress.setProgress(false);
      }).catch(e => {
       // console.log(e)
        this.snack.openSnackBar('An error ocurred', 'X Close')
      })
    })

  }

   // sends the user back to the page they came from.
    goBack() {
      this.location.back();
    }

  ngOnInit(): void {
    this.crouter.setRoute('Edit Profile');
  }

}
const countryList = [
  {name: 'United States',code: 'US'},
  {name: 'Australia',code: 'AU'},
  {name: 'Austria',code: 'AT'},
  {name: 'Belgium',code: 'BE'},
  {name: 'Brazil',code: 'BR'},
  {name: 'Bulgaria',code: 'BG'},
  {name: 'Canada',code: 'CA'},
  {name: 'Cyprus',code: 'CY'},
  {name: 'Czech Republic',code: 'CZ'},
  {name: 'Denmark',code: 'DK'},
  {name: 'Estonia',code: 'EE'},
  {name: 'Finland',code: 'FI'},
  {name: 'France',code: 'FR'},
  {name: 'Germany',code: 'DE'},
  {name: 'Greece',code: 'GR'},
  {name: 'Hong Kong',code: 'HK'},
  {name: 'India',code: 'IN'},
  {name: 'Ireland',code: 'IE'},
  {name: 'Italy',code: 'IT'},
  {name: 'Japan',code: 'JP'},
  {name: 'Latvia',code: 'LV'},
  {name: 'Lithuania',code: 'LT'},
  {name: 'Luxembourg',code: 'LU'},
  {name: 'Malaysia',code: 'MY'},
  {name: 'Malta',code: 'MT'},
  {name: 'Mexico',code: 'MX'},
  {name: 'Netherlands',code: 'NL'},
  {name: 'New Zealand',code: 'NZ'},
  {name: 'Norway',code: 'NO'},
  {name: 'Poland',code: 'PL'},
  {name: 'Portugal',code: 'PT'},
  {name: 'Romania',code: 'RO'},
  {name: 'Singapore',code: 'SG'},
  {name: 'Slovakia',code: 'SK'},
  {name: 'Slovenia',code: 'SI'},
  {name: 'Spain',code: 'ES'},
  {name: 'Sweden',code: 'SE'},
  {name: 'Switzerland',code: 'CH'},
  {name: 'United Kingdom',code: 'GB'},
  {name: 'Kenya',code: 'KY'}
];
