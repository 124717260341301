import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { CustomersService } from '@app/shared/services/customers/customers.service';
import { AuthService } from '@app/shared/services/auth/auth.service';
import { ProgressService } from '@app/shared/services/navigation/progress.service';
import { SnackService } from '@app/shared/services/snackbar/snack.service';
import { Customer } from '@app/shared/interfaces/customer';
import { Router } from '@angular/router';
import { RoutesService } from '@app/shared/services/navigation/routes.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  @ViewChild('ccNumber') ccNumberField: ElementRef;

  expandedpm_bank = false;
  expandedpm_card = false;
  infoFormGroup: FormGroup;
  paymentFormGroup: boolean;
  subscriptionFormGroup: FormGroup;
  bankFormGroup: FormGroup;
  cardFormGroup: FormGroup;
  customerInfo: any;
  cardType;
  loadStatus;
  countries;
  subscriptions;

  @ViewChild('stepper') stepper;

  constructor(
    private router: Router,
    private crouter: RoutesService,  
    private _formBuilder: FormBuilder, 
    public customers: CustomersService,
    private auth: AuthService,
    private progress: ProgressService,
    private snack : SnackService
    ) {}

  listSubscriptions(domain){
    this.customers.getGSuiteSubscriptions(domain).subscribe((data:any) =>{
     // console.log(data)
      for (var i = 0; i < data.length; i++) {
        if (data[i].skuName.includes('Voice')){
          data[i].icon = 'voice'; 
        }else if (data[i].skuName.includes('Identity')){
          data[i].icon = 'identity'; 
        }else if (data[i].skuName.includes('Drive')){
          data[i].icon = 'drive'; 
        }else{
          data[i].icon = 'gapps'; 
        }

        if (data[i].plan.planName.includes('FLEXIBLE')){
          data[i].plan.planName = 'Flexible Plan'; 
        }else if (data[i].plan.planName.includes('ANNUAL')){
          data[i].plan.planName = 'Annual Commitment'; 
        }else if (data[i].plan.planName.includes('FREE')){
          data[i].plan.planName = 'Free Plan'; 
        }
      }
     this.subscriptions = data;
    // console.log(data)
    })
  }

  updateSubscriptions(v, subscriptions){
    // complete the current step
    this.stepper.selected.completed = true;
    // move to next step
    this.stepper.next();
    this.scrollToTop();
    this.loadStatus = true;
    this.progress.setProgress(true);
    const data = {user: v.user, subscriptions: subscriptions};
    this.customers.activateSubscriptions(data).subscribe((res:any)=>{
      if(!res.error){
        this.progress.setProgress(false);
        this.loadStatus = false;
        setTimeout(() => {
          this.router.navigate(['dashboard']);
      }, 3000);

      }else{
        console.log(res.error)
        this.snack.openSnackBar('An error ocurred, please contact support@hiviewsolutions.com', 'X Close');
        this.progress.setProgress(false);
      }
    })

  }

  customerForm(customer){
    let fullName = customer.billing && customer.billing.name ? customer.billing.name.split(' ') : '';
    this.infoFormGroup = this._formBuilder.group({
      sid: [customer.id ? customer.id : ''],
      gid: [customer.g_id ? customer.g_id : ''],
      organization: [customer.organization ? customer.organization : ''],
      admin: [customer.adminEmail ? customer.adminEmail : ''],
      email: [customer.alternateEmail?  customer.alternateEmail : '', Validators.required],
      name: [fullName[0] ? fullName[0] : '' , Validators.required],
      lastname: [fullName[1] ? fullName[1] : '', Validators.required],
      street: [customer.billing && customer.billing.address.line1 ? customer.billing.address.line1 : '', Validators.required],
      street2: [customer.billing && customer.billing.address.line2 ? customer.billing.address.line2 : ''],
      city: [customer.billing && customer.billing.address.city ? customer.billing.address.city : '', Validators.required],
      state: [customer.billing && customer.billing.address.state ? customer.billing.address.state : '', Validators.required],
      country: [customer.billing && customer.billing.address.country ? customer.billing.address.country : 'US'],
      postal_code: [customer.billing &&  customer.billing.address.postal_code ? customer.billing.address.postal_code : '', Validators.required],
      phone: [customer.phone, Validators.required]
    });
  }
  
  updateCustomer(v){  
    this.progress.setProgress(true);
    const customerData: Customer = {
      organization: v.organization,
      id: v.sid,
      billing: {
        address:{
          line1: v.street,
          line2: v.sreet2 ? v.sreet2 : '',
          city: v.city,
          state: v.state,
          country: v.country,
          postal_code: v.postal_code
        },
        name: v.name+' '+v.lastname,
      },
      adminEmail: v.admin,
      alternateEmail: v.email,
      phone: v.phone,
      lastUpdated: new Date()
    }
    this.customers.updateDbCustomer(customerData).subscribe(res => {
      res.then(() => {
        this.progress.setProgress(false);
        // complete the current step
        this.stepper.selected.completed = true;
        // move to next step
        this.stepper.next();
        this.scrollToTop();
        
        if(this.customerInfo.default_source && this.customerInfo.default_source.id){
          // complete the current step
          this.stepper.selected.completed = true;
          // move to next step
          this.stepper.next();
          this.scrollToTop();
        }
        
      }).catch(e => {
        console.log(e)
        this.snack.openSnackBar('An error ocurred', 'X Close')
      })
    })

  }

  initiateFormBank(){
    this.bankFormGroup = this._formBuilder.group({
      object: ['bank_account'],
      country: ['US'],
      currency : ['usd'],
      account_holder_name: ['', Validators.required],
      account_holder_type: ['', Validators.required],
      routing_number: ['', Validators.required],
      account_number: ['', Validators.required]
      })
   }  

   addBank(data){
    this.progress.setProgress(true);
     this.auth.user.pipe(first()).subscribe(user => {
       let source = {'source': data, 'id': user.sid};
       this.customers.addSource(source).subscribe((bank:any) => {
         if(!bank.error){
           this.customers.addPaymentMethodDB(user.sid, bank).then(() => {
             this.customers.updateDefaultSourceDB(bank).then(() => {
              this.paymentFormGroup = true;
              this.progress.setProgress(false);
              // complete the current step
              this.stepper.selected.completed = true;
              // move to next step
              this.stepper.next();
              this.scrollToTop();
            })
           }).catch(error => {
             console.log(error)
            this.snack.openSnackBar('error', "X Close");
           })
         }else{
           this.snack.openSnackBar('An error ocurred, please try again later or contact us at support@hiviewsolutions.com', "X Close");
           this.progress.setProgress(false);
         }
        })
      })
    }

    initiateFormCard(){
      this.cardFormGroup = this._formBuilder.group({
       object: ['card'],
       address_country: ['US', Validators.required],
       currency : ['usd'],
       name: ['', Validators.required],
       number:  ['', [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(17), validateCC]],
       exp_month: ['', [Validators.required, Validators.pattern('^(0?[1-9]|1[012])$'), Validators.minLength(2)]],
       exp_year: ['', [Validators.required, Validators.pattern('^2[0-9]$'), Validators.minLength(2)]],
       cvc: ['', [Validators.required, Validators.pattern('^[ 0-9]*$'), Validators.minLength(3)]],
       address_zip: ['', [Validators.required, Validators.minLength(4)]]
     })
       this.countries = countryList;
    }  
 
    addCard(data){
     this.progress.setProgress(true);
     this.auth.user.pipe(first()).subscribe(user => {
       let source = {'source': data, 'id': user.sid};
       this.customers.addSource(source).pipe(first()).subscribe((card:any) => {
         if(!card.error){
            this.customers.addPaymentMethodDB(user.sid, card).then(() => {
              this.customers.updateDefaultSourceDB(card).then(() => {
                this.paymentFormGroup = true;
                this.progress.setProgress(false);
                // complete the current step
                this.stepper.selected.completed = true;
                // move to next step
                 this.stepper.next();
                this.scrollToTop();
              })
            }).catch(error => {
              console.log(error);
              this.snack.openSnackBar('error', "X Close");
            })
          }else if(card.error){
            this.snack.openSnackBar(card.error, "X Close");
            this.progress.setProgress(false);
          }else{
            this.snack.openSnackBar('An error ocurred, please try again later or contact us at support@hiviewsolutions.com', "X Close");
            this.progress.setProgress(false);
          }
        })
      })
    }

    /* Insert spaces to make the user's credit card number more legible */
    creditCardNumberSpacing() {
      const input = this.ccNumberField.nativeElement;
      const { selectionStart } = input;
      const { number } = this.cardFormGroup.controls;
      let trimmedCardNum = number.value.replace(/\s+/g, '');
  
      if (trimmedCardNum.length > 16) {
        trimmedCardNum = trimmedCardNum.substr(0, 16);
      }
  
       /* Handle American Express 4-6-5 spacing */
      const partitions = trimmedCardNum.startsWith('34') || trimmedCardNum.startsWith('37') 
                         ? [4,6,5] 
                         : [4,4,4,4];
  
      const numbers = [];
      let position = 0;
      partitions.forEach(partition => {
        const part = trimmedCardNum.substr(position, partition);
        if (part) numbers.push(part);
        position += partition;
      })
  
      number.setValue(numbers.join(' '));
  
      /* Handle caret position if user edits the number later */
      if (selectionStart < number.value.length - 1) {
        input.setSelectionRange(selectionStart, selectionStart, 'none');
      }

      this.cardType = getCardType(number.value);

    }

    scrollToTop() {
      document.querySelector("mat-sidenav-content").scrollTo(0, 0);
    }

  ngOnInit() {
    this.crouter.setRoute('Welcome!')
    this.paymentFormGroup = false;
    this.auth.user.subscribe( user => {
      if(user.email){
        this.loadStatus = true;
        this.customers.getDbCustomer(user.email).valueChanges().subscribe( data =>{
          this.customerInfo = data[0]
          this.customerForm(this.customerInfo);
          this.loadStatus = false;
          this.initiateFormBank();
          this.initiateFormCard();
          this.listSubscriptions(user.domain);
          /*
          if(this.customerInfo.billing && this.customerInfo.billing.address && this.customerInfo.billing.address.line1){
            // complete the current step
            this.stepper.selected.completed = true;
            // move to next step
            this.stepper.next();
            this.scrollToTop();

          }
          */ 

      
        });
        this.subscriptionFormGroup = this._formBuilder.group({
          terms: ['', Validators.required],
          user: user
        });
      }
    })
  }
}

function validateCC(c: FormControl) {
  //console.log(c.value)
  if(c.value){
    let type = getCardType(c.value)
    return type ? null : {
      validateCC: {
        valid: false
      }
    }
  }
}

function getCardType(number) {
  number = number.replace(/\s/g, '');

  var re = {
   'UnionPay': /^(62|88)\d+$/,
   'Visa': /^4[0-9]{12}(?:[0-9]{3})?$/,
   'MasterCard': /^5[1-5][0-9]{14}$/,
   'American Express': /^3[47][0-9]{13}$/,
   'Diners Club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
   'Discover': /^6(?:011|5[0-9]{2})[0-9]{12}$/,
   'JCB': /^(?:2131|1800|35\d{3})\d{11}$/
}

for(var key in re) {
    if(re[key].test(number)) {
        return key
    }
}
}

const countryList = [
  {name: 'United States',code: 'US'},
  {name: 'Canada',code: 'CA'},
  {name: 'Australia',code: 'AU'},
  {name: 'Austria',code: 'AT'},
  {name: 'Belgium',code: 'BE'},
  {name: 'Brazil',code: 'BR'},
  {name: 'Bulgaria',code: 'BG'},
  {name: 'Cyprus',code: 'CY'},
  {name: 'Czech Republic',code: 'CZ'},
  {name: 'Denmark',code: 'DK'},
  {name: 'Estonia',code: 'EE'},
  {name: 'Finland',code: 'FI'},
  {name: 'France',code: 'FR'},
  {name: 'Germany',code: 'DE'},
  {name: 'Greece',code: 'GR'},
  {name: 'Hong Kong',code: 'HK'},
  {name: 'India',code: 'IN'},
  {name: 'Ireland',code: 'IE'},
  {name: 'Italy',code: 'IT'},
  {name: 'Japan',code: 'JP'},
  {name: 'Latvia',code: 'LV'},
  {name: 'Lithuania',code: 'LT'},
  {name: 'Luxembourg',code: 'LU'},
  {name: 'Malaysia',code: 'MY'},
  {name: 'Malta',code: 'MT'},
  {name: 'Mexico',code: 'MX'},
  {name: 'Netherlands',code: 'NL'},
  {name: 'New Zealand',code: 'NZ'},
  {name: 'Norway',code: 'NO'},
  {name: 'Poland',code: 'PL'},
  {name: 'Portugal',code: 'PT'},
  {name: 'Romania',code: 'RO'},
  {name: 'Singapore',code: 'SG'},
  {name: 'Slovakia',code: 'SK'},
  {name: 'Slovenia',code: 'SI'},
  {name: 'Spain',code: 'ES'},
  {name: 'Sweden',code: 'SE'},
  {name: 'Switzerland',code: 'CH'},
  {name: 'United Kingdom',code: 'GB'},
  {name: 'Kenya',code: 'KY'}
];

