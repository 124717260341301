<div class="dashboard-content"  id="prodDetails">
    <mat-card class="dashboard-organization-main">
        <mat-progress-bar mode="indeterminate" *ngIf="!customer"></mat-progress-bar>
        <mat-card-content>
          <div fxLayout="row wrap" fxLayoutAlign="center"> 
           <div fxFlex="auto" fxFlex.gt-xs="50%" fxFlex.lt-sm="100%">
            <mat-card class="mat-elevation-z0 dashboard-contact-information">
                <mat-card-title class="dashboard-contact-title">
                    <h2> Billing contact information </h2>
                    <div>
                        <a class="edit-button mat-focus-indicator mat-stroked-button mat-button-base mat-primary" 
                           color="primary" fxlayout="row" fxlayoutalign="center center" [routerLink]="['/edit']">
                            <span class="mat-button-wrapper">
                              <mat-icon>edit</mat-icon>Edit
                            </span>
                            <div class="mat-button-ripple mat-ripple">
                            </div><div class="mat-button-focus-overlay"></div>
                        </a>
                    </div>
                </mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <div *ngIf="customer">
                        <div class="dashboard-contact-details"> {{customer.organization}} </div>
                        <div class="dashboard-contact-details"> {{customer.billing.name}} </div>
                        <div class="dashboard-contact-details" fxlayoutalign="start center">
                            <mat-icon aria-hidden="true" class="dashboard-contact-details-icon">email</mat-icon> {{customer.alternateEmail}} 
                        </div>
                        <div class="dashboard-contact-details" fxlayoutalign="start center">
                            <mat-icon aria-hidden="true" class="dashboard-contact-details-icon">domain</mat-icon> {{customer.domain}}
                        </div>
                        <div class="dashboard-contact-details" fxlayoutalign="start center">
                            <mat-icon aria-hidden="true" class="dashboard-contact-details-icon">location_on</mat-icon>
                            {{customer.billing.address.line1}} {{customer.billing.address.line2}} {{customer.billing.address.city}} {{customer.billing.address.state}} {{customer.billing.address.postal_code}}
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
         </div>
         <div fxFlex="auto" fxFlex.gt-xs="50%" fxFlex.lt-sm="100%">
            <mat-card class="mat-elevation-z0 dashboard-quick-links">
                <mat-card-title class="dashboard-contact-title">
                    <h2 *ngIf="payinfo">Your balance {{payinfo.balance}}</h2>
                    <h2 *ngIf="!payinfo">Loading billing details...</h2>
                </mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <div class="dashboard-quick-links-container" fxlayout="column" fxlayoutalign="center start">
                     <div *ngIf="payinfo">
                        <div class="dashboard-card-header-expanded-content">
                            <div class="content-container" >
                                <div class="title-container">
                                    <span class="header-title">How you pay</span>
                                </div>
                            </div>
                        </div>
                        <div class="dashboard-payment-card-panel">
                            <div class="details-header">
                                <div class="details-label">
                                    {{(payinfo.pay_method.object == 'card')? payinfo.pay_method.brand : payinfo.pay_method.bank_name}} •••• {{payinfo.pay_method.last4}}
                                </div>
                                <div class="details-description">
                                    <div>
                                        <span *ngIf="payinfo.pay_method.exp_month">Expires {{payinfo.pay_method.exp_month}}/{{payinfo.pay_method.exp_year}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="card">
                                <img *ngIf="payinfo.pay_method.object == 'card'" src="../../../assets/billing/cards/{{payinfo.pay_method.brand}}.png">
                                <div *ngIf="payinfo.pay_method.object !== 'card'" class="SVGInline SVGInline--cleaned SVG BrandIcon BrandIcon--size--32 Box-root Flex-flex">
                                    <svg height="70" width="100" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                                        <g fill="none" fill-rule="evenodd">
                                            <path d="M0 0h32v32H0z" fill="rgb(247, 250, 255)"></path>
                                            <path d="M7.274 13.5a1.25 1.25 0 0 1-.649-2.333C7.024 10.937 10.15 9.215 16 6c5.851 3.215 8.976 4.937 9.375 5.167a1.25 1.25 0 0 1-.65 2.333zm12.476 10v-8.125h3.75V23.5H25a1 1 0 0 1 1 1V26H6v-1.5a1 1 0 0 1 1-1h1.5v-8.125h3.75V23.5h1.875v-8.125h3.75V23.5z" fill="rgb(41, 134, 218)"></path>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="link">
                            <a fxlayoutalign="start center" [routerLink]="['/paymentmethods']">
                                <mat-icon>credit_card</mat-icon>
                                Manage Payment Methods
                            </a>
                        </div>
                        <div class="link">
                            <a fxlayoutalign="start center" [routerLink]="['/billing']">
                                <mat-icon>line_style</mat-icon>
                                View Transactions
                            </a>
                        </div>
                     </div>
                    </div>
                </mat-card-content>
            </mat-card>
          </div> 
         </div>
         <div fxLayout='row'>
             <mat-card class="mat-elevation-z0 dashboard-subscriptions-container"  fxFlex="auto">
                <mat-divider></mat-divider>
                <mat-card-title fxLayout='row' class="dashboard-subscriptions-title">
                    <h2> Subscriptions </h2>
                </mat-card-title>
                <mat-divider></mat-divider>
                <mat-card-content>
                    <div fxLayout="row wrap" fxLayoutAlign="start center">
                        <div class="loadingCircle" *ngIf="inProgress">
                            <mat-spinner class="inprogressCircle"></mat-spinner>
                        </div>
                        <div fxflex="1 1 auto" fxLayout="row wrap" *ngIf="inProgress == false">
                            <mat-card fxFlex="auto" fxFlex.gt-xs="160px" fxFlex.lt-sm="100%" class="mat-elevation-z0 dashboard-subscriptions-card" [routerLink]="['/support']">
                                <div class="dashboard-newservice-logo-container" fxflex="0 0 100%" >
                                    <mat-icon svgicon="channel:new-service-icon" aria-hidden="true">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" height="100%" version="1.1" viewBox="0 0 40 40" width="100%" fit="" preserveAspectRatio="xMidYMid meet" focusable="false">
                                            <g fill="none" fill-rule="evenodd" id="new-fab-icon" stroke="none" stroke-width="1">
                                                <g id="FAB/Regular/Raised">
                                                    <g id="Icons/GFAB/Create">
                                                        <g id="gFAB">
                                                            <polygon fill="#4285F4" id="Fill-2" points="16.6666667 16.6666667 16.6666667 23.335 23.4383333 23.335 40 23.335 40 16.6666667"></polygon>
                                                            <polygon fill="#FBBC05" id="Fill-3" points="16.6666667 16.6666667 0 16.6666667 0 23.335 16.6666667 23.335 23.335 16.6666667"></polygon>
                                                            <rect fill="#34A853" height="16.6666667" id="Rectangle" width="6.66666667" x="16.6666667" y="23.3333333"></rect>
                                                            <polygon fill="#EA4335" id="Fill-4" points="16.6666667 0 16.6666667 23.3333333 23.335 16.6666667 23.335 0"></polygon>
                                                        </g>
                                                    </g>
                                                </g>
                                            </g>
                                        </svg>
                                    </mat-icon>
                                </div>
                                <div class="dashboard-newservice-text" fxflex="1 1 auto" fxlayout="row" fxlayoutalign="space-between center">
                                   Add Services
                                </div>
                            </mat-card>
                            <mat-card fxFlex.gt-xs="160px" fxFlex.lt-sm="100%" class="mat-elevation-z0 dashboard-subscriptions-card"  *ngFor="let product of subscriptions; let i = index"  [ngClass]="{'dashboard-selectedCard': selectedIndex === i}" (click)="productInfo(product, i)">
                                <div class="dashboard-subscriptions-logo-container" fxflex="0 0 100%">
                                    <img src="../../../assets/billing/{{product.icon}}.png">
                                </div>
                                <div class="details" fxflex="0 1 100%" >
                                    <h2 class="dashboard-subscriptions-name" id="">{{product.skuName}}</h2>
                                </div>
                                <div class="dashboard-subscriptions-card-content" fxflex="1 1 auto" fxlayout="row" fxlayoutalign="space-between center" *ngIf="product.plan.planName">
                                    {{product.plan.planName}}
                                </div>
                                <div class="dashboard-subscriptions-card-content" fxflex="1 1 auto" fxlayout="row" fxlayoutalign="space-between center" *ngIf="product.seats && product.seats.numberOfSeats">
                                   {{product.seats.licensedNumberOfSeats}} / {{product.seats.numberOfSeats}} Licenses
                                </div>
                                <div class="dashboard-subscriptions-card-content" fxflex="1 1 auto" fxlayout="row" fxlayoutalign="space-between center" *ngIf="product.seats && !product.seats.numberOfSeats && product.seats.maximumNumberOfSeats >= 0">
                                    {{product.seats.licensedNumberOfSeats}} / {{product.seats.maximumNumberOfSeats}} Licenses
                                </div>
                            </mat-card>
                        </div>
                    </div>
                </mat-card-content>
            </mat-card>
         </div>
         <div fxLayout='row'>
            <mat-card *ngIf="prodDetails" class="mat-elevation-z0 dashboard-plan-details-card" fxFlex="auto">
                <mat-card-content>
                    <div fxLayout="column">
                        <div class="product" fxFlex="100%" fxLayout="row wrap">
                            <div class="logo-container" fxflex="0 0 48px" fxlayoutalign="center center">
                                <img src="../../../assets/billing/{{prodDetails.icon}}.png">
                            </div>
                            <div class="details" fxflex="0 1 100%" >
                                <h2 class="dashboard-subscriptions-name">{{prodDetails.skuName}}</h2>
                            </div>
                        </div>
                        <div fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px"> 
                            <div fxFlex="auto" fxLayout="column" fxFlex.gt-xs="48%" fxFlex.lt-sm="100%" *ngIf="prodDetails.seats">
                                <div class="licenses" fxLayout="row wrap" *ngIf="prodDetails.seats.numberOfSeats">
                                    <span>Licenses</span>
                                    <div class="dashboard-infopanel-add-button" role="button" matTooltip="Click to add licenses" (click)="addLicenses(prodDetails)">
                                        <mat-icon>edit</mat-icon>
                                        <span>{{prodDetails.seats.numberOfSeats}} licenses</span>
                                    </div>
                                </div>
                                <div class="licenses" fxLayout="row wrap" *ngIf="!prodDetails.seats.numberOfSeats && prodDetails.seats.maximumNumberOfSeats >= 0">
                                    <span>Licenses</span>
                                    <div class="dashboard-infopanel-add-button" role="button" matTooltip="Click to update licenses" (click)="updateLicenses(prodDetails)">
                                        <mat-icon>edit</mat-icon>
                                        <span>{{prodDetails.seats.maximumNumberOfSeats}} licenses</span>
                                    </div>
                                </div>
                                <div class="info" *ngIf="prodDetails.seats.licensedNumberOfSeats">
                                    <p>{{prodDetails.seats.licensedNumberOfSeats}} assigned</p>
                                </div>
                                
                            </div>
                            <div fxFlex="auto" fxFlex.gt-xs="48%" fxFlex.lt-sm="100%"  *ngIf="prodDetails.plan">
                                <div class="commitment" fxLayout="row wrap" *ngIf="prodDetails.plan.planName">
                                    {{prodDetails.plan.planName}}
                                </div>
                                <div class="info">
                                    <p *ngIf="!prodDetails.plan.commitmentInterval">{{prodDetails.status}}</p>
                                    <p *ngIf="prodDetails.plan.commitmentInterval && prodDetails.plan.commitmentInterval.endTime">Renewal date: {{prodDetails.plan.commitmentInterval.endTime | date:'long'}}</p>
                                </div>
                             </div> 
                        </div>
                    </div>
                </mat-card-content>
            </mat-card> 
         </div>
        </mat-card-content>
    </mat-card>
</div>


     <!-- Add Licenses Dialog template -->

     <ng-template #addLicensesDialog  let-data>
        <button mat-icon-button class="mat-dialog-cancel" color="primary" mat-dialog-close>
            <mat-icon>close</mat-icon>
        </button>
        <h3 mat-dialog-title> Add licenses </h3>
        <div mat-dialog-content>
            <p class="mat-dialog-info"> Your {{data.prod.skuName}} subscription currently has {{data.prod.seats.numberOfSeats}} licenses. You can add up to 20 more licenses at the time. </p>
            <form [formGroup]="licensesForm" class="dashboard-add-licenses-form">
                <mat-form-field  appearance="outline">
                    <mat-label>Additional licenses</mat-label>
                    <input matInput placeholder="Ex. 5" formControlName="units" min="1" max="20" type="number" required>
                    <mat-error *ngIf="licensesForm.get('units').errors && licensesForm.get('units').errors.min">(Min 1)</mat-error>
                    <mat-error *ngIf="licensesForm.get('units').errors && licensesForm.get('units').errors.max">(Max 20)</mat-error>
                </mat-form-field>
            </form>
        </div>
        <div class="licenses-disclaimer">
            You can add and remove user accounts as you wish from your Admin console, as long as you don’t exceed your number of purchased licenses. <br>
            If you need more accounts than that, here you can purchase more licenses. However, you can’t remove licenses and lower your monthly payments until it’s time to renew your annual contract.
        </div>
        <div mat-dialog-actions align="end">
          <button mat-button color="primary" mat-dialog-close>Cancel</button>
          <button mat-button color="warn" [disabled]="licensesForm && licensesForm.invalid" (click)="addLicensesSave(licensesForm.value, data.prod)" cdkFocusInitial>Continue</button>
        </div>
       </ng-template>


       <!-- udpdate Licenses Dialog template -->
  
       <ng-template #updateLicensesDialog  let-data>
          <button mat-icon-button class="mat-dialog-cancel" color="primary" mat-dialog-close>
              <mat-icon>close</mat-icon>
          </button>
          <h3 mat-dialog-title>Add or remove licenses</h3>
          <div mat-dialog-content>
              <p class="mat-dialog-info"> Your {{data.prod.skuName}} subscription currently has {{data.prod.seats.maximumNumberOfSeats}} licenses. Here you can update the total amount of licenses. </p>
              <form [formGroup]="updateSeatsForm" class="dashboard-add-licenses-form" *ngIf="updateSeatsForm">
                  <mat-form-field  appearance="outline">
                      <mat-label>Total licenses</mat-label>
                      <input matInput placeholder="Ex. 5" formControlName="units" min="data.prod.seats.licensedNumberOfSeats" type="number" oninput="this.value = Math.abs(this.value)" required>
                  </mat-form-field>
              </form>
          </div>
          <div class="licenses-error-message">
              <mat-error *ngIf="updateSeatsForm.get('units').errors && updateSeatsForm.get('units').errors.min">*Min is {{data.prod.seats.licensedNumberOfSeats}}. Same as the amount of assigned licenses in the Admin console</mat-error>
          </div>
          <div class="licenses-disclaimer">
              You can add and remove user accounts as you wish from your Admin console, as long as you don’t exceed your number of purchased licenses.<br>
              If you need more accounts than that, here you can purchase more licenses. Also, you can remove licenses and lower your monthly payments (Subject to proration).
          </div>  
          <div mat-dialog-actions align="end">
            <button mat-button color="primary" mat-dialog-close>Cancel</button>
            <button mat-button color="warn" [disabled]="updateSeatsForm && updateSeatsForm.invalid || updateSeatsForm.get('units').value == data.prod.seats.maximumNumberOfSeats" (click)="updateLicensesSave(updateSeatsForm.value, data.prod)" cdkFocusInitial>Continue</button>
          </div>
         </ng-template>